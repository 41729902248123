import { Checkbox, Divider, Dropdown, Form, Modal, Select } from 'antd';
import React, { useMemo, useState } from 'react';
import SCol from 'components/Standard/SCol';
import { SInputNumber } from 'components/Standard/SInput';
import SRow from 'components/Standard/SRow';
import SSelect from 'components/Standard/SSelect';
import { useTranslation } from 'react-i18next';
import {
  CLIENT_INTERACTION_DIRECTIONS,
  CLIENT_INTERACTION_TYPES,
  COLUMN_TYPES,
  COMMUNICATIONS_DISTRIBUTION_METHODS,
  CUSTOM_FIELDS_TYPES,
  DATE_PICKER_MODES,
  SYMBOLIC_TIME_RANGE
} from 'core/utils/constants';
import SButton from 'components/Standard/SButton';
import SText from 'components/Standard/SText';
import Icon from 'components/Icon';
import { Plus, Trash2 } from 'react-feather';
import { useSelector } from 'react-redux';
import { find, get, isEmpty, isEqual, isNil, reduce } from 'lodash';
import DurationPicker from 'components/Inputs/DurationPicker';
import RangeInputs from 'components/Inputs/RangeInputs';
import { getCustomFieldsByIds, getCustomFieldsByKeys } from 'redux/selectors/customFields';
import CustomDatePicker from 'components/DatePicker/DatePicker';
import AddUsersModal from '../../../components/AddUsersModal';
import CustomFieldFilter from './CustomFieldFilter';

const updateArray = (array, index, updateFn) => {
  return [...array.slice(0, index), updateFn(array[index]), ...array.slice(index + 1)];
};

const { Option } = Select;
const defaultFields = [];

const ClientInteractionFilters = ({
  clientInteractionType,
  fields = defaultFields,
  schedule,
  onChange,
  onDelete
}) => {
  const { t } = useTranslation();
  const [showUsersModal, setShowUsersModal] = useState(false);

  const customFieldsByKeys = useSelector(
    state =>
      reduce(
        getCustomFieldsByKeys(state, true),
        (acc, field, key) => (field.usedForFilters ? { ...acc, [key]: field } : acc),
        {}
      ),
    isEqual
  );
  const customFieldsByIds = useSelector(
    state =>
      reduce(
        getCustomFieldsByIds(state, true),
        (acc, field, id) => (field.usedForFilters ? { ...acc, [id]: field } : acc),
        {}
      ),
    isEqual
  );

  const fieldsByKeys = fields.reduce((acc, filter) => ({ ...acc, [filter.key]: filter }), {});
  const fieldsKeys = Object.keys(fieldsByKeys);

  const customFieldFiltersByKeys = useMemo(
    () =>
      Object.keys(customFieldsByKeys).reduce(
        (acc, key) => ({ ...acc, [key]: find(fields, ({ key: filterKey }) => filterKey === key) }),
        {}
      ),
    [customFieldsByKeys]
  );

  const fieldsTypes = useMemo(
    () => ({
      direction: {
        key: 'direction',
        title: COLUMN_TYPES.direction.name,
        defaultValue: { direction: CLIENT_INTERACTION_DIRECTIONS.INCOMING.value },
        component: ({ disabled, onChange, filters }) => {
          // const { t } = useTranslation();
          return (
            <SSelect
              disabled={disabled}
              placeholder={t(
                'clientInteractionsPage.tableFilters.tableGeneralFilters.form.directionPlaceholder'
              )}
              value={filters?.direction}
              onChange={direction => onChange({ direction })}
              size="large"
              width="260px"
            >
              {Object.values(CLIENT_INTERACTION_DIRECTIONS).reduce((acc, item) => {
                if (item.value === CLIENT_INTERACTION_DIRECTIONS.ALL.value) return acc;
                const component = (
                  <Option
                    value={item.value}
                    key={item.value}
                    label={`${t(
                      'clientInteractionsPage.tableFilters.tableGeneralFilters.form.direction'
                    )} ${t(item.title)}`}
                  >
                    {t(item.title)}
                  </Option>
                );
                return [...acc, component];
              }, [])}
            </SSelect>
          );
        }
      },
      duration: {
        key: 'duration',
        title: 'clientInteractionsPage.tableFilters.tableGeneralFilters.form.duration',
        defaultValue: { durationFrom: '0', durationTo: '300' },
        component: ({ filters, onChange, disabled }) => (
          <DurationPicker
            from={filters?.durationFrom}
            to={filters?.durationTo}
            disabled={disabled}
            size="large"
            style={{ width: '260px' }}
            allowClear={false}
            onChange={({ from, to }) => {
              if (!from && !to) return onChange({ durationFrom: '0', durationTo: '300' });
              return onChange({
                durationFrom: isNil(from) ? '0' : `${from}`,
                durationTo: isNil(to) ? '300' : `${to}`
              });
            }}
          />
        )
      },
      communicationPartsCount: {
        key: 'communicationPartsCount',
        title:
          'clientInteractionsPage.tableFilters.tableGeneralFilters.form.communicationPartsCount',
        defaultValue: { communicationPartsCountFrom: 1, communicationPartsCountTo: 10 },
        component: ({ filters, onChange, disabled }) => (
          <RangeInputs
            disabled={disabled}
            min={0}
            precision={0}
            value={{
              from: filters?.communicationPartsCountFrom,
              to: filters?.communicationPartsCountTo
            }}
            size="large"
            style={{ width: '260px' }}
            onChange={({ from, to }) => {
              if (!from && !to) return onChange({ from: 1, to: 10 });
              return onChange({
                communicationPartsCountFrom: isNil(from) ? 1 : `${from}`,
                communicationPartsCountTo: isNil(to) ? 10 : `${to}`
              });
            }}
          />
        )
      },
      ...reduce(
        customFieldsByKeys,
        (acc, field, key) => {
          const isPipelineFields =
            key === 'custom_field/sales_pipeline_status' ||
            key === 'sales_pipeline_status' ||
            key === 'custom_field/sales_pipeline' ||
            key === 'sales_pipeline';
          return {
            ...acc,
            [key]: {
              customField: field,
              key,
              title: isPipelineFields ? 'Воронка и этап воронки' : field.name,
              ...(field.fieldType === CUSTOM_FIELDS_TYPES.DATETIME && {
                defaultValue: {
                  contains: { symbolicTimeRange: SYMBOLIC_TIME_RANGE.LAST_SEVEN_DAYS }
                }
              }),
              component: ({ filters, disabled, onChange }) => {
                return (
                  <CustomFieldFilter
                    customField={field}
                    disabled={disabled}
                    onChange={onChange}
                    value={filters}
                    customFieldsByIds={customFieldsByIds}
                    customFieldFiltersByKeys={customFieldFiltersByKeys}
                  />
                );
              }
            }
          };
        },
        {}
      )
    }),
    [customFieldsByKeys]
  );

  const onAddFilter = addedFilter => {
    const isAddedFilterWasDeleted = fields.map(field => field.key).includes(addedFilter.key);

    // * убрать фильтр который там есть если он был удален
    // * добавить новый

    const fieldsWithOutdDeletedField = fields.filter(field => field.key !== addedFilter.key);

    const defaultSettings = {
      settings: {
        ...fieldsTypes[addedFilter.key]?.defaultValue
      }
    };

    onChange({
      ...clientInteractionType,
      fields: isAddedFilterWasDeleted
        ? [
            ...fieldsWithOutdDeletedField,
            { ...addedFilter, ...(isEmpty(addedFilter.settings) && defaultSettings) }
          ]
        : [...fields, { ...addedFilter, ...(isEmpty(addedFilter.settings) && defaultSettings) }]
    });
  };

  const onRemoveFilter = removedFieldKey => {
    const removedField = {
      ...find(fields, { key: removedFieldKey }),
      _destroy: '1'
    };

    onChange({
      ...clientInteractionType,
      fields: [...fields.filter(field => field.key !== removedFieldKey), removedField]
    });
  };

  const onUpdateFilter = filter => {
    const filterIndex = fields.findIndex(({ key }) => key === filter.key);
    filterIndex < 0
      ? onAddFilter(filter)
      : onChange({
          ...clientInteractionType,
          fields: updateArray(fields, filterIndex, oldFilter => ({ ...oldFilter, ...filter }))
        });
  };

  const onAddUsers = usersIds => {
    onUpdateFilter({ key: 'operatorsIds', settings: { operatorsIds: usersIds } });
    setShowUsersModal(false);
  };

  const onFilterCheckboxHandler = (customField, defaultValue) => e => {
    const key = e.target.name;
    const field = find(fields, { key });
    fieldsKeys.includes(key) && get(field, '_destroy') !== '1'
      ? onRemoveFilter(key)
      : onAddFilter({
          key,
          customFieldId: customField?.id,
          settings: defaultValue || {}
        });
  };

  const renderFilterCheckbox = (filterKey, customField, defaultValue) => {
    const field = find(fields, { key: filterKey });

    if (filterKey === 'custom_field/sales_pipeline_status' || filterKey === 'sales_pipeline_status')
      return null;
    if (filterKey === 'custom_field/sales_pipeline' || filterKey === 'sales_pipeline')
      return (
        <Checkbox
          name={filterKey}
          checked={fieldsKeys.includes(filterKey) && get(field, '_destroy') !== '1'}
          onChange={onFilterCheckboxHandler(customField, defaultValue)}
        >
          Воронка и этап воронки
        </Checkbox>
      );

    return (
      <Checkbox
        name={filterKey}
        checked={fieldsKeys.includes(filterKey) && get(field, '_destroy') !== '1'}
        onChange={onFilterCheckboxHandler(customField, defaultValue)}
      >
        {t(fieldsTypes[filterKey]?.title)}
      </Checkbox>
    );
  };

  const getValidateMessages = ({ key, settings }) => {
    if (key === 'direction') {
      return !settings.direction
        ? t(
            'workPlanTaskConfigurationEditor.clientInteractionFilters.setConfiguration.directionOfCommunications'
          )
        : '';
    }
    if (key === 'duration') {
      return !settings.durationFrom || !settings.durationTo
        ? t(
            'workPlanTaskConfigurationEditor.clientInteractionFilters.setConfiguration.durationOfCommunications'
          )
        : '';
    }
    if (key === 'communicationPartsCount') {
      return !settings.communicationPartsCountFrom || !settings.communicationPartsCountTo
        ? t(
            'workPlanTaskConfigurationEditor.clientInteractionFilters.setConfiguration.communicationPartsCount'
          )
        : '';
    }
  };

  const getValidateStatus = ({ key, settings }) => {
    if (key === 'direction') {
      return !settings.direction ? 'error' : '';
    }
    if (key === 'duration') {
      return !settings.durationFrom || !settings.durationTo ? 'error' : '';
    }
    if (key === 'communicationPartsCount') {
      return !settings.communicationPartsCountFrom || !settings.communicationPartsCountTo
        ? 'error'
        : '';
    }
  };

  const menu = (
    <SRow gutter={[24, 12]} style={{ marginBottom: '-8px', maxHeight: '300px', overflowY: 'auto' }}>
      {clientInteractionType?.clientInteractionType ===
        CLIENT_INTERACTION_TYPES.PHONE_CALL.value && (
        <>
          <SCol>{renderFilterCheckbox(fieldsTypes.direction.key)}</SCol>
          <SCol>{renderFilterCheckbox(fieldsTypes.duration.key)}</SCol>
          <Divider style={{ margin: '0' }} />
        </>
      )}

      {[CLIENT_INTERACTION_TYPES.CHAT.value, CLIENT_INTERACTION_TYPES.TICKET.value].includes(
        clientInteractionType?.clientInteractionType
      ) && (
        <>
          <SCol>{renderFilterCheckbox(fieldsTypes.communicationPartsCount.key)}</SCol>
          <Divider style={{ margin: '0' }} />
        </>
      )}

      {reduce(
        customFieldsByKeys,
        (acc, customField, customFieldKey) => [
          ...acc,
          <SCol>{renderFilterCheckbox(customFieldKey, customField)}</SCol>
        ],
        []
      )}
      <Divider style={{ margin: '0' }} />
      <SCol>
        <SButton
          padding="0"
          color="var(--red_primary)"
          type="link"
          _hover="color: var(--red_primary)"
          icon={<Icon icon={Trash2} color="var(--red_primary)" />}
          onClick={() => {
            return Modal.confirm({
              title: t(
                'workPlanTaskConfigurationEditor.clientInteractionFilters.deleteFiltersModalTitle'
              ),
              onOk: () => {
                onDelete(clientInteractionType.id);
              },
              okText: t('general.delete'),
              cancelText: t('general.cancel')
            });
          }}
        >
          {t('workPlanTaskConfigurationEditor.filters.deleteFilters')}
        </SButton>
      </SCol>
    </SRow>
  );

  const usersCount = fieldsByKeys?.operatorsIds?.settings?.operatorsIds?.length || 0;

  return (
    <Form layout="vertical">
      <SRow gutter={[16, 0]} align="baseline">
        <SCol span={6}>
          <Form.Item label={t('workPlanTaskConfigurationEditor.filters.clientInteractionType')}>
            <SSelect
              size="large"
              width="100%"
              // ! TODO: fix that shit
              value={clientInteractionType?.clientInteractionType}
              onChange={value =>
                onChange({ ...clientInteractionType, clientInteractionType: value })
              }
            >
              {Object.values(CLIENT_INTERACTION_TYPES).map(type => (
                <Option value={type.value}>{t(type.title)}</Option>
              ))}
            </SSelect>
          </Form.Item>
        </SCol>
        {schedule?.distributionType ===
          COMMUNICATIONS_DISTRIBUTION_METHODS.COMMUNICATIONS_NUMBER_BY_EACH_OPERATOR.value && (
          <SCol span={6}>
            <Form.Item
              label={
                <>
                  <SText>
                    {t(
                      'workPlanTaskConfigurationEditor.clientInteractionFilters.quantityByOperator'
                    )}
                  </SText>
                  <SText
                    color="var(--red_8)"
                    style={{ fontFamily: 'SimSun, sans-serif', marginLeft: 2 }}
                  >
                    *
                  </SText>
                </>
              }
              validateStatus={
                clientInteractionType?.clientInteractionsMaxCount < 1 ||
                !clientInteractionType?.clientInteractionsMaxCount
                  ? 'error'
                  : ''
              }
              help={
                clientInteractionType?.clientInteractionsMaxCount < 1 ||
                !clientInteractionType?.clientInteractionsMaxCount
                  ? t(
                      'workPlanTaskConfigurationEditor.clientInteractionFilters.setConfiguration.operatorsToCheck'
                    )
                  : ''
              }
            >
              <SInputNumber
                size="large"
                type="number"
                min={0}
                max={2147483647}
                onChange={value =>
                  onChange({
                    ...clientInteractionType,
                    clientInteractionsMaxCount: parseInt(value)
                  })
                }
                value={clientInteractionType?.clientInteractionsMaxCount || 0}
                width="100%"
              />
            </Form.Item>
          </SCol>
        )}
        {schedule?.distributionType ===
          COMMUNICATIONS_DISTRIBUTION_METHODS.COMMUNICATIONS_PERCENTAGE_BY_EACH_OPERATOR.value && (
          <SCol span={6}>
            <Form.Item
              label={
                <>
                  <SText>
                    {t(
                      'workPlanTaskConfigurationEditor.clientInteractionFilters.percentageByOperator'
                    )}
                  </SText>
                  <SText
                    color="var(--red_8)"
                    style={{ fontFamily: 'SimSun, sans-serif', marginLeft: 2 }}
                  >
                    *
                  </SText>
                </>
              }
              validateStatus={
                clientInteractionType?.clientInteractionsMaxCount < 0.01 ||
                !clientInteractionType?.clientInteractionsMaxCount
                  ? 'error'
                  : ''
              }
              help={
                clientInteractionType?.clientInteractionsMaxCount < 0.01 ||
                !clientInteractionType?.clientInteractionsMaxCount
                  ? 'Установите коммуникации на проверку'
                  : ''
              }
            >
              <SInputNumber
                size="large"
                type="number"
                min={0}
                step="0.01"
                onChange={value =>
                  onChange({
                    ...clientInteractionType,
                    clientInteractionsMaxCount: parseFloat(value)
                  })
                }
                max={100}
                value={clientInteractionType?.clientInteractionsMaxCount || 0}
                width="100%"
              />
            </Form.Item>
          </SCol>
        )}
        {schedule?.distributionType ===
          COMMUNICATIONS_DISTRIBUTION_METHODS.COMMUNICATIONS_NUMBER_BY_OPERATORS.value && (
          <SCol span={6}>
            <Form.Item
              label={
                <>
                  <SText>
                    {t('workPlanTaskConfigurationEditor.clientInteractionFilters.totalCountForAll')}
                  </SText>
                  <SText
                    color="var(--red_8)"
                    style={{ fontFamily: 'SimSun, sans-serif', marginLeft: 2 }}
                  >
                    *
                  </SText>
                </>
              }
              validateStatus={
                clientInteractionType?.clientInteractionsMaxCount < 1 ||
                !clientInteractionType?.clientInteractionsMaxCount
                  ? 'error'
                  : ''
              }
              help={
                clientInteractionType?.clientInteractionsMaxCount < 1 ||
                !clientInteractionType?.clientInteractionsMaxCount
                  ? t(
                      'workPlanTaskConfigurationEditor.clientInteractionFilters.setConfiguration.communicationsToCheck'
                    )
                  : ''
              }
            >
              <SInputNumber
                size="large"
                type="number"
                min={0}
                max={2147483647}
                onChange={value =>
                  onChange({
                    ...clientInteractionType,
                    clientInteractionsMaxCount: parseInt(value)
                  })
                }
                value={clientInteractionType?.clientInteractionsMaxCount || 0}
                width="100%"
              />
            </Form.Item>
          </SCol>
        )}
        <SCol span={6}>
          <Form.Item
            label={
              <>
                <SText>{t('workPlanTaskConfigurationEditor.filters.addUsers')}</SText>
                <SText
                  color="var(--red_8)"
                  style={{ fontFamily: 'SimSun, sans-serif', marginLeft: 2 }}
                >
                  *
                </SText>
              </>
            }
            name="employees"
            validateStatus={usersCount === 0 ? 'error' : ''}
            help={
              usersCount === 0
                ? t(
                    'workPlanTaskConfigurationEditor.clientInteractionFilters.setConfiguration.operatorsToCheck'
                  )
                : ''
            }
          >
            <SCol
              border={`1px solid var(${usersCount === 0 ? '--red_primary' : '--gray-border'})`}
              borderRadius="4px"
              height="40px"
              style={{ padding: '0 4px 0 11px' }}
            >
              <SRow
                justify="space-between"
                flexWrap="no-wrap"
                align="middle"
                gutter={[20, 0]}
                height="100%"
              >
                <SCol style={{ paddingRight: 0 }}>{`${t('general.selected')}: ${usersCount}`}</SCol>
                <SCol style={{ paddingLeft: 0 }}>
                  <SButton type="primary" onClick={() => setShowUsersModal(true)}>
                    {t('workPlanTaskConfigurationEditor.filters.add')}
                  </SButton>
                </SCol>
              </SRow>
            </SCol>
          </Form.Item>
        </SCol>
        <SCol span={6}>
          <Form.Item label={t('workPlanTaskConfigurationEditor.filters.datesRange')}>
            <CustomDatePicker
              onChange={({
                clientInteractionSymbolicTimeRange,
                clientInteractionTimeFrom,
                clientInteractionTimeTo
              }) =>
                onUpdateFilter({
                  key: 'clientInteractionsPeriod',
                  settings: {
                    clientInteractionTimeFrom,
                    clientInteractionTimeTo,
                    clientInteractionSymbolicTimeRange
                  }
                })
              }
              value={{
                symbolicTimeRange: find(clientInteractionType?.fields, {
                  key: 'clientInteractionsPeriod'
                })?.settings?.clientInteractionSymbolicTimeRange,
                timeFrom: find(clientInteractionType?.fields, {
                  key: 'clientInteractionsPeriod'
                })?.settings?.clientInteractionTimeFrom,
                timeTo: find(clientInteractionType?.fields, {
                  key: 'clientInteractionsPeriod'
                })?.settings?.clientInteractionTimeTo
              }}
              position="left"
              height="40px"
              top={42}
              mode={DATE_PICKER_MODES.clientInteraction}
            />
          </Form.Item>
        </SCol>
        {fields.map(filter => {
          const Component = fieldsTypes[filter.key]?.component;
          if (!Component || get(filter, '_destroy') === '1') return null;
          const onChange = settings => onUpdateFilter({ ...filter, settings });
          return (
            <SCol span={24} height="62px" marginBottom="24px">
              <Form.Item
                label={
                  <>
                    <SText>{t(fieldsTypes[filter.key].title)}</SText>
                    {['duration', 'communicationPartsCount'].includes(
                      fieldsTypes[filter.key]?.key
                    ) && (
                      <SText
                        color="var(--red_8)"
                        style={{ fontFamily: 'SimSun, sans-serif', marginLeft: 2 }}
                      >
                        *
                      </SText>
                    )}
                  </>
                }
                style={{ alignItems: 'baseline' }}
                validateStatus={getValidateStatus({
                  key: fieldsTypes[filter.key]?.key,
                  settings: filter?.settings
                })}
                help={getValidateMessages({
                  key: fieldsTypes[filter.key]?.key,
                  settings: filter?.settings
                })}
              >
                <SRow align="middle">
                  <SCol>
                    <Component filters={filter.settings} onChange={onChange} />
                  </SCol>

                  <SCol>
                    <SButton
                      icon={<Icon icon={Trash2} />}
                      border="none"
                      height="40px"
                      marginLeft="8px"
                      onClick={() => onRemoveFilter(filter.key)}
                    />
                  </SCol>
                </SRow>
              </Form.Item>
            </SCol>
          );
        })}
        <SCol display="flex" alignItems="center">
          <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
            <SButton type="link" padding="0" icon={<Icon icon={Plus} />}>
              {t('workPlanTaskConfigurationEditor.clientInteractionFilters.addFilter')}
            </SButton>
          </Dropdown>
        </SCol>
      </SRow>
      <AddUsersModal
        visible={showUsersModal}
        onCancel={() => setShowUsersModal(false)}
        onAdd={onAddUsers}
        title={t('workPlanTaskConfigurationEditor.clientInteractionFilters.operatorsToReview')}
        selectedUsersIds={fieldsByKeys?.operatorsIds?.settings?.operatorsIds}
      />
    </Form>
  );
};

export default ClientInteractionFilters;
