import api from 'core/api';
import { get, isEmpty, isNil, omit } from 'lodash';
import decamilize from 'decamelize-keys-deep';
import { isUUID } from 'core/utils/isUUID';

const createReviewWithNestedAttributes = ({
  clientInteractionId,
  customCommunicationAttributes
}) => async (dispatch, getState) => {
  const state = getState();

  const reviewState = state.uiClientInteractionPage;
  const { operatorId } = reviewState;
  const comments = Object.values(reviewState.commentsByIds);
  const taskDefinitions = get(reviewState, 'tasks.taskDefinitionsIds', []).reduce(
    (acc, taskDefinitionId) => {
      return [...acc, { taskDefinitionId }];
    },
    []
  );

  const commentsWithoutId = comments.map(item => {
    if (isUUID(item.id)) return item;
    return omit(item, 'id');
  });

  const checklist = state.uiChecklistManager.currentChecklist;
  const { questionIdToAnswerValue } = state.uiChecklistManager;
  const { questionIdToStandardComment } = state.uiChecklistManager;
  const { checklistDefinitionId } = checklist;
  const { comment } = checklist;
  const checklistMetadata = checklist.metadata || {};
  const answers = Object.keys(questionIdToAnswerValue).reduce((result, questionId) => {
    const value = questionIdToAnswerValue[questionId];
    const standardComments = questionIdToStandardComment[questionId];

    if (isNil(value)) {
      return result;
    }

    return [...result, { questionId, value, standardComments }];
  }, []);

  const payloadForCallOrChatReview = decamilize({
    clientInteractionId,
    operatorId,
    commentsAttributes: [...commentsWithoutId],
    tasksAttributes: [...taskDefinitions],
    checklistAttributes: {
      checklistDefinitionId,
      answers,
      comment,
      checklistMetadata
    }
  });

  const payloadForCustomCommunicationReview = decamilize({
    customCommunicationAttributes,
    operatorId: customCommunicationAttributes?.operatorId,
    commentsAttributes: [...comments],
    tasksAttributes: [...taskDefinitions],
    checklistAttributes: {
      checklistDefinitionId,
      answers,
      comment,
      checklistMetadata
    }
  });

  const review = await api.createNestedReview(
    isEmpty(customCommunicationAttributes)
      ? payloadForCallOrChatReview
      : payloadForCustomCommunicationReview
  );
  return review;
};

export default createReviewWithNestedAttributes;
