export default {
  uploadCallsPage: {
    messages: {
      successfullyLoaded: 'Loaded',
      loadFailed: 'File loading failed',
      maxFileText: 'File Size',
      maxFileSize: 'exceeds 30 MB.',
      fileFormatText: 'Incorrect file extension',
      fileFormatMessage: 'only mp3, wav or csv files are allowed.',
      fileFormat: 'You can upload files in mp3, wav or csv format, up to 30 megabytes/file in size'
    },
    buttons: {
      loadCalls: 'Upload calls',
      downloadExample: 'CSV Example'
    },
    table: {
      columns: {
        operator: 'Operator',
        direction: 'Call direction',
        startedAt: 'Call date',
        startedAtPlaceholder: 'Choose call date',
        clientPhoneNumber: 'Client phone number',
        duration: 'Call duration',
        actions: 'Actions'
      },
      uinstalledCallDirection: 'Unknown',
      confirmChange: {
        title: 'Changes will not be saved',
        ok: 'Continue',
        cancel: 'Cancel'
      },
      buttons: {
        cancel: 'Cancel',
        edit: 'Edit',
        delete: 'Delete'
      },
      confirmDelete: {
        title: 'Are you sure?',
        ok: 'Delete',
        cancel: 'Cancel'
      }
    }
  }
};
