export default {
  constants: {
    accessLevels: {
      noInvitationSent: "L'invito non e' stato inviato",
      invitationSent: "L'invito e' inviato",
      invitationAccepted: "Hai effettuato l'eccesso",
      userBlocked: 'Nessun accesso'
    },
    plans: {
      free: 'Gratuito',
      start: 'Inizio',
      profi: 'Profi',
      notInstalled: 'Non installato'
    },
    plansDescription: {
      checklistConstructor: 'Costruttore dei moduli di valutazione',
      omnichannelReviewsCount100:
        '100 valutazioni omnicanale (chiamate, chat, e-mail, ticket) al mese',
      omnichannelReviews: 'Valutazioni omnicanale (chiamate, chat, e-mail, ticket)',
      dashboardsCount2: '2 pannelli',
      unlimitedDashboards: 'Dashboard illimitati',
      communicationPanel: 'Pannello di comunicazione',
      library: 'Biblioteca delle chiamate',
      emailNotification: 'Notifiche di posta elettronica',
      appNotifications: 'Notifiche in applicazione ',
      dashboardsShare: 'Condivisione di dashboard',
      communicationsTableShare: 'Condivisione del pannello di comunicazione',
      calibrations: 'Sessioni di calibrazione',
      unlimitedTags: 'Tag automatici illimitati',
      unlimitedIntegrations: 'Integrazioni illimitate',
      integrationsCount1: '1 CRM o VoIP integrazione'
    },
    integrations: {
      customHttpTelephony: {
        title: 'API-telefonia',
        description: 'Integrazione con i programmi PBX'
      },
      customHttpText: {
        title: 'API canali di testo',
        description: 'Integrazione con i canali di testo'
      },
      bitrixCrm: {
        title: 'Bitrix24',
        description: 'Integrazione Bitrix 24 CRM'
      },
      amoCrm: {
        title: 'AmoCRM',
        description: 'Integrazione con AmoCRM'
      },
      sipuni: {
        title: 'Sipuni',
        description: 'Integrazione c Sipuni'
      },
      uis: { title: 'UIS/Comagic', description: 'Integrazione c UIS/Comagic' },
      naumen: {
        title: 'Naumen',
        description: 'Integration con Naumen'
      }
    },
    integrationsSyncPeroids: {
      month: 'Mese',
      threeMonth: 'Tre mesi',
      sixMonth: 'Mezzo anno',
      allTime: 'Tutto il tempo'
    },
    taskDefinitionTypes: {
      automatic: 'Automatico',
      manual: 'Manuale'
    },
    userStatuses: {
      active: 'Attivo',
      disabled: 'Disattivato'
    },
    permissionsDescriptions: {
      canPerformClientInteraction: {
        title: "L'amministratore comunica con i clienti (chiamate, chat, e-mail, ticket)",
        description: ''
      },
      canSignIn: {
        title: 'Il dipendente ha accesso a Qolio',
        description: ''
      },
      canSeeAndManageHiddenComments: {
        title: 'Aggiungere e visualizzare i commenti nascosti',
        description: ''
      },
      canAccessUserAnalyticsPageChartsTab: {
        title: 'Accesso al profilo personale',
        description: 'Il dipendente ha accesso alla pagina di analisi personale'
      },
      canAccessUserAnalyticsPageReviewsTab: {
        title: 'Accesso a un elenco personale di voti nel tuo accaunt personale',
        description: "Il dipendente vede l'elenco delle comunicazioni verificate e i loro commenti"
      },
      canAccessUserAnalyticsPageCommentsTab: {
        title: '',
        description: ''
      },
      canReplyCommnets: {
        title: 'Risposta ai commenti sulle tue communicazioni',
        description:
          "Il dipendente puo' rispondere ai commenti sulle valutazioni delle sue comunicazioni"
      },
      canAccessLibrary: {
        title: "L'accesso alla libreria delle chiamate",
        description: ''
      },
      canAddLibraryItems: {
        title: 'Aggiungi documenti alla libreria',
        description: ''
      },
      canSeeUnitClientInteractions: {
        title: 'Visualizza tutte le comunicazioni nel reparto',
        description: ''
      },
      canDownloadClientInteraction: {
        title: 'Scarica il file di comunicazione',
        description: ''
      },
      canMakeReview: {
        title: 'Il diritto di commentare e valutare le comunicazioni nel tuo dipartimento',
        description: ''
      },
      canSeeUnitReviews: {
        title: 'Visualizza valutazioni e commenti di altri utenti',
        description: ''
      },
      canSeeAndUpdateAssignedTasks: {
        title: 'Gestire i tuoi compiti',
        description: ''
      },
      canManageTasks: {
        title: "Aggiungi e gestisci le attivita' create",
        description: ''
      },
      canManageUnitTasks: {
        title: "Aggiungi e gestisci le attivita' del reparto",
        description: ''
      },
      canManageLibrary: {
        title: 'Gestisci la libreria delle chiamate',
        description:
          "Possibilita' di aggiungere/ rimuovere/ modificare cartelle e file all'interno della libreria"
      },
      canManageChecklistDefinitions: {
        title: 'Gestisci i moduli della valutazione',
        description: ''
      },
      canManageTaskDefinitions: {
        title: 'Gestione dei tag',
        description: ''
      },
      canManageOrganizationStructure: {
        title: 'Gestione dei reparti e degli utenti',
        description: ''
      },
      canManageOrganizationSettings: {
        title: "Gestione delle impostazioni generali dell'azienda",
        description: ''
      },
      canManageIntegrations: {
        title: "Gestione dell'integrazione",
        description: ''
      },
      canSeeDashboards: {
        title: "Accesso all'analisi",
        description: ''
      },
      canSeeUnitOperatorsAnalyticsPage: {
        title: 'Accesso al profilo personale degli amministratori che comunicano con i clienti',
        description: ''
      },
      canAccessTeamPage: {
        title: 'Accesso alla pagina "Squadra"',
        description: ''
      },
      canManageCalibrationSessions: {
        title: 'Accesso alle sessioni di calibrazione',
        description: ''
      },
      canSeeReviewerName: {
        title: 'Visualizzazione del nome del revisore',
        description: ''
      },
      canManageWorkPlanTaskConfigurations: {
        title: 'Controllo del bilanciamento del carico',
        description: ''
      },
      canCreateAppellations: {
        title: 'Presentare un ricorso',
        description: ''
      },
      canAdministrateAppellations: {
        title: 'Gestisci le impostazioni e rimuovi i ricorsi',
        description: ''
      },
      canWatchAppellations: {
        title: "Partecipa all'appello",
        description: ''
      },
      canSeeUnitAppellations: {
        title: 'Accesso al tracker dei ricorsi',
        description: ''
      },
      canAccessUserAnalyticsPageOperatorsAppellationsTab: {
        title: 'Accesso ai ricorsi tramite account personale (per operatori)',
        description: ''
      },
      canAccessAppellationsAnalyticsPage: {
        title: 'Accesso alle analisi sui ricorsi',
        description: ''
      },
      canUpdateClientInteractionOperator: {
        title: "Il diritto di modificare l'operatore responsabile della chiamata",
        description: ''
      },
      canAiTranscribeClientInteractions: {
        title: 'Il dipendente ha accesso alla funzionalità di trascrizione',
        description: ''
      },
      canAiAnalyzeClientInteractions: {
        title: 'Il dipendente ha accesso alle sezioni AI',
        description: ''
      }
    },
    widgetsDescriptions: {
      checklistDefinitionAverageScore: {
        title: 'Valutazione media del modulo di controllo',
        description:
          'Questo widget mostra il punteggio medio complessivo per un particolare modulo di valutazione utilizzato per controllare le chiamate.'
      },
      phoneCallsCount: {
        title: "Quantita' delle chiamate",
        description: 'Questo widget conta semplicemente il numero di chiamate.'
      },
      phoneCallsAverageDuration: {
        title: 'La durata media della chiamata',
        description:
          "Questo widget mostra la durata media di tutte chiamate selezionate. Il formato di visualizzazione e' mm:ss (minuti:secondi)."
      },
      totalPhoneCallsDuration: {
        title: 'Durata totale delle chiamate',
        description:
          "Questo widget mostra la somma della durata delle chiamate, ad es. la durata totale di tutte le conversazioni che hai selezionato. Il formato di visualizzazione e' hh:mm:ss (ore:minuti:secondi)."
      },
      reviewedClientInteractionsDuration: {
        title: 'Durata totale delle chiamate verificate',
        description:
          'Questo widget mostra la durata totale di tutte le chiamate verificate. Il calcolo si basa sulla data di valutazione.'
      },
      reviewedClientInteractionsPercentage: {
        title: 'Percentuale di valutazioni',
        description:
          "Questo widget mostra quale percentuale di chiamate e' stata estratta dal numero totale di chiamate effettuate durante il periodo selezionato."
      },
      checklistDefinitionAverageScoreByUnits: {
        title: 'Confronto del voto medio per dipartimento',
        description:
          'Questo widget grafico a barre mostra un confronto del punteggio medio per un modulo di valutazione tra i reparti.'
      },
      checklistDefinitionAverageScoreByOperators: {
        title: 'Confronto del punteggio medio degli amministratori',
        description:
          'Questo widget grafico a barre mostra un confronto del punteggio medio per un modulo di valutazione per gli amministratori selezionati.'
      },
      checklistDefinitionAverageScoreByChecklistDefinitions: {
        title: 'Confronto del punteggio medio per i moduli di valutazione',
        description:
          'Questo widget grafico a barre confronta il punteggio medio complessivo in diversi moduli di valutazione.'
      },
      phoneCallsCountByUnits: {
        title: 'Confronto di numero di chiamate per reparto',
        description:
          'Questo widget grafico a barre confronta il numero di chiamate in diversi reparti o squadre.'
      },
      phoneCallsCountByOperators: {
        title: 'Confronto di numero di chiamate degli amministratori',
        description:
          "Questo widget grafico a barre confronta il numero di chiamate da diversi dipendenti (in un reparto o per l'azienda generale)."
      },
      reviewsCountByReviewers: {
        title: 'Confronto del numero di controlli dei supervisori',
        description:
          "Questo widget grafico a barre mostra il numero di valutazioni eseguite da ciascun supervisore dell'azienda durante il periodo selezionato."
      },
      ratingFlagsCount: {
        title: 'Numero di bandiere e commenti',
        description:
          "Questo widget sotto forma di diagramma circolare mostra la proporzione del numero di commenti con bandiere o senza bandiere che sono stati creati nel periodo selezionato nell'azienda in generale o nel reparto / squadra scelta da te o anche per un amministratore specifico."
      },
      ratingFlagsCountByUnits: {
        title: 'Confronto del numero di bandiere per reparto',
        description:
          'Questo widget grafico a barre mostra la proporzione del numero di commenti con o senza bandiere che sono stati sreati nel reparto / squadra nel periodo scelto da te.'
      },
      ratingFlagsCountByOperators: {
        title: 'Confronto del numero di bandiere per dipendente',
        description:
          'Questo widget grafico a barre mostra la proporzione del numero di commenti con o senza bandiera che sono stati creati nel periodo scelto da te.'
      },
      labelsCountByOperators: {
        title: 'Confronto il numero di tag per dipendente',
        description:
          'Questo widget grafico a barre mostra la proporzione quanti tag sono stati assegnati alle chiamate di ciascun dipendente durante il periodo selezionato.'
      },
      labelsCountByUnits: {
        title: 'Confronto del numero di tag per reparto',
        description:
          'Questo widget grafico a barre mostra la proporzione quanti tag sono stati assegnati alle chiamate nei reparti / squandre nel periodo selezionato.'
      },
      checklistDefinitionQuestionsAverageScores: {
        title: 'Confronto del punteggio medio per criteri',
        description:
          'Questo widget grafico a barre mostra un confronto del punteggio medio ottenuto in ciascun criterio (punto di valutazione) nel modulo di valutazione.'
      },
      checklistDefinitionQuestionsAverageScoresByQuestionGroups: {
        title: 'Confronto del punteggio medio dei criteri nei gruppi',
        description:
          "Questo widget grafico con blocchi all'interno mostra un confronto del punteggio medio ottenuto in ciascun criterio (punto di valutazione) di ciascun gruppo del modulo di valutazione e un confronto dei gruppi tra loro."
      },
      checklistDefinitionQuestionGroupsAverageScores: {
        title: 'Confronto del punteggio medio per gruppi dei criteri',
        description:
          'Questo widget grafico a barre mostra un confronto del punteggio medio tra i gruppi di criteri.'
      },
      phoneCallsAverageDurationByUnits: {
        title: 'Confronto della durata media delle chiamate per reparto',
        description:
          "Questo widget grafico a barre confronta la durata media di tutte le chiamate in ogni reparto. Il formato di visualizzazione e' mm:ss (minuti:secondi)."
      },
      phoneCallsAverageDurationByOperators: {
        title: 'Confronto della durata media delle chiamate per dipendente',
        description:
          "Questo widget grafico a barre confronta la durata media di tutte le chiamate di ciascun amministratore. Il formato di visualizzazione e' mm:ss (minuti:secondi)."
      },
      reviewsCountByReviewersHistory: {
        title: 'Dinamica del numero delle chiamate verificate',
        description:
          'Questo widget grafico a barre mostra il numero di valutazioni eseguite da ciascun supervisore in un determinato periodo del tempo.'
      },
      checklistDefinitionAverageScoreByOperatorsHistory: {
        title: 'Dinamica del punteggio medio dei dipendenti',
        description:
          'Questo widget grafico a barre mostra il cambiamento della valutazione media di un modulo di valutazione di diversi amministratori in ogni periodo di tempo.'
      },
      checklistDefinitionAverageScoreByUnitsHistory: {
        title: 'Dinamica del punteggio medio per reparto',
        description:
          'Questo widget sotto forma di diagramma mostra il cambiamento del voto medio per un modulo di valutazione nei reparti diversi o squadre in ciascun periodo di tempo.'
      },
      averageScoreByQuestionsHistory: {
        title: 'La dimanica del punteggio secondo i criteri del modulo di valutazione',
        description:
          'Questo qidget sotto forma di diagramma mostra il cambiamento della valutazione, edia per ogni criterio nel modulo di valutazione in ogni periodo di tempo.'
      },
      averageScoreByQuestionGroupsHistory: {
        title: 'Dinamica del punteggio per gruppi di criteri',
        description:
          'Questo widget sotto forma di diagramma il cambiamento del punteggio medio per gruppi di criteri sotto forma di punteggio ad ogni intervallo di tempo.'
      },
      phoneCallsAverageDurationByOperatorsHistory: {
        title: 'Dinamica della durata media delle chiamate per dipendente',
        description:
          'Questo widget sotto forma di grafico mostra il cambiamento della durata media delle chiamate per ciascun dipendente a determinati intervalli. Formato di visualizzazione della scala Y - mm:ss (minuti:secondi).'
      },
      phoneCallsAverageDurationByUnitsHistory: {
        title: 'Dinamica della durata media delle chiamate per reparto',
        description:
          'Questo widget sotto forma di diagramma mostra il cambiamento della durata media delle chiamate in ogni reparto o squadre a intervalli specifici. Formato della visualizzazione della scala Y - mm:ss (minuti: secondi).'
      },
      clientInteractionsCountByOperatorsHistory: {
        title: 'Dinamica del numero di chiamate dei dipendenti',
        description:
          'Questo widget sotto forma di diagramma mostra il cambiamento della durata media delle chiamate per ogni dipendente in ogni periodo di tempo.'
      },
      clientInteractionsCountByUnitsHistory: {
        title: 'Dinamica del numero di chiamate per reparto',
        description:
          'Questo widget sotto forma di diagramma mostra il cambiamento della durata media delle chiamate in diversi reparti o squadre ad ogni intervallo di tempo.'
      },
      tableReviewsCountByOperators: {
        title: 'I risultati delle valutazioni degli amministratori',
        description:
          'Il widget visualizza il punteggio medio di un amministratore per una data specifica, il punteggio totale per il mese e il numero di valutazioni'
      },
      tableReviewsCountByOperatorsWithKpi: {
        title: 'KPI per operatore',
        description:
          'Mostra il punteggio medio per agente nelle ultime cinque settimane, più una tabella aggiuntiva basata sulle impostazioni KPI'
      },
      tableChecklistItemsByOperators: {
        title: 'Risultati degli amministratori in base a criteti',
        description:
          'Il widget visualizza il punteggio medio per criteri o gruppi di criteri per operatore'
      },
      tableChecklistItemsHistory: {
        title: 'Risultati dei controlli in base a criteti',
        description:
          'Il widget visualizza il punteggio medio per una domanda o un gruppo di domande per una data specifica, il punteggio totale per il mese e il numero di verifiche'
      },
      flagsAndComments: {
        title: 'Bandiere e commenti',
        description:
          'Il widget mostra il numero di bandiere e commenti di operatori, reparti, aziende'
      },
      tableChecklistsScoresByOperators: {
        title: 'Risultati delle verifiche dei moduli di valutazioni',
        description:
          'Il widget visualizza i risultati delle verifiche dei dipendenti o dei reparti per i moduli di valutazioni selezionati per un periodo specificato'
      },
      tableReviewsCountByReviewers: {
        title: 'Numero di controlli da parte dei revisori',
        description:
          'Il widget visualizza il numero di controlli effettuati dai revisori per i moduli di valutazione selezionati per un periodo specificato'
      },
      tableReviewsWithScores: {
        title: 'I risultati dei dipendenti sotto forma di valutazione',
        description:
          'Il widget visualizza i controlli per dipendente, insieme ai risultati dei controlli per criteri e gruppi di criteri sotto forma di valutazione'
      }
    },
    reviewCallStatuses: {
      all: 'Tutti',
      checked: 'Valutati',
      unchecked: 'Non valutati'
    },
    callsDirections: {
      all: 'Tutti',
      incoming: 'Arrivati',
      outcoming: 'Usciti'
    },
    weekendViewStatuses: {
      view: 'Visualizzare',
      hide: 'Non visualizzare'
    },
    communicationDirections: {
      incoming: 'In arrivo',
      outcoming: 'In uscita'
    },
    sessionsDirections: {
      pending: 'Prossimi',
      done: 'Passati'
    },
    reviewStatuses: {
      all: 'Tutti',
      reviewed: 'Valutato',
      notReviewed: 'Non valutato'
    },
    locales: {
      ru: 'Russo',
      en: 'Inglese',
      es: 'Spagnolo',
      it: 'Italiano'
    },
    clientInteractionDirections: {
      all: 'Tutti',
      incoming: 'In arrivo',
      outcoming: 'In uscita',
      unknown: 'Non installato'
    },
    ratingMethods: {
      simple: 'Valutazione semplice',
      weighted: 'Valutazione equilibrata'
    },
    ratingCalculations: {
      sum: 'Somma dei punteggi',
      average: 'Punteggio medio'
    },
    ratingModes: {
      numbers: 'Numerico',
      percentage: 'Percentuale'
    },
    autofailEnabled: {
      true: 'Si',
      false: 'No'
    },
    clientInteractionTypes: {
      chat: 'Chats',
      ticket: 'Tickets',
      phone_call: 'Chiamate',
      email: 'Lettere',
      other: 'Altro'
    },
    flagsColors: {
      red: 'Bandiera rossa',
      green: 'Bandiera verde',
      yellow: 'Bandiera gialla',
      withoutFlag: 'Senza bandiera'
    },
    columnTypes: {
      communicationType: { title: 'Tipo' },
      clientPhoneNumber: { title: 'Numero del telefono' },
      startedAt: {
        title: 'Data e ora della comunicazione',
        columnName: 'Data e ora'
      },
      nps: { title: 'NPS' },
      direction: { title: 'Direzione' },
      text_conversation_response_time_in_seconds: { title: 'Tempo della risposta' },
      email: { title: 'E-mail' },
      clientId: { title: 'ID cliente' },
      chatId: { title: 'Numero del chat' },
      ticketId: { title: 'Numero del ticket' },
      partsCount: {
        title: 'Numero di messaggi',
        columnName: 'Numero di messaggi.'
      },
      duration: {
        title: 'Durata',
        columnName: 'Dur'
      },
      text_conversation_ended_at: { title: 'Data e ora di chiusura' },
      unitId: { title: 'Dipartimento' },
      operatorId: { title: 'Nome e cognome' },
      isReviewed: { title: 'Stato' },
      score: {
        title: 'Risultato della valutazione',
        columnName: 'Valutazione'
      },
      checklistDefinitionName: { title: 'Modulo di valutazione' },
      reviewReviewerId: { title: 'Nome e cognome del valutatore' },
      reviewCreatedAt: { title: 'Data di valutazione' },
      reviewComments: { title: 'Bandiere e commenti' },
      taskTaskDefinitions: { title: 'Tag' },
      reviewTasksCount: { title: 'Compiti' },
      taskPriority: { title: "Priorita' del compito" },
      taskStatus: { title: 'Stato del compito' },
      taskAssigneeId: { title: 'Responsabile del compito' },
      status: { title: 'Stato del contatto' },
      crmEntityId: { title: 'Affare/biglietto' }
    },
    boolValues: {
      true: 'Si',
      false: 'No'
    },
    colorPercantageZone: {
      emptyPercantage: 'Non inclusi nella zona di valutazione',
      lowPercantage: 'Critico',
      mediumPercantage: 'Normale',
      highPercantage: 'Eccellente'
    },
    clientInteractionsTypes: {
      phoneCall: 'Chiamata',
      email: 'E-mail',
      chat: 'Chat',
      ticket: 'Ticket',
      video: 'Video',
      other: 'Altro'
    },
    symbolicTimeRanges: {
      all: 'Tutto il tempo',
      today: 'Oggi',
      yesterday: 'Ieri',
      lastSevenDays: 'Ultimi 7 giorni',
      thisWeek: 'Questa settimana',
      previousWeek: 'La settimana scorsa',
      lastThirtyDays: 'Ultimi 30 giorni',
      thisMonth: 'Questo mese',
      previousMonth: 'Lo scorso mese',
      thisYear: "Quest'anno",
      previousYear: 'Anno scorso'
    },
    symbolicMode: {
      clientInteraction: ', per data di comunicazione',
      review: ', per data di valutazione'
    },
    notificationsScheduleTypes: {
      immediately: 'Immediatamente',
      endOfDay: 'Alla fine del giorno',
      endOfWeek: 'Alla fine della settimana',
      dayAbout: 'Tra un giorno'
    },
    notificationsTypes: {
      reviewCreated: 'Chiamata valutata',
      replyCommentCreated: 'Rispondi al commento',
      taskCreated: 'Tag aggiunto automaticamente'
    },
    syncDataPeriods: {
      threeDays: 'Tre giorni',
      week: 'Settimana',
      twoWeeks: 'Due settimane',
      month: 'Mese',
      threeMonths: 'Tre mesi',
      sixMonths: 'Mezzo anno',
      allTime: 'Tutto il tempo'
    },
    colorZonesText: {
      low:
        "Operatore ha commesso gravi errori in questo parametro di valutazione, che comporteranno perdite reputazionali/ finanziarie per l'azienda o l'interesse del cliente non potra essere restituito",
      medium:
        "Operatore ha commesso piccoli errori in questo punto di valutazione che non hanno grande influenza sulla reputazione dell'azienda o sulla decisione del cliente di aquistare il nostro prodotto. In questo parametro di valutazione l'amministratore deve migliorarsi, poiche' qui e' possibile meglio come descritto negli standart.",
      high:
        "Operatore ha anticipato le aspettative del cliente, ha fatto tutto in modo che il cliente non avesse nemmeno la possibilita' di esprimere un dubbio. Caso degno di come gestire obiezioni/ dubbi/ negativita'"
    },
    scaleTypes: {
      max_2: {
        text: {
          yes: 'Si',
          no: 'No'
        },
        name: 'Scala di due valutazioni (0/1, si/ no)',
        valuesOptions: {
          emojis: '👍 e 👎 (👎 - 0, 👍 - 1)',
          text: 'Si e No (No - 0, Si - 1)',
          numbers: '0 e 1'
        }
      },
      max_3: {
        name: 'Scala di tre valutazioni (1,2,3)',
        valuesOptions: { text: '1,2 e 3' }
      },
      max_4: { name: 'Scala di quattro valutazioni (1,2,3,4)' },
      max_5: { name: 'Scala di cinque valutazioni (1,2,3,4,5)' },
      max_10: {
        name: 'Scala di dieci valutazioni (da 1 a 10)',
        valuesOptions: { numbers: 'da 1 a 10' }
      },
      custom: { name: 'Scala arbitraria' },
      custom_with_views: { name: 'Scala arbitraria (valore del testo)' },
      custom_binary: {
        name: 'Scala di 2 valutazioni con cifre arbitrarie',
        text: {
          yes: 'Si',
          no: 'No'
        },
        valuesOptions: {
          emojis: '👍 e 👎',
          text: 'Si e No',
          numbers: 'Cifre arbitrari'
        }
      }
    },
    checklistDefinitionStatuses: {
      draft: 'Bozza',
      archived: "Nell'archivio",
      published: 'Pubblicato'
    },
    intercomMessengerStatuses: {
      show: 'Visualizzare',
      hide: 'Nascondere'
    },
    widgetViewModes: {
      operators: 'Dipendenti',
      reviewers: 'Dipendenti',
      units: 'Dipartimenti',
      questions: 'Criteri',
      groups: 'Gruppi',
      table: 'Tabella',
      chart: 'Grafico',
      score: 'Punti',
      percantage: 'Percentuale'
    },
    datesViewFormat: {
      day: 'Giorno',
      week: 'Una settimana',
      month: 'Mese',
      year: 'Anno'
    },
    errors: {
      unknownError:
        'Qualcosa è andato storto, ma ci stiamo lavorando. Prova a ricaricare la pagina',
      loadingRecordError: {
        title: 'Impossibile accedere al record',
        content:
          "Contattare l'assistenza telefonica o l'amministratore di sistema con questo collegamento al record",
        tooltip: {
          copy: 'Copia',
          copied: 'Copiato!'
        }
      }
    },
    defaultPageTitle:
      "Servizio cloud per l'automazione del controllo qualità per contact center e reparti commerciali | qolio.io",
    hiddenNamePlaceholder: 'Utente sconosciuto',
    workPlanTaskConfigurationsStatuses: {
      draft: 'Bozza',
      pending: 'In attesa di',
      inProgress: 'Nel processo',
      completed: 'Completato',
      stopped: 'Fermato'
    },
    periodRepeat: {
      day: 'Quotidiano',
      week: 'Settimanalmente',
      month: 'Mensile',
      never: 'Non ripetere'
    },
    timeUnit: {
      day: 'giorni',
      week: 'settimane',
      month: 'mesi'
    },
    appealsStatuses: {
      pending: 'Non processato',
      inProgress: 'In opera',
      needsInformation: 'Informazione richiesta',
      accepted: 'Accettato',
      rejected: 'Respinto',
      withoutAnswer: 'Nessuna risposta'
    },
    appealPersonFilterValues: {
      assignedTo: 'Esecutore',
      watcher: 'Osservatore',
      appellant: 'Presenta un ricorso'
    }
  }
};
