export default {
  loginPage: {
    form: {
      title: 'Entrar',
      unRegistered: '¿No registrado?',
      fields: {
        emailPlaceholder: 'Email',
        phonePlaceholder: 'Phone',
        passwordPlaceholder: 'Password',
        enterConfimationPasswordPlaceholder: 'Password confirmation',
        firstNamePlaceholder: 'First name',
        lastNamePlaceholder: 'Last name',
        orgNamePlaceholder: 'Organization name',
        WebsitePlaceholder: 'Website address',
        demoPlaceholder: 'Create with demo data'
      },
      messages: {
        agreeWith: 'I agree with ',
        and: ' and ',
        offer: 'public offer agreement',
        privacy: 'privacy policy',
        errorUrl: 'Incorrect url',
        errorPhone: 'Incorrect phone',
        errorReg: 'Registration failed! Please try again',
        successReg: 'You are registered!',
        enterEmail: 'Please enter email',
        enterPhone: 'Please enter phone',
        enterFirstName: 'Please enter first name',
        enterLastName: 'Please enter last name',
        shouldAccept: 'Should accept agreement',
        enterPassword: 'Please enter password',
        confirmPassword: 'Please confirm password',
        enterOrgName: 'Please enter organization name',
        enterWebsite: 'Please enter website address',
        wrongEmail: 'Wrong email format',
        minLengthPassword: 'Short password'
      },
      buttons: {
        login: 'Entrar',
        register: 'Registrarse',
        forgotPassword: '¿Olvidó la contraseña?',
        registration: 'Registrarse',
        goToSite: 'Pasar a la página web',
        registrationlink: 'Regístrate ahora'
      }
    },
    layout: {
      slides: {
        efficiency: {
          title: 'Eficiencia',
          description:
            'Todos los instrumentos para el control de calidad y la evaluación de la comunicación están reunidos en una plataforma'
        },
        motivation: {
          title: 'Motivación',
          description: 'Involucre, desarrolle y motive a sus empleados'
        },
        simplicity: {
          title: 'Sencillez',
          description:
            'Elimine las hojas de cálculo electrónicas y los instrumentos de evaluación y control absoletos'
        },
        analytics: {
          title: 'Analítica',
          description:
            'Analize los indicadores de calidad y encuentre nuevas posibiidades de crecimiento'
        }
      }
    }
  }
};
