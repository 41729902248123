import createListReducer from 'core/redux/lists/listReducer';
import { datasetsResource } from 'redux/resources/dataset';

const additionalReducer = {
  onDatasetCreate: (state, { payload }) => {
    return {
      ...state,
      ids: [payload.id, ...state.ids]
    };
  },
  onDatasetDelete: (state, { payload }) => {
    const { selectedIds } = payload;
    const newIds = state.ids.filter(id => !selectedIds.includes(id));
    return {
      ...state,
      ids: newIds
    };
  }
};

export const listParams = {
  name: 'datasetsList',
  loader: datasetsResource.operations.load,
  additionalReducer,
  keepResults: false
};

const { actions, reducer, operations } = createListReducer(listParams);

const datasetsListReducer = reducer;

export { actions, datasetsListReducer, operations };
