import api from 'core/api';
import * as actions from './reducer';

export const getDashboardAnalytics = ({ id }) => {
  return async dispatch => {
    try {
      const response = await api.getDashboardAnalytics({ id });
      const analyticsObject = response.body;
      return analyticsObject;
    } catch (error) {
      console.log(error);
      return [];
    }
  };
};

export const getDashboardWidgetAnalytics = ({ id }) => {
  return async dispatch => {
    try {
      await dispatch(actions.loadDashboardWidgetAnalyticsStarted({ id }));
      const response = await api.getDashboardWidgetAnalytics({ id });
      const widgetAnalyticsObject = response.body;
      await dispatch(actions.loadDashboardWidgetAnalyticsSucceed(widgetAnalyticsObject));
      return widgetAnalyticsObject;
    } catch (error) {
      console.log(error);
      return [];
    }
  };
};
