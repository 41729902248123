import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  loading: false,
  editableGroup: {},
  currentChecklist: {},
  currentChecklistQuestionGroups: [],
  currentChecklistQuestions: [],
  addingQuestionToGroup: {},
  comments: {},
  addingQuestionGroup: false,
  selectingQuestionsFromSaved: false,
  selectedQuestionsFromSaved: [],
  sortedQuestionGroups: [],
  sortedQuestionGroupBindings: {},
  editingColorZonesBeforePublish: {},
  employees: {}
};

export const setCurrentChecklist = createAction('uiChecklistEditor/setCurrentChecklist');
export const setEditableGroup = createAction('uiChecklistEditor/setEditableGroup');
export const setAddingQuestionToGroup = createAction('uiChecklistEditor/setAddingQuestionToGroup');
export const setComments = createAction('uiChecklistEditor/setComments');
export const setSelectingQuestionsFromSaved = createAction(
  'uiChecklistEditor/setSelectingQuestionsFromSaved'
);
export const setSelectedQuestionsFromSaved = createAction(
  'uiChecklistEditor/setSelectedQuestionsFromSaved'
);
export const setAddingQuestionGroup = createAction('uiChecklistEditor/setAddingQuestionGroup');
export const setSortedQuestionGroups = createAction('uiChecklistEditor/setSortedQuestionGroups');
export const updateSortedQuestionGroupBindings = createAction(
  'uiChecklistEditor/updateSortedQuestionGroupBindings'
);
export const updateMultipleSortedQuestionGroupBindings = createAction(
  'uiChecklistEditor/updateMultipleSortedQuestionGroupBindings'
);
export const setEditingColorZonesBeforePublish = createAction(
  'uiChecklistEditor/setEditingColorZonesBeforePublish'
);
export const setEmployees = createAction('uiChecklistEditor/setEmployees');

export const uiChecklistEditorReducer = createReducer(defaultState, {
  [setCurrentChecklist]: (state, { payload }) => {
    return {
      ...state,
      ...payload
    };
  },
  [setEditableGroup]: (state, { payload }) => {
    return {
      ...state,
      editableGroup: payload
    };
  },
  [setAddingQuestionToGroup]: (state, { payload }) => ({
    ...state,
    addingQuestionToGroup: payload
  }),
  [setComments]: (state, { payload }) => {
    return {
      ...state,
      comments: { ...state.comments, ...payload }
    };
  },
  [setSelectingQuestionsFromSaved]: (state, { payload }) => ({
    ...state,
    selectingQuestionsFromSaved: payload,
    selectedQuestionsFromSaved: payload ? state.selectedQuestionsFromSaved : []
  }),
  [setSelectedQuestionsFromSaved]: (state, { payload }) => ({
    ...state,
    selectedQuestionsFromSaved: payload
  }),
  [setAddingQuestionGroup]: (state, { payload }) => ({ ...state, addingQuestionGroup: payload }),
  [setSortedQuestionGroups]: (state, { payload }) => ({ ...state, sortedQuestionGroups: payload }),
  [updateSortedQuestionGroupBindings]: (state, { payload }) => ({
    ...state,
    sortedQuestionGroupBindings: {
      ...state.sortedQuestionGroupBindings,
      [payload.questionGroupId]: payload.bindings
    }
  }),
  [updateMultipleSortedQuestionGroupBindings]: (state, { payload }) => ({
    ...state,
    sortedQuestionGroupBindings: { ...state.sortedQuestionGroupBindings, ...payload }
  }),
  [setEditingColorZonesBeforePublish]: (state, { payload }) => ({
    ...state,
    editingColorZonesBeforePublish: payload
  }),
  [setEmployees]: (state, { payload }) => {
    return {
      ...state,
      employees: { ...state.employees, ...payload }
    };
  }
});
