import { InfoCircleOutlined } from '@ant-design/icons';
import { Select, InputNumber, Modal, Tooltip } from 'antd';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SSelect from 'components/Standard/SSelect';
import SText from 'components/Standard/SText';
import UserPreview from 'components/UserPreview';
import {
  CLIENT_INTERACTION_TYPES,
  REVIEWERS_COMMUNICATIONS_DISTRIBUTION_METHODS
} from 'core/utils/constants';
import { get, sum, uniq, isEqual } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Icon from 'components/Icon';
import { calcDistributions } from './calcDistributions';

const { Option } = Select;

const ReviewsWithDistribution = ({
  onChange,
  reviewersIds,
  distribution,
  removedReviewersIds,
  clientInteractionsTypesConfigs,
  reviewersCommunicationsDistributionMethod,
  setReviewersCommunicationsDistributionMethod
}) => {
  const { t } = useTranslation();

  // * типы коммуникаций для распределения
  const clientInteractionTypes = uniq(
    Object.values(clientInteractionsTypesConfigs).reduce((acc, communicationTypeFilters) => {
      if (get(communicationTypeFilters, '_destroy')) return acc;
      return [...acc, communicationTypeFilters?.clientInteractionType];
    }, [])
  );

  const getClientInteractionTypeValidation = clientInteractionType =>
    get(distribution, [clientInteractionType, 'totalDistributed'], 100) !== 100;

  // * подсчёт результата распределения по типу коммуникации
  const getTotalClientInteractionTypeDistribution = clientInteractionType => {
    const invalid = getClientInteractionTypeValidation(clientInteractionType);
    const totalDistributed = get(distribution, [clientInteractionType, 'totalDistributed'], 0);
    return (
      <>
        <SText>
          {`${t(CLIENT_INTERACTION_TYPES[clientInteractionType.toUpperCase()].title)}: `}
        </SText>
        <SText color={invalid ? 'var(--red_primary)' : undefined}>{`${totalDistributed}% `}</SText>
        <SText>/ 100%</SText>
      </>
    );
  };

  const commonUnitsIds = useSelector(
    state => state.organizationInfo.commonUnitsIdsforUsers,
    isEqual
  );
  const usersByIds = useSelector(state => state.usersResource.byIds, isEqual);

  useEffect(() => {
    calcDistributions({
      communicationsDistributionMethod: reviewersCommunicationsDistributionMethod,
      clientInteractionTypes,
      reviewersIds,
      setDistribution: onChange,
      distribution
    });
  }, [clientInteractionTypes.join(','), reviewersIds, removedReviewersIds]);

  const updateManuallyDistribution = ({ value, clientInteractionType, userId }) => {
    setReviewersCommunicationsDistributionMethod(
      REVIEWERS_COMMUNICATIONS_DISTRIBUTION_METHODS.MANUAL.value
    );
    const data = { ...distribution[clientInteractionType] };
    data.byIds[userId] = value;
    data.totalDistributed = sum(Object.values(data.byIds));
    const result = {
      ...distribution,
      [clientInteractionType]: data
    };
    onChange(result);
  };

  const getDistributionInputHandler = (clientInteractionType, userId) => value =>
    updateManuallyDistribution({
      clientInteractionType,
      userId,
      value: parseInt(value || 0)
    });

  const getDistributionValue = (clientInteractionType, userId) => {
    return get(distribution, [clientInteractionType, 'byIds', userId]) || 0;
  };

  return (
    <SRow>
      <SCol span={24}>
        <SRow
          gutter={[0, 16]}
          style={{ marginBottom: '-8px' }}
          justify="space-between"
          align="middle"
        >
          <SCol span={8}>
            <SRow align="middle" gutter={[20, 0]} justify="start">
              <SCol>
                <SText fontSize="20px" fontWeight="500">
                  {t('general.reviewers')}
                </SText>
              </SCol>
              <SCol>
                <SSelect
                  width="100%"
                  minWidth="150px"
                  value={reviewersCommunicationsDistributionMethod}
                  onChange={method => {
                    return method === REVIEWERS_COMMUNICATIONS_DISTRIBUTION_METHODS.AUTO.value
                      ? Modal.confirm({
                          title: t('workPlanTaskConfigurationEditor.changeTypeDistribution'),
                          onOk: () => {
                            setReviewersCommunicationsDistributionMethod(
                              REVIEWERS_COMMUNICATIONS_DISTRIBUTION_METHODS.AUTO.value
                            );
                            calcDistributions({
                              communicationsDistributionMethod:
                                REVIEWERS_COMMUNICATIONS_DISTRIBUTION_METHODS.AUTO.value,
                              clientInteractionTypes,
                              reviewersIds,
                              setDistribution: onChange,
                              distribution
                            });
                          },
                          onCancel: () =>
                            setReviewersCommunicationsDistributionMethod(
                              REVIEWERS_COMMUNICATIONS_DISTRIBUTION_METHODS.MANUAL.value
                            ),
                          okText: t('general.continue'),
                          cancelText: t('general.cancel')
                        })
                      : setReviewersCommunicationsDistributionMethod(method);
                  }}
                >
                  {Object.values(REVIEWERS_COMMUNICATIONS_DISTRIBUTION_METHODS).map(
                    ({ value, title }) => (
                      <Option value={value}>{t(title)}</Option>
                    )
                  )}
                </SSelect>
              </SCol>
            </SRow>
          </SCol>

          <SCol span={16}>
            <SRow gutter={[16, 0]} justify="end">
              {clientInteractionTypes.map(type => (
                <SCol width="20%" style={{ justifyContent: 'flex-end' }}>
                  <SText>{getTotalClientInteractionTypeDistribution(type)}</SText>
                </SCol>
              ))}
            </SRow>
          </SCol>
        </SRow>

        <SRow gutter={[0, 22]} style={{ marginBottom: '-12px' }}>
          {reviewersIds.map(userId => {
            const isExistsCommonUnits = commonUnitsIds.includes(usersByIds[userId]?.unitId);
            return (
              <>
                <SCol span={8}>
                  <SRow gutter={[8, 0]}>
                    <SCol>
                      <UserPreview userId={userId} disabled showAvatar />
                    </SCol>
                    {!isExistsCommonUnits && (
                      <SCol display="flex" alignItems="center">
                        <Tooltip title="Проверяющий не может быть добавлен к задаче т.к. не имеет прав доступа к записям одного или нескольких операторов.">
                          <Icon icon={InfoCircleOutlined} color="var(--red_primary)" />
                        </Tooltip>
                      </SCol>
                    )}
                  </SRow>
                </SCol>

                <SCol span={16}>
                  <SRow align="middle" gutter={[16, 0]} justify="end">
                    {clientInteractionTypes.map(type => (
                      <SCol style={{ width: '20%' }}>
                        {/* ant-form-item-has-error */}
                        <InputNumber
                          style={{ width: '100%' }}
                          min={0}
                          max={100}
                          formatter={value => `${value ? `${value}%` : ''}`}
                          {...(getClientInteractionTypeValidation(type)
                            ? {
                                borderColor: 'var(--red_primary)',
                                outline: 'none',
                                boxShadow: '0 0 0 2px rgba(245, 34, 45, 0.2)'
                              }
                            : {})}
                          onChange={getDistributionInputHandler(type, userId)}
                          value={getDistributionValue(type, userId)}
                        />
                      </SCol>
                    ))}
                  </SRow>
                </SCol>
              </>
            );
          })}
        </SRow>
      </SCol>
    </SRow>
  );
};

export default ReviewsWithDistribution;
