export default {
  uploadCallsPage: {
    messages: {
      successfullyLoaded: 'cargado con éxito',
      loadFailed: 'No se pudo cargar el archivo',
      maxFileText: 'Tamaño del archivo',
      maxFileSize: 'supera los 30 MB.',
      fileFormatText: 'Extensión de archivo incorrecta',
      fileFormatMessage: 'solo se permiten archivos mp3, wav o csv.',
      fileFormat:
        'Puede descargar archivos en formato mp3, wav o csv, de hasta 30 megabytes / archivo'
    },
    buttons: {
      loadCalls: 'Cargar las llamadas',
      downloadExample: 'Ejemplo CSV'
    },
    table: {
      columns: {
        operator: 'Operador',
        direction: 'Tipo de llamada',
        startedAt: 'Fecha de llamada',
        startedAtPlaceholder: 'Elija la hora',
        clientPhoneNumber: 'Número de teléfono',
        duration: 'Duración de la llamada',
        actions: 'Acción'
      },
      uinstalledCallDirection: 'No instalado',
      confirmChange: {
        title: 'Los cambios no se guardarán',
        ok: 'Continuar',
        cancel: 'Cancelar'
      },
      buttons: {
        cancel: 'Cancelar',
        edit: 'Redactar',
        delete: 'Eliminar'
      },
      confirmDelete: {
        title: '¿Está seguro?',
        ok: 'Eliminar',
        cancel: 'Cancelar'
      }
    }
  }
};
