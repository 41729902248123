export default {
  general: {
    save: 'Guardar',
    cancel: 'Cancelación',
    canceling: 'Cancelar',
    edit: 'Redactar',
    answer: 'Contestar',
    delete: 'Eliminar',
    disagree: 'No estoy de acuerdo',
    accept: 'Aceptar',
    accepted: 'Aceptar',
    reject: 'Rechazar',
    rejected: 'Rechazar',
    from: 'Desde',
    to: 'Hasta',
    time: 'Tiempo',
    hidden: 'Oculto',
    visibleToAll: 'Público',
    update: 'Actualizar',
    add: 'Añadir',
    redFlag: 'Rojo',
    yellowFlag: 'Amarillo',
    greenFlag: 'Verde',
    withoutFlag: 'Sin bandera',
    uploadImage: 'Adjuntar imagen',
    loading: 'Cargando',
    requiredField: 'Campo obligatorio',
    continue: 'Continuar',
    date: 'Fecha',
    dateAndTime: 'Fecha y hora',
    direction: 'Dirección',
    number: 'Número',
    phoneNumber: 'Número de teléfono',
    tags: 'Etiquetas',
    tag: 'Etiqueta',
    reviewDate: 'Fecha de evaluación',
    clientInteractionDate: 'Fecha de comunicación',
    applicationDate: 'Fecha de aplicación',
    applicator: 'Archivado',
    checklistDefinition: 'Lista a chequear',
    checklistDefinitions: 'Listas a chequear',
    checklistDefinitionPlaceholder: 'Elija una lista a chequear',
    selectChecklist: 'Elija una forma de evaluación para comenzar la inspección',
    criterion: 'Pregunta',
    criteries: 'Criterios',
    clientInteractionType: 'Tipo',
    clientInteractionTypes: 'Tipos',
    status: 'Estatus',
    statuses: 'Estados',
    users: 'Usuarios',
    executor: 'Ejecutor',
    executors: 'Ejecutores',
    role: 'Papel',
    roles: 'Papel',
    changed: 'Cambió',
    change: 'cambió',
    view: 'Reloj',
    unit: 'Departamento',
    units: 'Departamentos',
    settings: 'Ajustes',
    analytics: 'Analítica',
    yes: 'Si',
    no: 'No',
    name: 'Nombre',
    lastName: 'Apellido',
    email: 'Correo electrónico',
    goTo: 'Ir a',
    employees: 'Empleados',
    employee: 'Empleado',
    all: 'Todos',
    initials: 'Nombres y Apellidos',
    duration: 'Duración',
    score: 'Evaluación',
    flagsAndComments: 'Banderas y comentarios',
    close: 'Terminar',
    stop: 'Detener',
    download: 'Descargar',
    reviewer: 'Verificador',
    reviewers: 'Revisores',
    total: 'Total',
    author: 'Autor',
    operator: 'Operador',
    result: 'Resultado',
    found: 'Encontró',
    comments: 'Сomentarios',
    title: 'Nombre',
    start: 'Comienzo',
    finish: 'Terminación',
    create: 'Crear',
    created: 'Creado',
    selected: 'Seleccionado',
    progress: 'Progreso',
    apply: 'Aceptar',
    period: 'Periodo',
    communication: 'comunicación',
    review: 'evaluación',
    startOfPeriod: 'Fecha de inicio',
    endOfPeriod: 'Fecha de finalización',
    displayByDate: 'Visualización por fecha: ',
    systemMessages: 'Mensajes del sistema',
    areHidden: 'están ocultos',
    opened: 'abiertos',
    showDetails: 'Mostrar detalles',
    empty: 'No hay datos',
    goBack: 'Atrás',
    send: 'Enviar'
  }
};
