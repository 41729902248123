import { Col, Row, Spin, Typography } from 'antd';
import { audioServiceOrigin } from 'core/config';
import qs from 'qs';
import nodePath from 'path';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Player from 'react-player';
import { useHistory } from 'react-router-dom';
import {
  setCurrentCallId,
  setUpdatingUrl,
  setUrl,
  updatePlayerState
} from 'redux/ui/recordPlayer/reducer';
import openSocket from 'socket.io-client';
import { useTranslation } from 'react-i18next';
import { isEqual, pick } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import truncateString from 'core/utils/truncateString';
import SModal from 'components/Standard/SModal';
import AudioTrack from './AudioTrack';
import RecordControls from './RecordControls';
import { getCredentialsNaumen } from '../../../redux/entities/naumenIntegration/operations';

const { Text } = Typography;

const RecordPlayer = ({
  messages,
  onMessageClick,
  review,
  call,
  comments,
  allowAttachTags = true,
  showTags = true,
  shouldLoad = true
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    playbackRate,
    isPlaying,
    wasPlayed,
    forcePlayed,
    playedSeconds,
    url,
    updatingUrl
  } = useSelector(
    state =>
      pick(state.uiRecordPlayer, [
        'playbackRate',
        'isPlaying',
        'wasPlayed',
        'forcePlayed',
        'playedSeconds',
        'url',
        'updatingUrl'
      ]),
    isEqual
  );

  const { phoneCallMediaUrl, mediaUrl } = call;
  const unsafeRecordUrl = phoneCallMediaUrl || mediaUrl;
  const [bufferedPercentage, setBufferedPercentage] = useState(0);
  const { t } = useTranslation();
  const player = useRef();
  const [auth, setAuth] = useState(null);

  const setTime = useCallback(
    seconds => {
      if (player.current && player.current.seekTo) {
        player.current.seekTo(seconds, 'seconds');
      }
    },
    [player?.current]
  );

  useEffect(() => {
    if (call) dispatch(setCurrentCallId(call.id));
    if (call.duration) dispatch(updatePlayerState({ duration: call.duration }));
  }, [call.id, call.duration]);

  useEffect(() => {
    const setInitState = async () => {
      const { search } = history.location;
      if (search) {
        const { t } = qs.parse(search, { ignoreQueryPrefix: true });
        if (t) {
          setTime(parseFloat(t));
          dispatch(
            updatePlayerState({
              playedSeconds: parseFloat(t),
              wasPlayed: false
            })
          );
        }
      }
    };
    setInitState();
  }, [history.location]);

  useEffect(() => {
    if (forcePlayed) {
      setTime(playedSeconds);
      dispatch(updatePlayerState({ forcePlayed: false }));
    }
  }, [playedSeconds]);

  useEffect(async () => {
    if ((url || unsafeRecordUrl) && (url || unsafeRecordUrl).includes('.nau')) {
      await getCredentialsNaumen(url || unsafeRecordUrl).then(res => {
        setAuth(res);
      });
    }
  }, []);

  const handleProgress = useCallback(
    async ({ playedSeconds, played, loaded }) => {
      if (playedSeconds === 0 && !wasPlayed) {
        return;
      }
      await dispatch(updatePlayerState({ playedSeconds, played }));
      setBufferedPercentage(loaded * 100);
    },
    [dispatch]
  );

  const initSocket = ({ isReload = false }) => {
    dispatch(setUpdatingUrl(true));

    const audioServiceOriginUrl = new URL(audioServiceOrigin);
    const socket = openSocket(audioServiceOriginUrl.origin, {
      reconnectionAttempts: 5,
      path: nodePath.join(audioServiceOriginUrl.pathname, 'socket.io')
    });
    socket.on('connect_error', () => dispatch(setUrl(null)));
    socket.on('connect_failed', () => dispatch(setUrl(null)));
    socket.on('disconnect', () => dispatch(setUrl(null)));
    socket.on('record trade error', () => dispatch(setUrl(null)));

    socket.emit('trade record url', { recordUrl: unsafeRecordUrl, callId: call.id });

    socket.on('traded record', ({ path }) => {
      const audioServiceRecordUrl = new URL(
        nodePath.join(audioServiceOriginUrl.pathname, path),
        audioServiceOriginUrl.origin
      );
      dispatch(setUrl(audioServiceRecordUrl.href));
      if (playedSeconds && isReload) setTime(playedSeconds);
    });

    return socket;
  };

  useEffect(() => {
    const socket = initSocket({ isReload: false });
    return () => {
      socket.emit('leave player page');
      dispatch(setUrl(null));
      dispatch(setUpdatingUrl(true));
      dispatch(updatePlayerState({ isPlaying: false }));
    };
  }, [unsafeRecordUrl, call.id]);

  // if (updatingUrl) {
  //   return (
  //     <Row
  //       type="flex"
  //       justify="center"
  //       align="middle"
  //       gutter={[8, 8]}
  //       style={{ margin: '-4px', height: '134px', width: '100%' }}
  //     >
  //       <Col>
  //         <Spin spinning={updatingUrl} tip={t('components.recordPlayer.loading')} />
  //       </Col>
  //     </Row>
  //   );
  // }

  const onPlay = () => {
    dispatch(updatePlayerState({ wasPlayed: true }));
    // ! remove autoplay attr from audio tag cuz it can cause double sound on some devices
    try {
      document.querySelector('audio').removeAttribute('autoplay');
    } catch (error) {
      console.log(error);
    }
  };

  const onError = () => {
    SModal.warning({
      title: 'Не удается воспроизвести запись',
      onOk: () => {
        initSocket({ isReload: true });
      },
      closable: true,
      okText: 'Перезагрузить плеер',
      content: (
        <Row gutter={[16, 16]} style={{ margin: '-8px' }}>
          <Col span={24}>
            <Text>
              Нажмите "Перезагрузить плеер", чтобы повторить попытку воспроизведения, или обратитесь
              в службу техподдержки CRM или телефонии со ссылкой на запись:
            </Text>
          </Col>
          <Col span={24}>
            <Text
              copyable={{
                text: unsafeRecordUrl,
                tooltips: [
                  t('constants.errors.loadingRecordError.tooltip.copy'),
                  t('constants.errors.loadingRecordError.tooltip.copied')
                ]
              }}
              className="truncated"
            >
              <a href={unsafeRecordUrl}>{truncateString(unsafeRecordUrl, 40)}</a>
            </Text>
          </Col>
        </Row>
      )
    });
  };

  return (
    <div>
      {updatingUrl ? (
        <Row
          type="flex"
          justify="center"
          align="middle"
          gutter={[8, 8]}
          style={{ margin: '-4px', height: '134px', width: '100%' }}
        >
          <Col>
            <Spin spinning={updatingUrl} tip={t('components.recordPlayer.loading')} />
          </Col>
        </Row>
      ) : (
        <>
          <Player
            url={url || unsafeRecordUrl}
            onProgress={handleProgress}
            style={{ display: 'none' }}
            onDuration={duration => dispatch(updatePlayerState({ duration }))}
            loop={false}
            playing={isPlaying}
            playbackRate={parseFloat(playbackRate)}
            progressInterval={100}
            ref={player}
            onError={onError}
            onPlay={onPlay}
            config={
              auth
                ? {
                    file: {
                      forceAudio: true,
                      forceHLS: true,
                      hlsOptions: {
                        xhrSetup(xhr: XMLHttpRequest, url: string) {
                          xhr.open('GET', url, true);
                          xhr.setRequestHeader(
                            'Authorization',
                            `Basic ${btoa(`${auth.username}:${auth.password}`)}`
                          );
                        }
                      }
                    }
                  }
                : {}
            }
          />
          <AudioTrack
            comments={comments}
            setTime={setTime}
            messages={messages}
            onMessageClick={onMessageClick}
            bufferedPercentage={bufferedPercentage}
          />
        </>
      )}

      <RecordControls
        call={call}
        reviewId={review ? review.id : null}
        setTime={setTime}
        allowAttachTags={allowAttachTags}
        showTags={showTags}
        shouldLoad={shouldLoad}
        recordUrl={url || unsafeRecordUrl}
        reloadFunction={() => {
          initSocket({ isReload: true });
        }}
      />
    </div>
  );
};

export default React.memo(RecordPlayer, isEqual);
