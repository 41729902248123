import { Alert, Col, Modal, Row, Typography } from 'antd';
import { ColorBox } from 'components/ColorZones/ColorZones';
import RateValues from 'components/Inputs/RateValues';
import { SCALE_TYPES, ZONE_TO_COLOR_RELATIONS } from 'core/utils/constants';
import { get, isEmpty, isNil, keyBy, max, min } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getChecklistDefinitionQuestions } from 'redux/selectors/checklistItems/checklistItems';
import { setQuestionIdDescriptionModal, updateAnswer } from 'redux/ui/checklistManager/reducer';

const { Text } = Typography;

const QuestionDescriptionModal = ({
  question = {},
  updateAnswer,
  setQuestionIdDescriptionModal,
  checklistDefinition,
  questionIdToAnswerValue,
  onAutoFail,
  disabled = false
}) => {
  const { t } = useTranslation();
  const { valuesDisplayType } = checklistDefinition;
  const {
    name,
    text,
    ratingValues = [],
    ratingValuesViews = [],
    id,
    scaleType,
    binding
  } = question;
  const value = get(questionIdToAnswerValue, id, null);
  const colorZones =
    question?.colorZones ||
    get(SCALE_TYPES, [scaleType, 'colorZones'], SCALE_TYPES.max_5.colorZones);

  const lowRatingValues = (() => {
    return ratingValues.length > 2
      ? ratingValues.filter(value => value < colorZones.low.stop)
      : [min(ratingValues)];
  })();

  const mediumRatingValues = ratingValues.filter(
    value => value < get(colorZones, 'medium.stop') && value >= get(colorZones, 'low.stop')
  );

  const highRatingValues = (() => {
    return ratingValues.length > 2
      ? ratingValues.filter(value => value >= get(colorZones, 'medium.stop', colorZones.low.stop))
      : [max(ratingValues)];
  })();

  let lowRatingViews;
  let mediumRatingViews;
  let highRatingViews;

  if (!isEmpty(ratingValuesViews)) {
    lowRatingViews = ratingValues.reduce((acc, curr, i) => {
      if (lowRatingValues.includes(curr)) {
        return [...acc, ratingValuesViews[i]];
      }

      return acc;
    }, []);

    mediumRatingViews = ratingValues.reduce((acc, curr, i) => {
      if (mediumRatingValues.includes(curr)) {
        return [...acc, ratingValuesViews[i]];
      }

      return acc;
    }, []);

    highRatingViews = ratingValues.reduce((acc, curr, i) => {
      if (highRatingValues.includes(curr)) {
        return [...acc, ratingValuesViews[i]];
      }

      return acc;
    }, []);
  }

  const onChange = newValue => {
    updateAnswer({ questionId: id, value: newValue });

    if (
      get(binding, 'checklistAutofailValues', []).includes(newValue) &&
      binding?.checklistAutofailEnabled
    ) {
      onAutoFail();
    }
  };

  const onCancel = () => {
    setQuestionIdDescriptionModal(null);
  };

  const visible = !isEmpty(question);

  return (
    <Modal
      centered
      destroyOnClose
      title={name}
      visible={visible}
      onCancel={onCancel}
      okText={t('components.checklistManager.questionDescriptionModal.modal.ok')}
      cancelText={t('components.checklistManager.questionDescriptionModal.modal.cancel')}
      onOk={onCancel}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Row gutter={[16, 16]} style={{ margin: '-8px' }}>
        <Col span={24}>
          {text && (
            <Alert
              message={t('components.checklistManager.questionDescriptionModal.modal.alert.title')}
              description={<Text style={{ whiteSpace: 'pre-wrap' }}>{text}</Text>}
              type="info"
            />
          )}
        </Col>
        {/* high zone */}
        {colorZones.high && (
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={4}>
                <ColorBox color={ZONE_TO_COLOR_RELATIONS.high} style={{ marginTop: '.5em' }} />
              </Col>
              <Col span={20}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Text>{t(colorZones.high.text)}</Text>
                  </Col>

                  <Col span={24}>
                    <RateValues
                      disabled={disabled}
                      showNull={false}
                      value={value}
                      scaleType={scaleType}
                      ratingValues={highRatingValues}
                      ratingValuesViews={highRatingViews || highRatingValues}
                      onChange={onChange}
                      valuesDisplayType={valuesDisplayType}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
        {/* medium zone */}
        {!isNil(colorZones?.medium?.stop) && ratingValues.length > 2 && (
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={4}>
                <ColorBox color={ZONE_TO_COLOR_RELATIONS.medium} style={{ marginTop: '.5em' }} />
              </Col>
              <Col span={20}>
                <Row gutter={[16, 16]}>
                  <Col>
                    <Text>{t(colorZones.medium.text)}</Text>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <RateValues
                      disabled={disabled}
                      showNull={false}
                      value={value}
                      scaleType={scaleType}
                      ratingValues={mediumRatingValues}
                      ratingValuesViews={mediumRatingViews}
                      onChange={onChange}
                      valuesDisplayType={valuesDisplayType}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
        {/* low zone */}
        {colorZones.low && (
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={4}>
                <ColorBox color={ZONE_TO_COLOR_RELATIONS.low} style={{ marginTop: '.5em' }} />
              </Col>
              <Col span={20}>
                <Row gutter={[16, 16]}>
                  <Col>
                    <Text>{t(colorZones.low.text)}</Text>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <RateValues
                      disabled={disabled}
                      showNull={false}
                      value={value}
                      scaleType={scaleType}
                      ratingValues={lowRatingValues}
                      ratingValuesViews={lowRatingViews}
                      onChange={onChange}
                      valuesDisplayType={valuesDisplayType}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Modal>
  );
};

const mapStateToProps = state => {
  const {
    currentChecklist,
    questionIdToAnswerValue,
    questionIdDescriptionModal
  } = state.uiChecklistManager;

  const checklistDefinition = get(
    state.checklistDefinitionsResource.byIds,
    currentChecklist.checklistDefinitionId,
    {}
  );

  const checklistDefinitionQuestionsByIds = keyBy(
    getChecklistDefinitionQuestions(state, checklistDefinition),
    'id'
  );

  const question = get(checklistDefinitionQuestionsByIds, questionIdDescriptionModal, {});

  return { currentChecklist, questionIdToAnswerValue, question, checklistDefinition };
};

const mapDispatchToProps = {
  updateAnswer,
  setQuestionIdDescriptionModal
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionDescriptionModal);
