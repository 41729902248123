import {
  amocrmBulkActionFieldsEndpoint,
  amocrmConnectCustomFieldEndpoint,
  amocrmCustomFieldsEndpoint,
  amocrmSynchronizationEndpoint,
  amocrmUnConnectCustomFieldEndpoint,
  handlerEndpoint
} from 'core/api';
import { setIntegrationId, setShowBJModal } from 'redux/ui/registerFromIntegration/reducer';
import decamelize from 'decamelize-keys';
import { processJsonApiCollection, processJsonApiObject } from 'core/jsonapi';
import { get, isEmpty } from 'lodash';
import { signInUser } from 'redux/reducers/reduxTokenAuthReducer';
import AmoService from 'core/services/amocrm';
import { getCurrentUser } from 'redux/selectors/users';
import { handleError } from 'core/services/errors';
import { AMO_COMMANDS } from 'core/utils/constants';
import { amoIntegrationId } from 'core/config';
import { integrationsResource } from 'redux/resources/integrations';
import { saveBackgroundJob } from '../backgroundJob/operations';

export const registerWithAmo = ({
  password,
  email,
  organizationName,
  id,
  firstName,
  lastName,
  phoneNumber,
  domain,
  onSuccess
}) => async (dispatch, getState) => {
  const reqBody = decamelize({
    action: 'amocrm/create_organization',
    adminPassword: password,
    organizationName,
    adminIntegrationUid: id,
    adminPhoneNumber: phoneNumber,
    adminEmail: email,
    adminFirstName: firstName,
    adminLastName: lastName,
    endpoint: domain
  });
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(reqBody)
  };
  let response = await fetch(handlerEndpoint, requestOptions);
  response = await response.json();
  if (!!!response.data) {
    return null;
  }
  await dispatch(signInUser({ email, password }));

  const user = getCurrentUser(getState());
  const organization = processJsonApiObject(response.data);
  localStorage.setItem('curUser', response.data.relationships['admin-user'].data.id);
  const clientId = isEmpty(organization?.amocrmClientId)
    ? amoIntegrationId
    : organization?.amoClientId;

  // popup или post_message
  const amoAuthUrl = `https://www.amocrm.ru/oauth?client_id=${clientId}&state=${organization.id}&mode=post_message`;

  const amoConfirmWindow = window.open(amoAuthUrl);

  window.addEventListener('message', ({ data }) => {
    if (data.errors) {
      amoConfirmWindow.close();
      return handleError(null, data);
    }

    if (data.integration) {
      AmoService.sendMessage({
        command: AMO_COMMANDS.SEND_USER_DATA,
        params: {
          dealappUser: {
            ...user,
            'access-token': localStorage.getItem('access-token'),
            client: localStorage.getItem('client'),
            uid: localStorage.getItem('uid'),
            expiry: localStorage.getItem('expiry')
          }
        }
      });

      amoConfirmWindow.close();
      const integration = processJsonApiObject(data.integration.data);
      dispatch(saveBackgroundJob(integration.currentBackgroundJob));
      dispatch(setIntegrationId(integration.id));
      dispatch(setShowBJModal(true));
      onSuccess(integration);
    }
  });
};

export const syncDataWithAmo = ({ id, fetchFrom }) => async dispatch => {
  let response = await fetch(amocrmSynchronizationEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      integration_id: id,
      fetch_from: fetchFrom
    })
  });
  response = await response.json();
  // history.push(`${url}/${integrationType}/${integration.id}`);
  // const response = await api.amoSyncDataByPeriod(
  //   { id },
  //   decamelize({
  //     fetchFrom
  //   })
  // );

  // const backgroundJob = processJsonApiObject(response.body.data);

  // return dispatch(saveBackgroundJob(backgroundJob));
};

export const createAmoIntegration = ({ name, integrationType, history, url }) => async (
  dispatch,
  getState
) => {
  const user = getCurrentUser(getState());
  const organizationId = get(user, 'organization-id');
  const organizationClientId = get(user, 'organization.amocrm-client-id');
  const clientId = isEmpty(organizationClientId) ? amoIntegrationId : organizationClientId;

  const amoAuthUrl = `https://www.amocrm.ru/oauth?client_id=${clientId}&state=${organizationId}&mode=post_message`;

  const amoConfirmWindow = window.open(amoAuthUrl);
  window.addEventListener('message', ({ data }) => {
    if (data.errors) {
      amoConfirmWindow.close();
      return handleError(null, data);
    }

    if (data.integration) {
      AmoService.sendMessage({
        command: AMO_COMMANDS.SEND_USER_DATA,
        params: {
          dealappUser: {
            ...user,
            'access-token': localStorage.getItem('access-token'),
            client: localStorage.getItem('client'),
            uid: localStorage.getItem('uid'),
            expiry: localStorage.getItem('expiry')
          }
        }
      });

      amoConfirmWindow.close();
      const integration = processJsonApiObject(data.integration.data);
      dispatch(saveBackgroundJob(integration.currentBackgroundJob));
      dispatch(setIntegrationId(integration.id));
      dispatch(
        integrationsResource.operations.updateById({
          id: integration.id,
          name
        })
      );
      history.push(`${url}/${integrationType}/${integration.id}`);

      return integration.id;
    }
  });
};

export const reconnectToAmo = () => async (dispatch, getState) => {
  const user = getCurrentUser(getState());
  const organizationId = get(user, 'organization-id');
  const organizationClientId = get(user, 'organization.amocrm-client-id');
  const clientId = isEmpty(organizationClientId) ? amoIntegrationId : organizationClientId;

  const amoAuthUrl = `https://www.amocrm.ru/oauth?client_id=${clientId}&state=${organizationId}&mode=post_message`;
  const amoConfirmWindow = window.open(amoAuthUrl);
  window.addEventListener('message', ({ data }) => {
    if (data.errors) {
      amoConfirmWindow.close();
      return handleError(null, data);
    }

    if (data.integration) {
      AmoService.sendMessage({
        command: AMO_COMMANDS.SEND_USER_DATA,
        params: {
          dealappUser: {
            ...user,
            'access-token': localStorage.getItem('access-token'),
            client: localStorage.getItem('client'),
            uid: localStorage.getItem('uid'),
            expiry: localStorage.getItem('expiry')
          }
        }
      });

      amoConfirmWindow.close();
    }
  });
};

export const getEntityCustomFields = ({ id }) => async dispatch => {
  try {
    let response = await fetch(amocrmCustomFieldsEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        integration_id: id
      })
    });
    response = await response.json();
    const resources = processJsonApiCollection(response.data);
    return resources;
  } catch (error) {}
};

export const connectCustomField = ({ id, fieldId }) => async dispatch => {
  try {
    await fetch(amocrmConnectCustomFieldEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        field_id: fieldId
      })
    });
    return true;
  } catch (error) {}
};

export const connectAllCustomFields = ({ id, all }) => async dispatch => {
  try {
    await fetch(amocrmBulkActionFieldsEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        integration_id: id,
        connect: all
      })
    });
    return true;
  } catch (error) {}
};

export const disconnectCustomField = ({ id, fieldId }) => async dispatch => {
  try {
    await fetch(amocrmUnConnectCustomFieldEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        field_id: fieldId
      })
    });
    return true;
  } catch (error) {}
};
