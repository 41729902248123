export default {
  datasetAiPage: {
    title: 'Dataset',
    buttons: {
      delete: 'Удалить',
      create: 'Новый Dataset'
    },
    statuses: {
      title: 'Статусы',
      done: 'Завершена',
      process: 'В процессе',
      error: 'Ошибка'
    },
    columns: {
      title: 'Название',
      createdAt: 'Дата создания',
      updatedAt: 'Дата изменения',
      createdBy: 'Кем создан'
    },
    placeholders: {
      name: 'По названию',
      status: 'По статусу',
      createdBy: 'Кем создан'
    },
    messages: {
      enterName: 'Пожалуйста, введите название',
      loadFile: 'Пожалуйста, загрузите файл',
      deleteSuccess: 'Успешо удалено'
    },
    modalConfirmDelete: {
      title: 'Удалить Dataset?',
      description: 'Вы уверены, что хотите удалить Dataset(-s)?',
      massDescription:
        'Вы уверены, что хотите удалить весь список Dataset? Это может занять некоторое время',
      ok: 'Удалить',
      cancel: 'Отменить',
      errors: {
        errorTimeoutTitle: 'Удаление пользователя',
        errorTimeout:
          'Похоже, что это займет немного больше времени. Можете продолжать работу в системе, пользователь вскоре будет удален.'
      }
    }
  }
};
