import { createSlice } from '@reduxjs/toolkit';
import { get, uniq } from 'lodash';
import parseOptionsForApi from 'core/utils/parseOptionsForApi';
import decamilize from 'decamelize';
import { stringify, parse } from 'core/utils/queryString';

const parseSorter = sorter => {
  if (!sorter.order) return '';
  return `${sorter.order === 'descend' ? '-' : ''}${decamilize(sorter.columnKey, '_')}`;
};

export const preDefaultState = {
  query: stringify({ page: 1, size: 25 }),
  filters: {},
  page: {
    number: '1',
    size: '25'
  },
  totalPages: 1,
  sort: '',
  ids: [],
  loading: false
};

export const createLoadOperation = (loader, actions) => {
  return options => async dispatch => {
    dispatch(actions.startedLoading());
    const uriConfig = parseOptionsForApi(options);
    try {
      const { resources, meta } = await dispatch(loader(uriConfig));
      dispatch(
        actions.loadedData({
          ids: Object.keys(resources),
          totalPages: get(meta, 'totalPages', '1'),
          totalCount: get(meta, 'totalCount', '25')
        })
      );
      return resources;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
};

const createListReducer = ({
  name,
  additionalReducer = {},
  defaultState = {},
  rebuildQuery,
  keepResults = false,
  keepResultsUnique = false,
  loader
}) => {
  const state = {
    ...preDefaultState,
    ...defaultState
  };

  if (rebuildQuery) {
    state.query = stringify({ page: state.page, filters: state.filters, sort: state.sort });
  }

  const dataHandler = (state, payload) => {
    if (!keepResults) return payload.ids;

    return keepResultsUnique
      ? uniq([...state.ids, ...payload.ids])
      : [...state.ids, ...payload.ids];
  };

  const listReducer = {
    setCurrentQuery: (state, { payload }) => {
      const settings = parse(payload);
      if (!settings.sort) settings.sort = [];
      return {
        ...state,
        query: payload,
        ...settings
      };
    },

    updateFilters: (state, { payload }) => {
      const filters = { ...state.filters, ...payload };
      const page = { ...state.page, number: '1' };
      return {
        ...state,
        page,
        filters,
        query: stringify({ filters, sort: state.sort, page: state.page })
      };
    },
    setFilters: (state, { payload }) => {
      const page = { ...state.page, number: '1' };
      return {
        ...state,
        page,
        filters: payload,
        query: stringify({ filters: payload, sort: state.sort, page: state.page })
      };
    },
    setSorting: (state, { payload }) => {
      const sort = parseSorter(payload);
      return {
        ...state,
        sort,
        query: stringify({ filters: state.filters, sort, page: state.page })
      };
    },
    updatePage: (state, { payload }) => {
      const page = { ...state.page, ...payload };
      return {
        ...state,
        page,
        query: stringify({ page, sort: state.sort, filters: state.filters })
      };
    },
    setPage: (state, { payload }) => {
      return {
        ...state,
        page: payload,
        query: stringify({ page: payload, sort: state.sort, filters: state.filters })
      };
    },
    startedLoading: state => ({ ...state, loading: true }),
    clearList: state => ({ ...state, ids: [], page: { ...state.page, number: '1' } }),
    loadedData: (state, { payload }) => {
      const conditionalPageObject = payload?.page ? { page: payload?.page } : undefined;

      return {
        ...state,
        ...conditionalPageObject,
        ids: dataHandler(state, payload),
        totalPages: payload.totalPages,
        totalCount: payload.totalCount,
        loading: false
      };
    }
  };

  const slice = createSlice({
    reducers: { ...listReducer, ...additionalReducer },
    name: `${name}List`,
    initialState: state
  });

  const operations = {
    load: createLoadOperation(loader, slice.actions)
  };

  return {
    ...slice,
    operations
  };
};

export default createListReducer;
