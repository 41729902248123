import { Trash2 } from 'react-feather';
import AddToCalibrationModal from 'components/AddToCalibrationModal';
import { Button, Col, message, Modal, Row, Form } from 'antd';
import ChecklistDefinitionsSelect from 'components/Checklist/ChecklistDefinitionsSelect';
import ChecklistManager from 'components/Checklist/ChecklistManager';
import CustomAlert from 'components/CustomAlert';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import { openAddToCalibrationModal } from 'redux/ui/addToCalibrationModal/reducer';
import {
  CHECKLIST_DEFINITION_STATUS,
  CHECKLIST_MANAGER_STATES,
  PERMISSIONS
} from 'core/utils/constants';
import { selectSearch } from 'core/utils/selectSearch';
import { every, get, isEmpty, isEqual, isNil, pickBy, reduce, throttle } from 'lodash';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Prompt, useHistory } from 'react-router-dom';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import { clientInteractionsResource } from 'redux/resources/clientInteractions';
import { commentsResource } from 'redux/resources/comments';
import { usersResource } from 'redux/resources/users';
import {
  getChecklistsDefinitionsByIds,
  getChecklistsDefinitionsByParams
} from 'redux/selectors/checklists';
import { getCurrentUser, getUsersByIds } from 'redux/selectors/users';
import { createChecklistFromDefinition } from 'redux/ui/checklistManager/operations';
import {
  setChecklistManagerState,
  setInitialState as setInitialChecklistManagerState
} from 'redux/ui/checklistManager/reducer';
import { setCommunication } from 'redux/ui/clientInteractionDrawer/reducer';
import { submitReview } from 'redux/ui/clientInteractionPage/operations';
import {
  addComment,
  deleteComment,
  setAddingComment,
  setInitialState,
  updateComment
} from 'redux/ui/clientInteractionPage/reducer';
import { updateTableRow } from 'redux/ui/clientInteractions/operations';

import { updateCustomCommunicationForm } from 'redux/ui/customCommunicationReviewPage/reducer';
import Icon from 'components/Icon';
import CustomCommunicationForm from './components/CustomCommunicationForm';

const { confirm } = Modal;

const defaultCommunication = {};

// TODO: refactor selectors and callbacks
const CustomCommunicationReviewPage = ({
  communication = defaultCommunication,
  review = undefined,
  fromDrawer = false
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const addingComment = useSelector(state => state.uiClientInteractionPage.isAddingComment);
  const loadingCheckilstDefinitions = useSelector(
    state => state.checklistDefinitionsResource.loading
  );
  const { currentChecklist, checklistManagerState, questionIdToAnswerValue } = useSelector(
    state => state.uiChecklistManager,
    isEqual
  );

  const checklistDefinitionId = currentChecklist?.checklistDefinitionId;
  const isEmptyQuestionsValues = every(questionIdToAnswerValue, isNil);

  const canMakeReview = useSelector(state =>
    get(getCurrentUser(state), 'role.permissions', []).includes(PERMISSIONS.CAN_MAKE_REVIEW)
  );

  const communicationType = useSelector(
    state => state.uiCustomCommunicationReview?.form?.communicationType?.value
  );

  const communicationTypes = communicationType ? [communicationType] : undefined;

  const checklistDefinitions = useSelector(state =>
    getChecklistsDefinitionsByParams(
      state,
      {
        params: {
          statuses: [CHECKLIST_DEFINITION_STATUS.PUBLISHED.value],
          communicationTypes
        }
      },
      isEqual
    )
  );
  const checklistDefinitionsByIds = useSelector(
    state => getChecklistsDefinitionsByIds(state),
    isEqual
  );

  const { creatingReview, updatingReview } = useSelector(
    state => ({
      creatingReview: state.reviewsResource.createStarted,
      updatingReview: state.reviewsResource.updateByIdStarted
    }),
    isEqual
  );

  const { creatingChecklist, updatingChecklist } = useSelector(
    state => ({
      creatingChecklist: state.checklistsResource.createStarted,
      updatingChecklist: state.checklistsResource.updateByIdStarted
    }),
    isEqual
  );

  const communicationLoading = useSelector(state => state.customCommunication.loading);

  const [saveLoading, setSaveLoading] = useState(false);

  const actionsLoading =
    creatingReview ||
    updatingReview ||
    creatingChecklist ||
    updatingChecklist ||
    communicationLoading ||
    saveLoading;

  const comments = useSelector(state => state.uiClientInteractionPage.commentsByIds, isEqual);

  const fields = useSelector(state => state.uiCustomCommunicationReview.form, isEqual);

  const users = useSelector(getUsersByIds);

  const currentUser = useSelector(state => getCurrentUser(state));

  const reviewAuthorId = review?.reviewerId;

  const canSave =
    (!!currentChecklist && canMakeReview) ||
    get(currentUser, 'role.permissions', []).includes(PERMISSIONS.CAN_REPLY_COMMENTS);

  useEffect(() => {
    if (!fromDrawer) {
      dispatch(
        checklistDefinitionsResource.operations.load({
          include: 'question_groups.questions,question_groups.question_to_group_bindings',
          pagination: 'false'
        })
      );
      dispatch(
        usersResource.operations.load({
          pagination: 'false',
          include: 'unit,role',
          sort: 'last_name'
        })
      );
    }

    return () => {
      dispatch(setInitialState());
      dispatch(setInitialChecklistManagerState());
    };
  }, []);

  const goToReview = async reviewId => {
    fromDrawer
      ? dispatch(setCommunication({ type: 'review', id: reviewId }))
      : history.push(`/reviews/${reviewId}`);
  };

  const onCommentSave = comment => {
    dispatch(addComment(comment));
  };

  const onDeleteComment = ({ id, commentType }) => {
    if (
      checklistManagerState !== CHECKLIST_MANAGER_STATES.EDITING &&
      commentType === 'review_comment'
    ) {
      return message.warn(t('customCommunicationPage.messages.needToChangeMode'));
    }

    dispatch(deleteComment({ id }));
  };

  const onUpdateComment = async comment => {
    let newComment = comment;
    if (checklistManagerState === CHECKLIST_MANAGER_STATES.SAVED) {
      try {
        newComment = await dispatch(commentsResource.operations.updateById(comment));
        message.success(t('customCommunicationPage.messages.commentSuccessfullyUpdated'));
      } catch (error) {
        console.log(error);
        message.error(t('customCommunicationPage.messages.updateCommentFailed'));
      }
    } else {
      dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.EDITING));
    }

    dispatch(updateComment(newComment));
  };

  const onSaveOrEdit = throttle(async () => {
    if (checklistManagerState !== CHECKLIST_MANAGER_STATES.EDITING) {
      return dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.EDITING));
    }

    try {
      await form.validateFields();
      form.submit();
      setSaveLoading(!saveLoading);
    } catch (error) {
      console.log(error);
      form.scrollToField(error?.errorFields[0].name);
      return;
    }

    if (isEmptyQuestionsValues) {
      message.warning(t('customCommunicationPage.messages.isEmptyQuestionsValues'));
      setSaveLoading(saveLoading);
      return;
    }

    const reviewCommunication = communication;

    try {
      const reviewId = await dispatch(
        submitReview({
          id: review ? review.id : reviewCommunication.id,
          review,
          fromDrawer,
          customCommunicationAttributes: {
            ...reduce(
              fields,
              (acc, input, key) => {
                // * skip metadata form fields
                if (key.includes('metadataAdditional') || key.includes('metadataMediaUrls'))
                  return acc;

                if (key === 'duration' && moment.isMoment(input.value))
                  return {
                    ...acc,
                    [key]: input.value.diff(input.value.clone().startOf('day'), 'seconds')
                  };

                if (moment.isMoment(input.value))
                  return { ...acc, [key]: input.value.toISOString() };
                // * map fields with values
                return { ...acc, [key]: input.value };
              },
              {}
            ),

            metadata: {
              additional: get(fields, 'metadataAdditional.value', ''),
              // * преобразуем филды с ссылками в массив для поддержки старых коммуникаций
              mediaUrls: Object.values(
                pickBy(fields, ({ name = [] }) => name.includes('metadataMediaUrls'))
              ).map(item => item.value)
            }
          }
        })
      );

      if (reviewId !== review?.id) {
        message.success(t('customCommunicationPage.messages.communicationSuccessfullyCreated'));
        return goToReview(reviewId);
      }

      if (fromDrawer) {
        dispatch(setCommunication({ type: 'review', id: reviewId }));
      }

      dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.SAVED));
      message.success(t('customCommunicationPage.messages.communicationSuccessfullyUpdated'));
    } catch (error) {
      console.log(error);
      return message.error(t('customCommunicationPage.messages.updateCommunicationFailed'));
    }
  }, 150);

  const handleFormChange = changedFields => {
    dispatch(updateCustomCommunicationForm(changedFields));
  };

  const commentButtonText = (() => {
    if (checklistManagerState === CHECKLIST_MANAGER_STATES.SAVED) {
      return t('customCommunicationPage.buttons.viewGeneralComment');
    }

    return isEmpty(review)
      ? t('customCommunicationPage.buttons.addGeneralComment')
      : t('customCommunicationPage.buttons.editGeneralComment');
  })();

  const calibrationButtonText = (() => {
    if (checklistManagerState === CHECKLIST_MANAGER_STATES.SAVED) {
      return t('components.checklistManager.checklistManagerHead.menu.addToCalibration');
    }
  })();

  const confirmDeleteReview = () =>
    confirm({
      title: t('customCommunicationPage.confirmDeleteReview.title'),
      content: t('customCommunicationPage.confirmDeleteReview.description'),
      onOk: async () => {
        try {
          await dispatch(
            clientInteractionsResource.operations.deleteById({ id: communication.id })
          );

          if (fromDrawer) {
            dispatch(updateTableRow({ clientInteractionId: communication.id }));
            dispatch(setCommunication({}));
          } else {
            history.push('/client-interactions');
          }

          message.success(t('customCommunicationPage.messages.reviewSuccessfullyDeleted'));
        } catch (error) {
          console.log(error);
          message.error(t('customCommunicationPage.messages.deleteReviewFailed'));
        }
      }
    });

  const isVisibleDeleteButton =
    checklistManagerState === CHECKLIST_MANAGER_STATES.EDITING &&
    currentUser?.id === reviewAuthorId;

  const ConditionalDeleteCol = withConditionalRender(
    {
      hide: !isVisibleDeleteButton
    },
    Col
  );

  const addToCalibration = () => dispatch(openAddToCalibrationModal());

  const activeOperator = get(users[communication?.operatorId], 'active', {});

  const availableChecklists = ({ checklistDefinitionId }) => {
    const availableChecklistsIds = checklistDefinitions.map(checklist => checklist.id);
    const availableChecklists = availableChecklistsIds.includes(checklistDefinitionId)
      ? availableChecklistsIds
      : [...availableChecklistsIds, checklistDefinitionId];

    return checklistDefinitionId
      ? availableChecklists.map(checklistId => get(checklistDefinitionsByIds, `${checklistId}`, {}))
      : checklistDefinitions;
  };

  return (
    <Row type="flex" justify="center">
      <Helmet>
        <title>
          {review?.id
            ? `${t('pagesMeta.customCommunicationReviewPage.titles.review')}`
            : `${t('pagesMeta.customCommunicationReviewPage.titles.addReview')}`}
        </title>
      </Helmet>
      <Col {...(fromDrawer ? { span: 24 } : { xl: 22, xxl: 20, xs: 24 })}>
        <Row gutter={[16, 16]}>
          {!activeOperator && (
            <Col span={24}>
              <CustomAlert
                type="warning"
                message={t('customCommunicationPage.messages.disabledOperator')}
                description={
                  <p>
                    {t('customCommunicationPage.messages.needToActivateOperator')}
                    <Link to={`/user/${currentUser?.id}/organization-settings/employees/`}>
                      {t('customCommunicationPage.buttons.viewOperatorSettings')}
                    </Link>
                  </p>
                }
              />
            </Col>
          )}

          {!fromDrawer && (
            <Col span={24}>
              <h1>{t('customCommunicationPage.title')}</h1>
            </Col>
          )}

          <Col span={24}>
            <CustomCommunicationForm
              form={form}
              communication={communication}
              fields={fields}
              onChange={handleFormChange}
              disabled={checklistManagerState === CHECKLIST_MANAGER_STATES.SAVED || review}
              reviewId={review?.id}
            />
          </Col>

          <Col span={8} style={{ padding: '8px 5px 8px 24px' }}>
            <ChecklistDefinitionsSelect
              placeholder={t('general.checklistDefinitionPlaceholder')}
              checklistDefinitions={availableChecklists({ checklistDefinitionId })}
              disabled={
                loadingCheckilstDefinitions ||
                checklistManagerState === CHECKLIST_MANAGER_STATES.SAVED
              }
              loading={loadingCheckilstDefinitions}
              onChange={checklistDefinitionId =>
                dispatch(createChecklistFromDefinition({ checklistDefinitionId }))
              }
              value={checklistDefinitionId}
              style={{ width: '100%', marginBottom: 16 }}
              showSearch
              filterOption={(input, option) =>
                selectSearch({ input, option, searchProp: 'searchValue' })
              }
            />
          </Col>

          <Col span={24}>
            <Prompt
              when={checklistManagerState === CHECKLIST_MANAGER_STATES.EDITING}
              message={t('customCommunicationPage.messages.unsavedReview')}
            />
            <ChecklistManager
              customCommunicationView
              onSubmit={onSaveOrEdit}
              onUpdateComment={onUpdateComment}
              onDeleteComment={onDeleteComment}
              onCommentSave={onCommentSave}
              comments={comments}
              reviewId={review?.id}
              loading={loadingCheckilstDefinitions}
              setAddingComment={setAddingComment}
              addingComment={addingComment}
              communication={communication}
              allowCommenting={canSave}
            />
          </Col>

          <Col span={24}>
            {checklistDefinitionId && (
              <Row type="flex" align="middle" justify="end">
                <Col>
                  <Row gutter={[16, 16]}>
                    <Col>
                      <Button
                        style={{ width: '100%' }}
                        loading={actionsLoading}
                        onClick={() => dispatch(setAddingComment(true))}
                      >
                        {commentButtonText}
                      </Button>
                    </Col>
                  </Row>
                  {checklistManagerState === CHECKLIST_MANAGER_STATES.SAVED && 
                    <Row gutter={[16, 16]}>
                      <Col>
                        <Button
                          style={{ width: '100%' }}
                          loading={actionsLoading}
                          onClick={e => addToCalibration(e)}
                        >
                          {calibrationButtonText}
                        </Button>
                      </Col>
                    </Row>
                  }
                  <AddToCalibrationModal clientInteraction={communication} />
                  <Row gutter={[16, 16]}>
                    <Col span={isVisibleDeleteButton ? 20 : 24}>
                      <Button
                        loading={actionsLoading}
                        disabled={
                          !canSave ||
                          actionsLoading ||
                          (review?.id && currentUser?.id !== review?.reviewerId)
                        }
                        type={
                          checklistManagerState === CHECKLIST_MANAGER_STATES.EDITING
                            ? 'primary'
                            : 'default'
                        }
                        style={{ width: '100%' }}
                        onClick={onSaveOrEdit}
                      >
                        {checklistManagerState === CHECKLIST_MANAGER_STATES.EDITING
                          ? t('customCommunicationPage.buttons.saveReview')
                          : t('customCommunicationPage.buttons.editReview')}
                      </Button>
                    </Col>
                    <ConditionalDeleteCol span={4}>
                      <Button
                        icon={<Icon icon={Trash2} />}
                        type="danger"
                        onClick={confirmDeleteReview}
                      />
                    </ConditionalDeleteCol>
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CustomCommunicationReviewPage;
