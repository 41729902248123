export default {
  constants: {
    accessLevels: {
      noInvitationSent: 'No se ha enviado la invitación',
      invitationSent: 'La invitación se ha enviado',
      invitationAccepted: 'Ha accedido',
      userBlocked: 'No hay acceso'
    },
    plans: {
      free: 'Libre',
      start: 'Comienzo',
      profi: 'Profi',
      notInstalled: 'No instalado'
    },
    plansDescription: {
      checklistConstructor: 'Constructor de lista a chequear',
      omnichannelReviewsCount100:
        '100 evaluaciones omnicanales (Llamadas, chat, e-mail, tickets) al mes',
      omnichannelReviews: 'Evaluaciones omnicanales (llamadas, chat, email, tickets)',
      dashboardsCount2: '2 paneles',
      unlimitedDashboards: 'Cantidad ilimitada de paneles',
      communicationPanel: 'Panel de comunición',
      library: 'Biblioteca de llamadas',
      emailNotification: 'Mensaje via e-mail',
      appNotifications: 'Mensaje en el anexo ',
      dashboardsShare: 'Utilizar los paneles conjuntamente',
      communicationsTableShare: 'Utilizar los paneles de comunicación conjuntamente',
      calibrations: 'Sesiones de calibración',
      unlimitedTags: 'Cantidad ilimitada de etiquetas automáticas',
      unlimitedIntegrations: 'Cantidad ilimitada de integraciones',
      integrationsCount1: '1 CRM o VoiP integración'
    },
    integrations: {
      customHttpTelephony: {
        title: 'API-telefonía',
        description: 'Integración con los programas de la centralita'
      },
      customHttpText: {
        title: 'API clanales de texto',
        description: 'Integración con los clanales de texto'
      },
      bitrixCrm: {
        title: 'Bitrix24',
        description: 'Integración Bitrix 24 CRM'
      },
      amoCrm: {
        title: 'AmoCRM',
        description: 'Integración con el AmoCRM'
      },
      sipuni: {
        title: 'Sipuni',
        description: 'Integración c Sipuni'
      },
      uis: { title: 'UIS/Comagic', description: 'Integración c UIS/Comagic' },
      naumen: {
        title: 'Naumen',
        description: 'Integration con Naumen'
      }
    },
    integrationsSyncPeroids: {
      month: 'Mes',
      threeMonth: 'Tres meses',
      sixMonth: 'Medio año',
      allTime: 'Todo el tiempo'
    },
    taskDefinitionTypes: {
      automatic: 'Automático',
      manual: 'Manual'
    },
    userStatuses: {
      active: 'Activo',
      disabled: 'Desactivado'
    },
    permissionsDescriptions: {
      canPerformClientInteraction: {
        title: 'El administrador se comunica con el cliente (llamadas, chat, tickets, e-mal)',
        description: ''
      },
      canSignIn: {
        title: 'El administrador tiene acceso al Deal App',
        description: ''
      },
      canSeeAndManageHiddenComments: {
        title: 'Añadir y ver los comentarios internos',
        description: ''
      },
      canAccessUserAnalyticsPageChartsTab: {
        title: 'Acceso al perfil personal',
        description: 'El administrador tiene acceso a la página de analítica personal'
      },
      canAccessUserAnalyticsPageReviewsTab: {
        title: 'Acceso a la lista de evaluaciones personales en el perfil individual',
        description:
          'El administrador ve la lista de las comunicaciones revisadas y los comentarios'
      },
      canAccessUserAnalyticsPageCommentsTab: {
        title: '',
        description: ''
      },
      canReplyCommnets: {
        title: 'Respuesta a los comentarios de su comunicación',
        description:
          'El administrador puede contesrar a los comentarios y evaluaciones de sus comunicaciones'
      },
      canAccessLibrary: {
        title: 'Acceso a la biblioteca de llamadas',
        description: ''
      },
      canAddLibraryItems: {
        title: 'Añadir documentos a la biblioteca',
        description: ''
      },
      canSeeUnitClientInteractions: {
        title: 'Ver todas las comunicaciones del departamento',
        description: ''
      },
      canDownloadClientInteraction: {
        title: 'Descargar archivo de comunicación',
        description: ''
      },
      canMakeReview: {
        title: 'Derecho a evaluar y comentar las comunicaciones de su departamento',
        description: ''
      },
      canSeeUnitReviews: {
        title: 'Ver las notas y comentarios de otros usuarios',
        description: ''
      },
      canSeeAndUpdateAssignedTasks: {
        title: 'Gestión de tus tareas',
        description: ''
      },
      canManageTasks: {
        title: 'Agregar y gestionar las tareas creadas',
        description: ''
      },
      canManageUnitTasks: {
        title: 'Agregar y gestionar las tareas del departamento',
        description: ''
      },
      canManageLibrary: {
        title: 'Gestión de la biblioteca de llamadas',
        description:
          'Posibilidad de añadir/borrar/redactar carpetas y archivos dentro de la biblioteca'
      },
      canManageChecklistDefinitions: {
        title: 'Gestión de las listas a chequear',
        description: ''
      },
      canManageTaskDefinitions: {
        title: 'Gestión de las etiquetas',
        description: ''
      },
      canManageOrganizationStructure: {
        title: 'Gestión de departamentos y usuarios',
        description: ''
      },
      canManageOrganizationSettings: {
        title: 'Gestión de los ajustes generales de la empresa',
        description: ''
      },
      canManageIntegrations: {
        title: 'Gestión de las integraciones',
        description: ''
      },
      canSeeDashboards: {
        title: 'Acceso a la analítica',
        description: ''
      },
      canSeeUnitOperatorsAnalyticsPage: {
        title: 'Acceso al perfil personal de los administradores que se comunican con los clientes',
        description: ''
      },
      canAccessTeamPage: {
        title: 'Acceso a la página "Equipo"',
        description: ''
      },
      canManageCalibrationSessions: {
        title: 'Acceso a las seciones de calibración',
        description: ''
      },
      canSeeReviewerName: {
        title: 'Visualización del nombre del revisor',
        description: ''
      },
      canManageWorkPlanTaskConfigurations: {
        title: 'Control de equilibrio de carga',
        description: ''
      },
      canCreateAppellations: {
        title: 'Presentar una apelación',
        description: ''
      },
      canAdministrateAppellations: {
        title: 'Administrar la configuración y eliminar apelaciones',
        description: ''
      },
      canWatchAppellations: {
        title: 'Sea parte de la apelación',
        description: ''
      },
      canSeeUnitAppellations: {
        title: 'Acceso al rastreador de apelaciones',
        description: ''
      },
      canAccessUserAnalyticsPageOperatorsAppellationsTab: {
        title: 'Acceso a apelaciones a través de una cuenta personal (para operadores)',
        description: ''
      },
      canAccessAppellationsAnalyticsPage: {
        title: 'Acceso a análisis de apelaciones',
        description: ''
      },
      canUpdateClientInteractionOperator: {
        title: 'Derecho a cambiar el operador responsable de la llamada',
        description: ''
      },
      canAiTranscribeClientInteractions: {
        title: 'El empleado tiene acceso a la funcionalidad de transcripción',
        description: ''
      },
      canAiAnalyzeClientInteractions: {
        title: 'El empleado tiene acceso a las secciones de AI',
        description: ''
      }
    },
    widgetsDescriptions: {
      checklistDefinitionAverageScore: {
        title: 'Evaluación media de la lista a chequear',
        description:
          'Este widget muestra la evaluación media general por una lista a chequear concreta, con la ayuda de la cual se realizó la revisión de las llamadas.'
      },
      phoneCallsCount: {
        title: 'Cantidad de llamadas',
        description: 'Este widget cuenta el número de llamadas.'
      },
      phoneCallsAverageDuration: {
        title: 'Duración media de la llamada',
        description:
          'Este widget muestra la duración media de todas las llamadas elegidas por usted. Se refleja en el formato mm:ss (minútos:segundos).'
      },
      totalPhoneCallsDuration: {
        title: 'Duración total de las llamadas',
        description:
          'Este widget muestra cla suma de la duración de las llamadas, o sea la duración total de todas las llamadas elegidas por usted. Se refleja en el formato hh:mm:ss (horas, minutos, segundos).'
      },
      reviewedClientInteractionsDuration: {
        title: 'Duración total de las llamadas',
        description:
          'Este widget refleja la suma de la duración de todas las llamadas evaluadas. El cáculo se basa en la fecha de la evaluación.'
      },
      reviewedClientInteractionsPercentage: {
        title: 'Porcentaje de evaluaciones',
        description:
          'Este widget refleja que porcentaje de llamadas fué evaluado de la totalidad de llamadas realizadas en el período elegido por usted.'
      },
      checklistDefinitionAverageScoreByUnits: {
        title: 'Comparación de la nota media por departamento',
        description:
          'Este widget en forma de histograma refleja la comparación de la evaluación media de una lista a chequear por departamentos.'
      },
      checklistDefinitionAverageScoreByOperators: {
        title: 'Comparación de la evaluación media por administrador',
        description:
          'Este widget en forma de histograma refleja la comparación de la evaluación media de una lista a chequear de los administradores elegidos.'
      },
      checklistDefinitionAverageScoreByChecklistDefinitions: {
        title: 'Comparación de la evaluación media por listas a chequear',
        description:
          'Este widget en forma de histograma refleja la comparación de la evalución media general en diferentes listas a chequear.'
      },
      phoneCallsCountByUnits: {
        title: 'Comparación de cantidad de llamadas por departamento',
        description:
          'Este widget en forma de histograma refleja la cantidad de llamadas en diferentes departamentos o equipos.'
      },
      phoneCallsCountByOperators: {
        title: 'Comparación de la cantidad de llamadas por administrador',
        description:
          'Este widget en forma de histograma compara la cantidad de llamadas de administradores diferentes (en un departamento en el compañía en general).'
      },
      reviewsCountByReviewers: {
        title: 'Comparación de la cantidad de evaluaciones por el supervisor',
        description:
          'Este widget en forma de histograma refleja cuantas evaluciones realizó cada supervisor de la compañía en el período elegido.'
      },
      ratingFlagsCount: {
        title: 'Número de banderas y comentarios',
        description:
          'Este widget en forma de diagráma circular refleja la proporción de la cantidad de comentarios con banderas o sin banderas que fueron creados en el período elegido, en la compañía en general o en el departamento/equipo elegido por usted, o inclusive para un administrador específico.'
      },
      ratingFlagsCountByUnits: {
        title: 'Comparación de la cantidad de bamderas por departamento',
        description:
          'Este widget en forma de diagráma circular refleja la proporción de la cantidad de comentarios con banderas o sin banderas que fueron creados en el departamento/equipo, en el tiempo elegido por usted.'
      },
      ratingFlagsCountByOperators: {
        title: 'Comparación de la cantidad de banderas por administrador',
        description:
          'Este widget en forma de histograma refleja la proporción de la cantidad de comentarios con banderas o ellas que fueron creados por cada administrador en el tiempo elegido por usted.'
      },
      labelsCountByOperators: {
        title: 'Comparación de la cantidad de etiquetas por admnistrador',
        description:
          'Este widget en forma de histograma refleja la proporción de la cantidad de etiquetas que se le añadió a las llamadas de cada administrador en el tiempo elegido.'
      },
      labelsCountByUnits: {
        title: 'Comparación de la cantidad de etiquetas por departamento',
        description:
          'Este widget en forma de histograma refleja la proporción de la cantidad de etiquetas que se le añadió a las llamadas de cada departamento/equipo en el tiempo elegido.'
      },
      checklistDefinitionQuestionsAverageScores: {
        title: 'Comparación de la evaluación media por pregunta de la lista a chequear',
        description:
          'Este widget en forma de histograma refleja la comparación de la evaluación media obtenida en cada pregunta (punto de evaluación) de la lista a chequear.'
      },
      checklistDefinitionQuestionsAverageScoresByQuestionGroups: {
        title: 'Comparacion de la evaluación media de las preguntas en el grupo',
        description:
          'Este widget en forma de histograma con bloques internos refleja la comparación de la evaluación media obtenida en cada pregunta (punto de evaluación) de cada grupo de la lista a chequear y la comparación de los grupos entre ellos.'
      },
      checklistDefinitionQuestionGroupsAverageScores: {
        title: 'Comparación de la evaluación media en los grupos de la lista a chequear',
        description:
          'Este widget en forma de histograma refleja la comparacón de la evaluación media obtenida en cada grupo de preguntas (puntos de evaluación) de la lista a chequear.'
      },
      phoneCallsAverageDurationByUnits: {
        title: 'Comparación de la duración media de las llamadas por departamento',
        description:
          'Este widget en forma de histograma compara la duración media de todas las llamadas en cada departamento. Se refleja en el formato mm:ss (minutos: segundos).'
      },
      phoneCallsAverageDurationByOperators: {
        title: 'Comparación de la duración media de las llamadas por administrador',
        description:
          'Este widget en forma de histograma compara la duración media de todas las llamadas de cada administrador Se refleja en el formato mm:ss (minutos: segundos).'
      },
      reviewsCountByReviewersHistory: {
        title: 'Dinámica de la cantidad de llamadas revisadas',
        description:
          'Este widget en forma de diagráma refleja cuantas evaluaciones realizó cada supervisor en el período de tiempo elegido.'
      },
      checklistDefinitionAverageScoreByOperatorsHistory: {
        title: 'Dinámica de la evaluación media por administrador',
        description:
          'Este widget en forma de diagrama refleja el cambio de la evaluación media de una lista a chequear de diferentes administradores en cada período de tiempo.'
      },
      checklistDefinitionAverageScoreByUnitsHistory: {
        title: 'Dinámica de la evaluación media por departamentos',
        description:
          'Este widget en forma de diagrama refleja el cambio de la evaluación media de una lista a chequear de diferentes departamentos o equipos en cada período de tiempo.'
      },
      averageScoreByQuestionsHistory: {
        title: 'Dinámica de la evaluación por preguntas de la lista a chequear',
        description:
          'Este widget en forma de diagrama refleja los cambios de la evaluación media por cada pregunta (punto de evaluación) en la lista a chequear en cada período de tiempo.'
      },
      averageScoreByQuestionGroupsHistory: {
        title: 'Dinámica de la evaluación por los grupos de preguntas de la lista a chequear',
        description:
          'Este widget en forma de diagrama refleja el cambio de la evaluación media por grupos de preguntas (puntos de evaluación) en la lista a chequear en cada período de tiempo.'
      },
      phoneCallsAverageDurationByOperatorsHistory: {
        title: 'Dinámica de la duración media de las llamadas por administrador',
        description:
          'Este widget en forma de gráfico refleja el cambio de la duración media de las llamadas de cada administrador en un período de tiempo determinado. El formato se refleja en la escala Y - mm:ss (minutos: segundos).'
      },
      phoneCallsAverageDurationByUnitsHistory: {
        title: 'Dinámica de la duración media de las llamadas por departamento',
        description:
          'Este widget en forma de gráfico refleja el cambio de la duración media de las llamadas de cada departamento o equipo en un período de tiempo determinado. El formato se refleja en la escala Y - mm:ss (minutos:segundos).'
      },
      clientInteractionsCountByOperatorsHistory: {
        title: 'Dinámica de la cantidad de llamadas por administrador',
        description:
          'Este widget en forma de diagrama refleja los casmbios de la cantidad de llamadas de cada administrador en cada período de tiempo.'
      },
      clientInteractionsCountByUnitsHistory: {
        title: 'Dinámica de la cantidad de llamadas por departamento',
        description:
          'Este widget en forma de diagrama refleja los casmbios de la cantidad de llamadas en diferentes departamentos o equipos en cada período de tiempo.'
      },
      tableReviewsCountByOperators: {
        title: 'Evaluación por administradores',
        description:
          'El widget refleja la evaluación media por admoinistrador en una fecha concreta, la evaluación total del mes y la cantidad de evaluaciones'
      },
      tableReviewsCountByOperatorsWithKpi: {
        title: 'KPI por operador',
        description:
          'Muestra el puntaje promedio por agente durante las últimas cinco semanas, además de una tabla adicional basada en la configuración de KPI'
      },
      tableChecklistItemsByOperators: {
        title: 'Resultados de empleados por criterios',
        description:
          'El widget muestra la puntuación media por criterios o grupos de criterios por operador'
      },
      tableChecklistItemsHistory: {
        title: 'Resultados de la prueba por criterios',
        description:
          'El widget muestra el puntaje promedio de una pregunta o un grupo de preguntas para una fecha específica, el puntaje total del mes y el número de verificaciones'
      },
      flagsAndComments: {
        title: 'Banderas y comentarios',
        description:
          'El widget muestra el número de banderas y comentarios de operadores, departamentos y empresas'
      },
      tableChecklistsScoresByOperators: {
        title: 'Resultados de las verificaciones de los formularios de evaluación',
        description:
          'El widget muestra los resultados de las inspecciones de empleados o departamentos para los formularios de evaluación seleccionados durante un período específico'
      },
      tableReviewsCountByReviewers: {
        title: 'Número de controles por auditores',
        description:
          'El widget muestra el número de comprobaciones realizadas por los revisores para los formularios de evaluación seleccionados durante un período específico'
      },
      tableReviewsWithScores: {
        title: 'Resultados de los empleados en forma de evaluación',
        description:
          'El widget muestra las verificaciones por empleado, junto con los resultados de las verificaciones por criterios y grupos de criterios en forma de evaluación.'
      }
    },
    reviewCallStatuses: {
      all: 'Todos',
      checked: 'Evaluados',
      unchecked: 'No evaluados'
    },
    callsDirections: {
      all: 'Todos',
      incoming: 'Entrantes',
      outcoming: 'Salientes'
    },
    weekendViewStatuses: {
      view: 'Reflejar',
      hide: 'No reflejar'
    },
    communicationDirections: {
      incoming: 'Entrante',
      outcoming: 'Salientes'
    },
    sessionsDirections: {
      pending: 'Próximo',
      done: 'Pasado'
    },
    reviewStatuses: {
      all: 'Todos',
      reviewed: 'Evaluados',
      notReviewed: 'No evaluados'
    },
    locales: {
      ru: 'Ruso',
      en: 'Inglés',
      es: 'Español',
      it: 'Italiano'
    },
    clientInteractionDirections: {
      all: 'Todos',
      incoming: 'Entrante',
      outcoming: 'Saliente',
      unknown: 'No instalado'
    },
    ratingMethods: {
      simple: 'Evaluación simple',
      weighted: 'Evaluación equilibrada'
    },
    ratingCalculations: {
      sum: 'Suma de puntuación',
      average: 'Evaluación media'
    },
    ratingModes: {
      numbers: 'Númerico',
      percentage: 'Porcentaje'
    },
    autofailEnabled: {
      true: 'Si',
      false: 'No'
    },
    clientInteractionTypes: {
      chat: 'Chats',
      ticket: 'Tickets',
      phone_call: 'Llamadas',
      email: 'Cartas',
      other: 'Otro'
    },
    flagsColors: {
      red: 'Bandera roja',
      green: 'Bandera verde',
      yellow: 'Bandera amarilla',
      withoutFlag: 'Sin bandera'
    },
    columnTypes: {
      communicationType: { title: 'Tipo' },
      clientPhoneNumber: { title: 'Número de teléfono' },
      startedAt: {
        title: 'Fecha y hora de la comunicación',
        columnName: 'Fecha y hora'
      },
      nps: { title: 'NPS' },
      direction: { title: 'Dirección' },
      text_conversation_response_time_in_seconds: { title: 'Tiempo de respuesta' },
      email: { title: 'Correo electrónico' },
      clientId: { title: 'ID clienta' },
      chatId: { title: 'Número del chat' },
      ticketId: { title: 'Número del ticket' },
      partsCount: {
        title: 'Cantidad de mensajes',
        columnName: 'Cantidad de mensajes.'
      },
      duration: {
        title: 'Duración',
        columnName: 'Duración'
      },
      text_conversation_ended_at: { title: 'Fecha y hora de cierre' },
      unitId: { title: 'Departamento' },
      operatorId: { title: 'Nombres y Apellidos' },
      isReviewed: { title: 'Estatus' },
      score: {
        title: 'Resultado de la evaluación',
        columnName: 'Evaluación'
      },
      checklistDefinitionName: { title: 'Lista a chequear' },
      reviewReviewerId: { title: 'Nombres y apellidos del evaluado' },
      reviewCreatedAt: { title: 'Fecha de evaluación' },
      reviewComments: { title: 'Banderas y comentarios' },
      taskTaskDefinitions: { title: 'Etiquetas' },
      reviewTasksCount: { title: 'Tareas' },
      taskPriority: { title: 'Prioridad de las tareas' },
      taskStatus: { title: 'Estado de las tareas' },
      taskAssigneeId: { title: 'Responsable de la tarea' },
      status: { title: 'Estatus del contacto' },
      crmEntityId: { title: 'Oferta/Boleto' }
    },
    boolValues: {
      true: 'Si',
      false: 'No'
    },
    colorPercantageZone: {
      emptyPercantage: 'No entra en la zona de evaluación',
      lowPercantage: 'Crítico',
      mediumPercantage: 'Normal',
      highPercantage: 'Exelente'
    },
    clientInteractionsTypes: {
      phoneCall: 'Llamada',
      email: 'Correo electrónico',
      chat: 'Sala de Chat',
      ticket: 'Ticket',
      video: 'Video',
      other: 'Otro'
    },
    symbolicTimeRanges: {
      all: 'Todo el tiempo',
      today: 'Hoy',
      yesterday: 'Ayer',
      lastSevenDays: 'Últimos 7 dias',
      thisWeek: 'Semana en curso',
      previousWeek: 'Semana pasada',
      lastThirtyDays: 'Últimos 30 dias',
      thisMonth: 'Mes en curso',
      previousMonth: 'Mes pasado',
      thisYear: 'Año en curso',
      previousYear: 'Año pasado'
    },
    symbolicMode: {
      clientInteraction: ', por fecha de informe',
      review: ', por fecha de evaluación'
    },
    notificationsScheduleTypes: {
      immediately: 'Inmediatamente',
      endOfDay: 'Al final del dia',
      endOfWeek: 'Al final de la semana',
      dayAbout: 'Dentro de un día'
    },
    notificationsTypes: {
      reviewCreated: 'Llamada evaluada',
      replyCommentCreated: 'Respuesta al comentario',
      taskCreated: 'Añadida la etiqueta automáticamente'
    },
    syncDataPeriods: {
      threeDays: 'Tres días',
      week: 'Una semana',
      twoWeeks: 'Dos semanas',
      month: 'Mes',
      threeMonths: 'Tres meses',
      sixMonths: 'Medio año',
      allTime: 'Todo el tiempo'
    },
    colorZonesText: {
      low:
        'El administrador hizo errores serios en este parámetro de evaluación, los cuales traerán perdidas de reputación o finanacieras a la compañía o será imposible devolver el interés del cliente',
      medium:
        'El administrador hizo errores leves en este parámetro de evaluación, los cuales no tienen gran influencia en la reputación de la compañía o en la desición del cliente de adquirir nuestro producto. En este parámetro de evaluación el administrador debería esforzarse más, aquí se puede ser mejor según lo descrito en los protocolos.',
      high:
        'El administrador se adelantó a las espectativas del cliente, hizo de tal manera, que el cliente no tuvo oportunidad de expresar alguna duda. Caso digno de como manejar la objeción/duda/negatividad'
    },
    scaleTypes: {
      max_2: {
        text: {
          yes: 'Si',
          no: 'No'
        },
        name: 'Escala de dos evaluaciones (0/1, Si/No)',
        valuesOptions: {
          emojis: '👍 y 👎 (👎 - 0, 👍 - 1)',
          text: 'Si y No (No-0, Si-1)',
          numbers: '0 y 1'
        }
      },
      max_3: {
        name: 'Escala de tres evaluaciones (1,2,3)',
        valuesOptions: { text: '1,2 y 3' }
      },
      max_4: { name: 'Escala de cuatro evaluaciones (1,2,3,4)' },
      max_5: { name: 'Escala de 5 evaluaciones (1,2,3,4,5,)' },
      max_10: {
        name: 'Escala de 10 evaluaciones (del 1 al 10)',
        valuesOptions: { numbers: 'del 1 al 10' }
      },
      custom: { name: 'Escala arbitraria' },
      custom_with_views: { name: 'Escala arbitraria (valor de texto)' },
      custom_binary: {
        name: 'Escala de 2 evaluaciones con cifras arbitrarias',
        text: {
          yes: 'Si',
          no: 'No'
        },
        valuesOptions: {
          emojis: '👍 y 👎',
          text: 'Si y No',
          numbers: 'Cifras arbitrarias'
        }
      }
    },
    checklistDefinitionStatuses: {
      draft: 'Borrador',
      archived: 'En el archivo',
      published: 'Publicado'
    },
    intercomMessengerStatuses: {
      show: 'Reflejar',
      hide: 'Ocultar'
    },
    widgetViewModes: {
      operators: 'Empleados',
      reviewers: 'Empleados',
      units: 'Departamentos',
      questions: 'Criterios',
      groups: 'Grupos',
      table: 'Tabla',
      chart: 'Calendario',
      score: 'Puntos',
      percantage: 'Porcentajes'
    },
    datesViewFormat: {
      day: 'Día',
      week: 'Una semana',
      month: 'Mes',
      year: 'Año'
    },
    errors: {
      unknownError: 'Algo salió mal, pero estamos trabajando en ello. Intenta recargar la página',
      loadingRecordError: {
        title: 'Error al acceder al registro',
        content:
          'Comuníquese con el soporte telefónico o el administrador del sistema con este enlace de registro',
        tooltip: {
          copy: 'Copiar',
          copied: '¡Copiado!'
        }
      }
    },
    defaultPageTitle:
      'Servicio de automatización de control de calidad basado en la nube para centros de contacto y departamentos de ventas | qolio.io',
    hiddenNamePlaceholder: 'Usuario desconocido',
    workPlanTaskConfigurationsStatuses: {
      draft: 'Borrador',
      pending: 'Pendiente',
      inProgress: 'Durante',
      completed: 'Terminado',
      stopped: 'Detenido'
    },
    periodRepeat: {
      day: 'A diario',
      week: 'Semanal',
      month: 'Mensual',
      never: 'No repitas'
    },
    timeUnit: {
      day: 'días',
      week: 'semanas',
      month: 'meses'
    },
    appealsStatuses: {
      pending: 'No procesado',
      inProgress: 'En el trabajo',
      needsInformation: 'Informacion requerida',
      accepted: 'Aceptado',
      rejected: 'Denegado',
      withoutAnswer: 'Sin respuesta'
    },
    appealPersonFilterValues: {
      assignedTo: 'Ejecutor',
      watcher: 'Observador',
      appellant: 'Apelado'
    }
  }
};
