import React from 'react';
import SRow from 'components/Standard/SRow';
import { Col, Typography } from 'antd';
import AiIcon from 'assets/images/ai-icon.svg';
import { useTranslation } from 'react-i18next';
import HeaderDatasetAi from './HeaderDatasetAi';
import DatasetAiTable from './DatasetAiTable';
import ApplicationModal from './ApplicationModal';

const DatasetAiPage = () => {
  const { t } = useTranslation();
  return (
    <SRow gutter={[0, 16, 16]} padding="16px">
      <ApplicationModal />
      <Col span={24}>
        <Typography.Title level={4}>
          {t('datasetAiPage.title')}
          <img src={AiIcon} alt="ai-icon" style={{ marginLeft: '5px', paddingBottom: '3px' }} />
        </Typography.Title>
      </Col>
      <HeaderDatasetAi />
      <DatasetAiTable />
    </SRow>
  );
};

export default DatasetAiPage;
