import { Col, Row, Tooltip, Typography } from 'antd';
import { INTEGRATIONS } from 'core/utils/constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { createAmoIntegration } from 'redux/entities/amoIntegration/operations';
import { createBinotelIntegration } from 'redux/entities/binotelIntegration/operations';
import { createBitrixIntegration } from 'redux/entities/bitrixIntegration/operations';
import { createSipuniIntegration } from 'redux/entities/sipuniIntegration/operations';
import { createUsedeskIntegration } from 'redux/entities/usedeskIntegration/operations';
import { createZendeskIntegration } from 'redux/entities/zendeskIntegration/operations';
import { createInfinityIntegration } from 'redux/entities/infinityIntegration/operations';
import { createHelpDeskEddyIntegration } from 'redux/entities/helpDeskEddyIntegration/operations';
import { createBrightPatternIntegration } from 'redux/entities/brightPatternIntegration/operations';
import { createCustomIntegration } from 'redux/entities/customIntegration/operations';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import Icon from 'components/Icon';
import { BookOpen } from 'react-feather';
import SText from 'components/Standard/SText';
import { getManualLinkInfo } from 'pages/UserPage/Integrations/utils';
import { createUisIntegration } from 'redux/entities/uisIntegration/operations';
import NameAndType from './IntegrationSettingsModules/NameAndType';
import {createNaumenIntegration} from "../../../redux/entities/naumenIntegration/operations";

const integrationTypeToHandler = {
  [INTEGRATIONS.amocrm.type]: createAmoIntegration,
  [INTEGRATIONS.bitrix_crm.type]: createBitrixIntegration,
  [INTEGRATIONS.custom_http_telephony.type]: createCustomIntegration,
  [INTEGRATIONS.custom_http_text.type]: createCustomIntegration,
  [INTEGRATIONS.sipuni.type]: createSipuniIntegration,
  [INTEGRATIONS.usedesk.type]: createUsedeskIntegration,
  [INTEGRATIONS.binotel.type]: createBinotelIntegration,
  [INTEGRATIONS.zendesk.type]: createZendeskIntegration,
  [INTEGRATIONS.help_desk_eddy.type]: createHelpDeskEddyIntegration,
  [INTEGRATIONS.infinity.type]: createInfinityIntegration,
  [INTEGRATIONS.bright_pattern.type]: createBrightPatternIntegration,
  [INTEGRATIONS.uis.type]: createUisIntegration,
  [INTEGRATIONS.naumen.type]: createNaumenIntegration
};

const { Title } = Typography;

const NewIntegrationPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const [integrationType, setIntegrationType] = useState();
  const organization_id = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );

  const onSetNameAndType = async values => {
    const integrationId = await dispatch(
      integrationTypeToHandler[values.integrationType]({ ...values, url, history, organization_id })
    );

    if (integrationId) {
      history.push(`${url}/${values.integrationType}/${integrationId}`);
    }
  };

  const { manualTextLink, manualLink } = getManualLinkInfo(integrationType);

  return (
    <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
      <Col span={24}>
        <SRow type="flex" wrap={false} justify="space-between">
          <SCol flex="auto">
            <Title level={4}>{t('integrationsSettingsPage.addIntegration.title')}</Title>
          </SCol>
          <SCol flex="none">
            <Tooltip title={manualTextLink}>
              <a target="_blank" href={manualLink} rel="noreferrer">
                <SRow>
                  <SCol display="flex" alignItems="center">
                    <Icon icon={BookOpen} marginTop="1px" marginRight="6px" size="20px" />
                    <SText ellipsis color="var(--link-color)">
                      {manualTextLink}
                    </SText>
                  </SCol>
                </SRow>
              </a>
            </Tooltip>
          </SCol>
        </SRow>
      </Col>
      <Col span={24}>
        <NameAndType
          onSubmit={onSetNameAndType}
          onChangeIntegrationType={type => setIntegrationType(type)}
        />
      </Col>
    </Row>
  );
};

export default NewIntegrationPage;
