import { utils } from 'react-media-player';
import i18n from 'i18next';
import moment from 'moment';
import {
  get,
  reduce,
  upperFirst,
  toLower,
  values,
  countBy,
  compact,
  sum,
  merge,
  snakeCase,
  isEmpty
} from 'lodash';
import { beatifyFloat } from 'core/utils/convertFloat';
import {
  CALL_DIRECTION_LITERALS,
  CLIENT_INTERACTIONS_TYPES_LITERALS,
  COLUMN_TYPES,
  COMMUNICATION_TYPES,
  WIDGET_FLAGS_COLORS_RELATIONS
} from 'core/utils/constants';
import { renderCustomField } from 'pages/ClientInteractionsPage/components/ColumnsRender';

const REVIEW_COMMENTS_COLUMNS = [
  {
    title: i18n.t('teamPage.exportTable.columns.redComments'),
    key: 'reviewComments.red',
    dataIndex: 'reviewComments.red'
  },
  {
    title: i18n.t('teamPage.exportTable.columns.yellowComments'),
    key: 'reviewComments.yellow',
    dataIndex: 'reviewComments.yellow'
  },
  {
    title: i18n.t('teamPage.exportTable.columns.greenComments'),
    key: 'reviewComments.green',
    dataIndex: 'reviewComments.green'
  },
  {
    title: i18n.t('teamPage.exportTable.columns.countCommentsWithoutFlag'),
    key: 'reviewComments.withoutFlag',
    dataIndex: 'reviewComments.withoutFlag'
  },
  {
    title: i18n.t('components.commentsPreview.previewIcons.generalComment'),
    key: 'reviewComments.general',
    dataIndex: 'reviewComments.general'
  },
  {
    title: 'Всего комментариев',
    key: 'reviewComments.total',
    dataIndex: 'reviewComments.total'
  }
];

const CHILDREN_COLUMNS = [
  'score', // “Оценка”
  'reviewComments.red', // “Красные комментарии”
  'reviewComments.yellow', // “Желтые комментарии”
  'reviewComments.green', // “Зеленые комментарии”
  'reviewComments.withoutFlag', // “Комментарии без флага”
  'reviewComments.general', // “Итоговый комментарий”
  'reviewComments.total', // “Всего комментариев”
  'taskTaskDefinitions', // “Теги”
  'reviewReviewerId', // “ФИО оценившего”
  'reviewCreatedAt', // “Дата оценки”
  'unitId', // “Отдел”
  'checklistDefinitionName' // “Форма оценки”
];

export const prepareColumns = ({ tableColumns, customFieldsByKeys }) =>
  reduce(
    tableColumns,
    (acc, columnType) => {
      const columnTitle = get(
        COLUMN_TYPES,
        `${columnType}.name`,
        customFieldsByKeys[columnType]?.name || ''
      );

      return [
        ...acc,
        ...(columnType === COLUMN_TYPES.reviewComments.value
          ? REVIEW_COMMENTS_COLUMNS
          : [
              {
                title: i18n.t(get(COLUMN_TYPES, `${columnType}.columnName`, columnTitle)),
                dataIndex: columnType
              }
            ])
      ];
    },
    []
  );

const columnValue = ({
  customFieldsByKeys,
  col,
  text,
  record,
  crmEntitiesByIds,
  clientInteractionsByIds,
  statusesByIds,
  getUserNameForExport
}) => {
  const wrapValue = false;
  const renderColumnData = {
    communicationType: (type, { communicationType, clientInteractionType }) => {
      const literals = {
        ...CLIENT_INTERACTIONS_TYPES_LITERALS,
        custom_communication: CLIENT_INTERACTIONS_TYPES_LITERALS.other
      };

      const tmpType = communicationType || clientInteractionType || COMMUNICATION_TYPES.PHONE_CALL;

      return literals?.[tmpType] ? i18n.t(literals?.[tmpType]) : tmpType;
    },
    startedAt: data => (data ? moment(data).format('DD/MM/YYYY HH:mm') : ''),
    duration: data => (data ? utils.formatTime(data) : ''),
    direction: data => i18n.t(CALL_DIRECTION_LITERALS[data]),
    operatorId: (data, record) => {
      const userId = data || record?.reviewOperatorId;
      return userId ? getUserNameForExport({ userId }) : null;
    },
    isReviewed: (data, record) =>
      record.clientInteractionType &&
      (record.reviewId
        ? i18n.t('constants.reviewStatuses.reviewed')
        : i18n.t('constants.reviewStatuses.notReviewed')),
    reviewsCount: (data, record) => record?.reviews?.length,
    clientId: (data, record) => record?.client?.integrationUid || null,
    unitId: (data, record) => record?.unit?.name || null,
    nps: data => data,
    email: (data, record) => record?.email,
    clientPhoneNumber: data => data,
    partsCount: (data, record) =>
      record.textCommunicationPartsCount || record.textCommunicationPartsIds?.length || '',
    score: (data, record) => beatifyFloat(record?.reviewChecklist?.score ?? data)?.toString(),
    chatId: (data, record) => record.communicationId || null,
    ticketId: (data, record) => record.communicationId || null,
    reviewReviewerId: data => {
      return data ? getUserNameForExport({ userId: data }) : null;
    },
    reviewCreatedAt: data => (data ? moment(data).format('DD/MM/YYYY HH:mm') : null),
    checklistDefinitionName: (data, record) => record?.reviewChecklistDefinition?.name || null,
    reviewComments: (data, record) => {
      const groupedComment = countBy(data, 'metadata.ratingFlag');
      const newData = reduce(
        groupedComment,
        (acc, value, key) => ({
          ...acc,
          [key === 'null' ? 'withoutFlag' : key]: value
        }),
        {}
      );

      const general = !isEmpty(record?.reviewChecklist?.comment) ? 1 : null;
      const total = sum(compact([...values(newData), general]));

      const commentData = merge(
        {
          red: null,
          green: null,
          yellow: null,
          withoutFlag: null,
          general: null,
          total: null
        },
        {
          ...newData,
          general,
          total: total > 0 ? total : null
        }
      );

      return reduce(
        commentData,
        (acc, value, key) => ({
          ...acc,
          [key]: {
            value,
            color: ['red', 'green', 'yellow'].includes(key)
              ? WIDGET_FLAGS_COLORS_RELATIONS?.[snakeCase(key)]
              : null
          }
        }),
        {}
      );
    },
    reviewTasksCount: (data, record) => record?.tasks?.length,
    taskTaskDefinitions: (data, record) =>
      (record?.tasks || [])
        .map(({ taskDefinition }) => upperFirst(toLower(taskDefinition.labelName)))
        .join(', '),
    status: (data, record) => {
      if (record?.type === 'client-interactions' || record?.type === 'client_interactions') {
        const clientInteraction = clientInteractionsByIds[record.id];

        const status = statusesByIds[clientInteraction?.statusId];
        return status?.name;
      }

      return null;
    },
    crmEntityId: (data, record) => {
      const crmEntity = get(crmEntitiesByIds, record.crmEntityId, {});
      return crmEntity?.id;
    },
    ...reduce(
      customFieldsByKeys,
      (acc, curr, key) => ({
        ...acc,
        [key]: renderCustomField({ key, customFieldsByKeys, wrapValue })
      }),
      {}
    )
  };

  return renderColumnData?.[col] && renderColumnData[col](text, record, wrapValue)
    ? renderColumnData[col](text, record, wrapValue)
    : null;
};

const prepareRowData = ({
  item,
  customFieldsByKeys,
  crmEntitiesByIds,
  tableColumns,
  clientInteractionsByIds,
  statusesByIds,
  getUserNameForExport
}) => [
  {
    ...reduce(
      tableColumns,
      (acc, col) => ({
        ...acc,
        [col]: columnValue({
          customFieldsByKeys,
          col,
          record: item,
          crmEntitiesByIds,
          text: item?.[col],
          clientInteractionsByIds,
          statusesByIds,
          getUserNameForExport
        })
      }),
      {}
    )
  },
  ...reduce(
    item?.children || [],
    (acc, childrenItem) => [
      ...acc,
      ...prepareRowData({
        item: childrenItem,
        customFieldsByKeys,
        crmEntitiesByIds,
        tableColumns: CHILDREN_COLUMNS,
        clientInteractionsByIds,
        statusesByIds,
        getUserNameForExport
      })
    ],
    []
  )
];

export const prepareRows = ({
  tableRows,
  customFieldsByKeys,
  crmEntitiesByIds,
  tableColumns,
  clientInteractionsByIds,
  statusesByIds,
  getUserNameForExport
}) =>
  reduce(
    tableRows,
    (acc, item) => [
      ...acc,
      ...prepareRowData({
        item,
        customFieldsByKeys,
        crmEntitiesByIds,
        tableColumns,
        clientInteractionsByIds,
        statusesByIds,
        getUserNameForExport
      })
    ],
    []
  );
