import { Alert, Button, Modal, Typography } from 'antd';
import { OutlinedButton } from 'components/Buttons';
import { RATING_METHOD, SCALE_TYPES } from 'core/utils/constants';
import { basePosition } from 'core/utils/dnd';
import { get, isEmpty, last, sortBy, sumBy } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  getChecklistDefinitionBindings,
  getQuestionGroupBindings
} from 'redux/selectors/checklistItems/checklistItems';
import { setAddingQuestionToGroup } from 'redux/ui/checklistEditor/reducer';
import { setEditingQuestionWithBinding } from 'redux/ui/questionModal/reducer';
import uniqid from 'uniqid';
import { setSelectingQuestionsFromSaved } from '../../../redux/ui/checklistEditor/reducer';

const AddQuestionModal = ({
  currentChecklist,
  addingQuestionToGroup,
  setSelectingQuestionsFromSaved,
  setAddingQuestionToGroup,
  setEditingQuestionWithBinding,
  questionGroupBindings = [],
  sumPercentage,
  ratingMethod
}) => {
  const onCancel = () => setAddingQuestionToGroup({});
  const { Text } = Typography;
  const { t } = useTranslation();

  const onNewQuestion = () => {
    const { questionGroup } = addingQuestionToGroup;
    const { scaleType } = currentChecklist;
    const questionScaleType =
      scaleType === SCALE_TYPES.custom.type ? SCALE_TYPES.max_5.type : scaleType;

    const question = {
      id: uniqid(),
      scaleType: questionScaleType,
      colorZones: SCALE_TYPES[questionScaleType].colorZones,
      ratingValues: SCALE_TYPES[questionScaleType].numbers || [0, 1]
    };

    const questionToGroupBinding = {
      id: uniqid(),
      position: get(last(questionGroupBindings), 'position', 0) + basePosition,
      percentage: 1,
      questionId: question.id,
      questionGroupId: questionGroup.id
    };

    setAddingQuestionToGroup({});
    setEditingQuestionWithBinding({ question, questionToGroupBinding });
  };

  const onLibraryQuestion = () => {
    setSelectingQuestionsFromSaved(true);
  };

  const visible = !isEmpty(addingQuestionToGroup);

  const isDisabledByWeight = sumPercentage === 100 && ratingMethod === RATING_METHOD.WEIGHTED;

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={t('checklistsPage.addQuestionModal.title')}
      footer={
        <>
          <OutlinedButton onClick={onLibraryQuestion} disabled={isDisabledByWeight}>
            {t('checklistsPage.addQuestionModal.buttons.libraryQuestion')}
          </OutlinedButton>

          <Button type="primary" onClick={onNewQuestion} disabled={isDisabledByWeight}>
            {t('checklistsPage.addQuestionModal.buttons.newQuestion')}
          </Button>
        </>
      }
    >
      <Alert
        type="info"
        closable={false}
        description={
          <>
            {isDisabledByWeight ? (
              <Text strong>{t('checklistsPage.addQuestionModal.messages.disabledByWeight')}</Text>
            ) : (
              <Text>{t('checklistsPage.addQuestionModal.messages.info')}</Text>
            )}
          </>
        }
      />
    </Modal>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { currentChecklist } = ownProps;
  const { ratingMethod } = currentChecklist;
  const currentChecklistBindings = getChecklistDefinitionBindings(state, currentChecklist);
  const sumPercentage = sumBy(currentChecklistBindings, 'percentage');

  return {
    addingQuestionToGroup: state.uiChecklistEditor.addingQuestionToGroup,
    questionGroupBindings: sortBy(
      getQuestionGroupBindings(
        state,
        get(state.uiChecklistEditor.addingQuestionToGroup, 'questionGroup', {})
      ),
      'position'
    ),
    sumPercentage,
    ratingMethod
  };
};

const mapDispatchToProps = {
  setAddingQuestionToGroup,
  setEditingQuestionWithBinding,
  setSelectingQuestionsFromSaved
};

export default connect(mapStateToProps, mapDispatchToProps)(AddQuestionModal);
