import { Typography } from 'antd';
import { BOOL_VALUES, CUSTOM_FIELDS_TYPES } from 'core/utils/constants';
import { find, get, isEmpty, isUndefined, isArray } from 'lodash';
import moment from 'moment';
import React from 'react';
import i18n from 'i18next';

const { Text, Link } = Typography;

const getCustomFieldSelectValue = ({ customFields = {}, customFieldsByKeys, customFieldKey }) => {
  // FIXME: remove toString conversion after Qiwi fix their keys
  const getText = key =>
    get(find(customFieldsByKeys[customFieldKey]?.settings, { key: key + '' }), 'text', '');

  if (Array.isArray(customFields[customFieldKey])) {
    return customFields[customFieldKey]
      .map(element => {
        if (Array.isArray(element)) {
          return element.map(getText).join(', ');
        }
      })
      .join(' / ');
  }

  return getText(customFields[customFieldKey]);
};

const displayDuration = fieldValue => {
  if (isUndefined(fieldValue)) return '';
  if (fieldValue === 0 || fieldValue === '0') return '00:00:00';
  return moment.duration(fieldValue, 'seconds').format('hh:mm:ss');
};

const renderBool = value => {
  if (value === 'true' || value === true) {
    return i18n.t(BOOL_VALUES.TRUE.title);
  }
  if (value === 'false' || value === false) {
    return i18n.t(BOOL_VALUES.FALSE.title);
  }
  return '-';
};

const CustomFieldValueView = ({
  customFields,
  customFieldsByKeys,
  customFieldKey,
  value,
  wrapValue = true
}) => {
  const fieldType = customFieldsByKeys?.[customFieldKey]?.fieldType;
  const fieldValue = value || get(customFields, customFieldKey, []);

  // TODO: реализовать логику отображения полей с типом datetime с учетом timezone сервера, или значения которое записано в строке с датой
  // const displayDateTimeWithTimeZone = fieldValue => {
  //   if (isEmpty(fieldValue)) return '';
  //   return moment(fieldValue)
  //     .utc(0)
  //     .format('DD/MM/YYYY, HH:mm');
  // };

  switch (fieldType) {
    case CUSTOM_FIELDS_TYPES.DATETIME:
      if (wrapValue)
        return (
          !isEmpty(fieldValue) && <Text>{moment(fieldValue).format('DD/MM/YYYY, HH:mm')}</Text>
        );
      return !isEmpty(fieldValue) && moment(fieldValue).format('DD/MM/YYYY HH:mm');

    case CUSTOM_FIELDS_TYPES.STRING_ARRAY:
      if (wrapValue && isArray(fieldValue)) return <Text>{fieldValue.join(', ')}</Text>;
      if (isArray(fieldValue)) {
        return fieldValue.join(', ');
      }
      return fieldValue;

    case CUSTOM_FIELDS_TYPES.STRING_URL:
      if (wrapValue)
        return (
          <Link href={fieldValue} target="_blank">
            {fieldValue}
          </Link>
        );
      return fieldValue;

    case CUSTOM_FIELDS_TYPES.NUMBER_ARRAY:
      if (wrapValue && isArray(fieldValue)) return <Text>{fieldValue.join(', ')}</Text>;
      if (isArray(fieldValue)) {
        return fieldValue.join(', ');
      }
      return fieldValue;

    case CUSTOM_FIELDS_TYPES.BOOLEAN:
      if (wrapValue) return <Text>{renderBool(fieldValue)}</Text>;
      return renderBool(fieldValue);

    case CUSTOM_FIELDS_TYPES.DURATION:
      if (wrapValue) return <Text>{displayDuration(fieldValue)}</Text>;
      return displayDuration(fieldValue);

    case CUSTOM_FIELDS_TYPES.SELECT:
      if (wrapValue)
        return (
          <Text>
            {getCustomFieldSelectValue({
              customFields,
              customFieldsByKeys,
              customFieldKey
            })}
          </Text>
        );
      return getCustomFieldSelectValue({
        customFields,
        customFieldsByKeys,
        customFieldKey
      });

    default:
      if (wrapValue) return <Text>{fieldValue}</Text>;
      return isArray(fieldValue) ? fieldValue.join(', ') : fieldValue;
  }
};

export default CustomFieldValueView;
