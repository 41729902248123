import { message, Skeleton } from 'antd';
import AddToCalibrationModal from 'components/AddToCalibrationModal';
import ChecklistManager from 'components/Checklist/ChecklistManager';
import ChecklistManagerHead from 'components/Checklist/ChecklistManager/ChecklistManagerHead';
import CustomAlert from 'components/CustomAlert';
import {
  CHECKLIST_MANAGER_STATES,
  CLIENT_INTERACTIONS_TYPES_LITERALS,
  PERMISSIONS
} from 'core/utils/constants';
import { every, get, isEmpty, isEqual, isNil, pick } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import useEvent from '@react-hook/event';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { commentsResource } from 'redux/resources/comments';
import { getTranscriptionWithParts } from 'redux/selectors/phoneCallTranscriptions';
import { getCurrentUser } from 'redux/selectors/users';
import {
  setChecklistManagerState,
  setInitialState as setInitialChecklistManagerState
} from 'redux/ui/checklistManager/reducer';
import { setCommunication, visitInteraction } from 'redux/ui/clientInteractionDrawer/reducer';
import { submitReview } from 'redux/ui/clientInteractionPage/operations';
import {
  addComment,
  deleteComment,
  setAddingComment,
  setInitialState,
  updateComment
} from 'redux/ui/clientInteractionPage/reducer';
import { updatePlayerState } from 'redux/ui/recordPlayer/reducer';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SCard from 'components/Standard/SCard';
import ChecklistManagerFooter from 'components/Checklist/ChecklistManager/ChecklistManagerFooter';

const defaultInteraction = {};

const ClientInteractionPage = ({
  Content,
  clientInteraction = defaultInteraction,
  id: propsId,
  loading,
  fromDrawer = false,
  offsetTop = fromDrawer ? 8 : 64,
  affixTarget,
  shouldLoad = true
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: paramsId } = useParams();
  const history = useHistory();
  const id = propsId || paramsId;

  const ref = React.useRef();
  const refBody = React.useRef();
  const [scrollbarWidth, setScrollbarWidth] = useState(null);

  const currentUser = useSelector(getCurrentUser, isEqual);
  const review = useSelector(state => state.reviewsResource.byIds[id]);

  const allowCommenting = useSelector(state => {
    const user = getCurrentUser(state);
    const isPermissionInclude =
      get(user, 'role.permissions', []).includes(PERMISSIONS.CAN_MAKE_REVIEW) ||
      get(user, 'role.permissions', []).includes(PERMISSIONS.CAN_REPLY_COMMENTS);

    return isPermissionInclude && review ? review?.reviewerId === user.id : isPermissionInclude;
  });

  const comments = useSelector(state => state.uiClientInteractionPage.commentsByIds, isEqual);
  const isEmptyQuestionsValues = useSelector(state =>
    every(state.uiChecklistManager.questionIdToAnswerValue, isNil)
  );
  const isEmptyQuestionsComments = isEmpty(
    Object.values(comments).filter(comment => get(comment, 'metadata.questionId', undefined))
  );

  const activeOperator = useSelector(
    state => get(state.usersResource.byIds, clientInteraction?.operatorId),
    (next, prev) => isEqual(next?.id, prev?.id) && !next?.loading
  );
  const currentChecklist = useSelector(state => state.uiChecklistManager.currentChecklist);
  const addingCommentsToQuestionId = useSelector(
    state => state.uiChecklistManager.addingCommentsToQuestionId
  );
  const checklistManagerState = useSelector(
    state => state.uiChecklistManager.checklistManagerState
  );
  const loadingComments = useSelector(state => state.commentsResource.loading);
  const addingComment = useSelector(state => state.uiClientInteractionPage.isAddingComment);

  const { playedSeconds } = useSelector(
    state => pick(state.uiRecordPlayer, ['playedSeconds']),
    isEqual
  );

  const transcription = useSelector(
    state => getTranscriptionWithParts(state, clientInteraction?.phoneCallTranscriptionId),
    isEqual
  );

  useEffect(() => {
    dispatch(visitInteraction(id));
    return () => {
      dispatch(setInitialState());
      dispatch(setInitialChecklistManagerState());
    };
  }, []);

  useEffect(() => {
    const resizeOb = new ResizeObserver(entries => {
      // since we are observing only a single element, so we access the first element in entries array
      const el = entries?.[0]?.target;
      if (el && scrollbarWidth === null) {
        // current width & height
        const { offsetWidth, clientWidth } = el;
        const newScrollbarWidth = (offsetWidth || 0) - (clientWidth || 0);
        setScrollbarWidth(newScrollbarWidth);
      }
    });

    ref.current && resizeOb.observe(ref.current);

    return () => {
      ref.current && resizeOb.unobserve(ref.current);
    };
  }, [clientInteraction]);

  useEffect(() => {
    const resizeOb = new ResizeObserver(entries => {
      // since we are observing only a single element, so we access the first element in entries array
      const el = entries?.[0]?.target;
      if (el && scrollbarWidth === null) {
        // current width & height
        const { offsetWidth, clientWidth } = el;
        const newScrollbarWidth = (offsetWidth || 0) - (clientWidth || 0);
        setScrollbarWidth(newScrollbarWidth);
      }
    });

    refBody.current && resizeOb.observe(refBody.current);

    return () => {
      refBody.current && resizeOb.unobserve(refBody.current);
    };
  }, [clientInteraction]);

  const goToReview = useCallback(
    async reviewId => {
      await dispatch(updatePlayerState({ wasPlayed: false, isPlaying: false }));
      fromDrawer
        ? dispatch(setCommunication({ type: 'review', id: reviewId }))
        : history.push(`/reviews/${reviewId}?t=${playedSeconds}`);
    },
    [dispatch]
  );

  const onChecklistSave = useCallback(async () => {
    if (isEmpty(comments) && isEmptyQuestionsValues) {
      message.warning(t('clientInteractionPage.messages.emptyQuestionsComments'));
      return;
    }

    if (isEmptyQuestionsValues && !isEmptyQuestionsComments) {
      message.warning(t('clientInteractionPage.messages.emptyQuestionsValues'));
      return;
    }

    const reviewId = await dispatch(submitReview({ id, review, fromDrawer }));

    if (!reviewId) return;

    const clientInteractionType =
      get(clientInteraction, 'type') === 'text-communications'
        ? get(clientInteraction, 'communicationType')
        : get(clientInteraction, 'type');

    if (reviewId !== id) {
      return goToReview(reviewId);
    }

    dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.SAVED));
  }, [dispatch, id, review, comments, isEmptyQuestionsValues, isEmptyQuestionsComments]);

  const onCommentSave = useCallback(
    comment => {
      let id = localStorage.getItem('commId');
      localStorage.removeItem('commId');
      let comm = { ...comment };
      if (!!id) {
        comm.metadata.questionId = id; 
      }
      dispatch(addComment(comm));
      if (isEmpty(comm?.metadata?.questionId)) dispatch(setAddingComment(false));
    },
    [dispatch]
  );

  const onAddCommentButton = useCallback(() => {
    dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.EDITING));
    dispatch(setAddingComment(true));
  }, [dispatch]);

  useEvent(document, 'keydown', e => {
    const drawer = document.getElementsByClassName('ant-drawer')[0];
    const isEditorKeyPress = e => {
      return (
        e.target.classList.contains('public-DraftEditor-content') ||
        e.target.classList.contains('ant-input') ||
        e.target.classList.contains('ant-select-selection-search-input')
      );
    };

    if (e.code === 'Space' && e.shiftKey && !isEditorKeyPress(e) && !addingCommentsToQuestionId) {
      if (!review || (review && allowCommenting)) {
        if (review) dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.EDITING));
        if (
          clientInteraction.type === 'phone-calls' ||
          clientInteraction.type === 'client-interactions'
        ) {
          dispatch(setAddingComment(true));
          document.getElementsByClassName('public-DraftEditor-content')[0].focus();
        }
      }
    }

    if (e.code === 'Escape' && addingComment) {
      dispatch(setAddingComment(false));
      if (drawer) drawer.focus();
    }
  });

  const onCancelComment = useCallback(() => {
    dispatch(setAddingComment(false));
  }, [dispatch]);

  const onDeleteComment = useCallback(
    ({ id, commentType }) => {
      if (
        checklistManagerState !== CHECKLIST_MANAGER_STATES.EDITING &&
        commentType === 'review_comment'
      ) {
        return message.warn(t('clientInteractionPage.messages.needToChangeEditMode'));
      }

      if (Object.values(comments).length < 2 && isEmpty(currentChecklist)) {
        dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.INITIAL));
        dispatch(setAddingComment(false));
      }

      dispatch(deleteComment({ id }));
    },
    [dispatch, comments, checklistManagerState]
  );

  const onUpdateComment = useCallback(
    async comment => {
      let newComment = comment;
      if (checklistManagerState === CHECKLIST_MANAGER_STATES.SAVED) {
        try {
          newComment = await dispatch(commentsResource.operations.updateById(comment));
          message.success(t('clientInteractionPage.messages.commentSuccessfullyUpdated'));
        } catch (error) {
          message.error(t('clientInteractionPage.messages.updateCommentFailed'));
        }
      } else {
        dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.EDITING));
      }

      dispatch(updateComment(newComment));
    },
    [dispatch, checklistManagerState]
  );

  if (loading) {
    return (
      <SCard>
        <Skeleton active />
      </SCard>
    );
  }

  return (
    <SRow
      display="flex"
      minHeight={`calc(100vh - ${fromDrawer ? '0px' : '48px'})`}
      justifyContent="space-between"
      wrap={false}
    >
      <SCol flex="auto" overflowY="scroll" height={`calc(100vh - ${fromDrawer ? '0px' : '48px'})`}>
        <SRow
          ref={refBody}
          margin={fromDrawer ? '0' : ''}
          padding={`16px ${16 - scrollbarWidth || 0}px 16px 16px`}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          {!activeOperator && (
            <SCol
              span={24}
              flex="none"
              style={{
                position: 'sticky',
                top: '14px',
                zIndex: 2
              }}
              marginBottom="16px"
              minWidth="50%"
            >
              <CustomAlert
                type="warning"
                message={t('clientInteractionPage.alerts.disabledUser.title')}
                description={
                  <p>
                    {t('clientInteractionPage.alerts.disabledUser.description')}
                    <Link to={`/user/${currentUser?.id}/organization-settings/employees/`}>
                      {t('clientInteractionPage.alerts.disabledUser.button')}
                    </Link>
                  </p>
                }
              />
            </SCol>
          )}
          <SCol span={24} flex="auto">
            {Content ? (
              <Content
                affixOffsetTop={offsetTop}
                affixTarget={affixTarget}
                comments={comments}
                allowCommenting={allowCommenting}
                loadingComments={loadingComments}
                addingComment={addingComment}
                checklistManagerState={checklistManagerState}
                onAddCommentButton={onAddCommentButton}
                onCommentSave={onCommentSave}
                onCancelComment={onCancelComment}
                onDeleteComment={onDeleteComment}
                onUpdateComment={onUpdateComment}
                clientInteraction={clientInteraction}
                review={review}
                shouldLoad={shouldLoad}
                transcription={transcription}
                fromDrawer={fromDrawer}
              />
            ) : (
              <SCard>
                <Skeleton active />
              </SCard>
            )}
            <AddToCalibrationModal clientInteraction={clientInteraction} />
          </SCol>
        </SRow>
      </SCol>
      <SCol
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        style={{
          background: '#FFFFFF',
          border: '1px solid #DFE1E8',
          boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.08)',
          minWidth: '560px'
        }}
        width="min(50%, 56px)"
        height={`calc(100vh - ${fromDrawer ? '0px' : '48px'})`}
      >
        <SCol flex="none" span={24} padding="16px">
          <ChecklistManagerHead fromDrawer={fromDrawer} communication={clientInteraction} id={id} />
        </SCol>
        <SCol
          ref={ref}
          flex="auto"
          padding={`0 ${16 - scrollbarWidth || 0}px 0px 16px`}
          style={{ overflowY: 'scroll' }}
          overflowX="hidden"
        >
          <ChecklistManager
            onSubmit={onChecklistSave}
            onCommentSave={onCommentSave}
            onCancelComment={onCancelComment}
            onDeleteComment={onDeleteComment}
            onUpdateComment={onUpdateComment}
            onAddCommentButton={onAddCommentButton}
            allowCommenting={allowCommenting}
            comments={comments}
            communication={clientInteraction}
            reviewId={id}
          />
        </SCol>
        <SCol flex="none" width="100%" span={24}>
          <ChecklistManagerFooter reviewId={review?.id} onSubmit={onChecklistSave} />
        </SCol>
      </SCol>
    </SRow>
  );
};

export default React.memo(ClientInteractionPage, isEqual);
