import React, { useCallback, useState } from 'react';
import { Button, Checkbox, Form, message, Switch } from 'antd';
// import { datasetsResource } from 'redux/resources/dataset';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InboxOutlined } from '@ant-design/icons';
import { actions, operations as datasetsOperations } from 'redux/lists/datasetList';
import axios from 'axios';
import { createDatasetEndpoint } from 'core/api';
import { SDragger, SInput } from './styled';
import SModal from '../../components/Standard/SModal';

const fileStatuses = ['done', 'error', 'removed', 'uploading'];
const { onDatasetCreate } = actions;
const loadDatasets = datasetsOperations.load;

const ModalTask = ({ isModalOpen, setIsModalOpen }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);
  const [disabledName, setDisabledName] = useState(true);
  const [disabledFile, setDisabledFile] = useState(false);
  const disabled = disabledName || disabledFile;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const currentUserId = useSelector(state => state.reduxTokenAuth.currentUser.attributes.id);
  const { createStarted } = useSelector(state => state.datasetsResource);
  const { loading: datasetsResourceLoading } = useSelector(state => state.datasetsResource);
  const { loading: datasetsLoading } = useSelector(state => state.datasetsList);
  const loading = datasetsResourceLoading || datasetsLoading || createStarted || fileLoading;

  const closeModal = useCallback(() => {
    setFileLoading(false);
    form.resetFields();
    setFileList([]);
    setIsModalOpen(false);
  }, [dispatch]);

  const handleSubmit = async data => {
    const { name, isActive, file } = data;
    const { file: currentFile } = file;
    const formData = new FormData();

    if (fileList.length === 0) {
      form.setFieldsValue({ file: null });
      message.info('Пожалуйста, загрузите файл');
      return;
    }
    if (currentFile.status === 'error') {
      message.error(`${currentFile.name} ${t('components.datasetsModal.messages.errorLoad')}`);
      return;
    }

    formData.append('name', name);
    formData.append('organization_id', organizationId);
    formData.append('user_id', currentUserId);
    formData.append('is_active', true);
    // formData.append('is_active', isActive);
    formData.append('file', currentFile.originFileObj);
    // formData.append('file', currentFile.originFileObj);
    const headers = {
      'content-type': 'multipart/form-data'
    };
    try {
      setFileLoading(true);
      const newDataset = await axios.post(createDatasetEndpoint, formData, { headers });
      const createdId = newDataset.data.data.id;
      await dispatch(
        onDatasetCreate({
          id: createdId
        })
      );
      await dispatch(
        loadDatasets({
          organization_id: organizationId
        })
      );
      message.success(
        `${t('components.datasetsModal.title')} '${name}' ${t(
          'components.datasetsModal.messages.successCreate'
        )}`
      );
      closeModal();
    } catch (error) {
      setFileLoading(false);
      console.log('error', error);
      message.error(t('errorPages.internalServerError.title'));
    }
  };
  // const customRequest = async options => {
  //   const { onSuccess, onError, file, onProgress } = options;
  //
  //   const formData = new FormData();
  //   const config = {
  //     headers: { 'content-type': 'multipart/form-data' },
  //     onUploadProgress: ({ total, loaded }) => {
  //       const percent = Math.round((loaded * 100) / total);
  //       onProgress({ percent });
  //     }
  //   };
  //   formData.append('dataset', file);
  //
  //   try {
  //     const response = await axios.post('https://jsonplaceholder.typicode.com/posts/', formData, config);
  //     onSuccess('Ok');
  //     console.log('Сервер ответ: ', response);
  //   } catch (error) {
  //     console.log('Error: ', error);
  //     onError({ error });
  //   }
  // };

  const initialValues = {
    name: '',
    // isActive: true,
    file: ''
  };

  const onChangeName = item => {
    if (item.length === 0) {
      setDisabledName(true);
    } else {
      setDisabledName(false);
    }
  };

  const handleUpload = info => {
    const { file } = info;
    const { status, name } = file;

    if (!fileStatuses.includes(status)) return;

    setFileLoading(true);

    switch (status) {
      case 'done':
        setFileLoading(false);
        // setDisabledFile(false);
        message.success(`${name} ${t('components.datasetsModal.messages.successLoad')}`);
        break;
      case 'error':
        setDisabledFile(true);
        setFileLoading(false);
        message.error(`${name} ${t('components.datasetsModal.messages.errorLoad')}`);
        break;
      case 'removed':
        setFileLoading(false);
        message.info(`${name} ${t('components.datasetsModal.messages.removedLoad')}`);
        break;
      case 'uploading':
        break;
      default:
        break;
    }

    if (file.status !== 'removed') {
      setFileList([file]);
    } else {
      setFileList([]);
    }
  };

  const handleRemove = removedFile => {
    const newFileList = fileList.filter(file => file.uid !== removedFile.uid);
    setFileList(newFileList);
    // const index = fileList.indexOf(file);
    // const newFileList = fileList.slice();
    // newFileList.splice(index, 1);
    // setFileList(newFileList);
  };

  const handleBeforeUpload = file => {
    const fileExt = file.name
      .split('.')
      .pop()
      .toLowerCase();
    if (fileExt !== 'txt' && fileExt !== 'pdf') {
      message.error('Некорректное расширение файла. Разрешены только .txt и .pdf файлы.');
      setDisabledFile(true);
      setFileList([]);
      return false;
    }
    setDisabledFile(false);
    return true;
  };

  return (
    <SModal
      title={t('components.datasetsModal.title')}
      // confirmLoading={loading}
      centered
      visible={isModalOpen}
      onCancel={() => {
        if (fileLoading) {
          message.info(t('components.datasetsModal.messages.uploading'));
          return;
        }
        closeModal();
      }}
      size="big"
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            if (fileLoading) {
              message.info(t('components.datasetsModal.messages.uploading'));
              return;
            }
            closeModal();
          }}
        >
          {t('components.datasetsModal.buttons.cancel')}
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={form.submit}
          // disabled={disabled}
          loading={loading}
        >
          {t('components.datasetsModal.buttons.save')}
        </Button>
      ]}
    >
      <Form
        form={form}
        name="moveUserToUnit"
        initialValues={initialValues}
        scrollToFirstError
        layout="vertical"
        disabled={disabled}
        onFinish={handleSubmit}
      >
        <Form.Item
          label={t('components.datasetsModal.form.fields.name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('datasetAiPage.messages.enterName')
            }
          ]}
        >
          <SInput
            placeholder={t('components.datasetsModal.form.placeholder.name')}
            onChange={e => onChangeName(e.target.value)}
          />
        </Form.Item>
        {/* <Form.Item name="isActive" valuePropName="checked"> */}
        {/*  <Checkbox>{t('components.datasetsModal.form.fields.isActive')}</Checkbox> */}
        {/*  /!* @todo change to switch *!/ */}
        {/*  /!* <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" /> *!/ */}
        {/* </Form.Item> */}

        <Form.Item
          name="file"
          rules={[
            {
              required: true,
              message: t('datasetAiPage.messages.loadFile')
            }
          ]}
        >
          <SDragger
            fileList={fileList}
            onChange={handleUpload}
            onRemove={handleRemove}
            beforeUpload={handleBeforeUpload}
            // customRequest={customRequest}
            // accept=".txt, .pdf"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              {t('components.datasetsModal.form.fields.upload.text')}
            </p>
            <p className="ant-upload-hint">
              {t('components.datasetsModal.form.fields.upload.hint')}
            </p>
          </SDragger>
        </Form.Item>
      </Form>
    </SModal>
  );
};

export default ModalTask;
