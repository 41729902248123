import {
  Copy,
  Edit,
  Menu as MenuIcon,
  Minus,
  MoreVertical,
  Plus,
  Share2,
  Trash2
} from 'react-feather';
import { Button, Col, Dropdown, Menu, message, Modal, Progress, Row, Select } from 'antd';
import EditableLabel from 'components/Inputs/EditableLabel';
import { PERMISSIONS, SYMBOLIC_TIME_RANGE } from 'core/utils/constants';
import ExcelIcon from 'images/ExcelIcon';
import { get, isEmpty, isEqual } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/lists/clientInteractionsList';
import { communicationTablesResource } from 'redux/resources/communicationTables';
import {
  createCommunicationTable,
  deleteCommunicationTable,
  removeCommunicationTableFromList,
  buildTableRowsOnLoad
} from 'redux/ui/clientInteractions/operations';
import {
  setActiveTable,
  toogleVisibleDrawer,
  toogleVisibleShareSettings,
  updateActiveTable
} from 'redux/ui/clientInteractions/reducer';
import Icon from 'components/Icon';
import { getCurrentUser, getCurrentUserPermissions, getUsersByIds } from 'redux/selectors/users';
import { getUIClientInteractions } from 'redux/selectors/uiClientInterationsTable';
import { getCustomFieldsByKeysForReviews } from 'redux/selectors/customFields';
import { exportWidgetData } from 'pages/DashboardPage/utils';
import { getUserName } from 'components/UserPreview/getUserName';
import parseOptionsForApi from 'core/utils/parseOptionsForApi';
import moment from 'moment';
import { prepareColumns, prepareRows } from './utils';
import {
  checkExportStatus,
  downloadFileAfterExport,
  exportSendEmail,
  getRequestID
} from '../../../../redux/entities/exports/operations';

const { Option } = Select;
const { confirm } = Modal;

const { setFilters } = actions;

const Tables = ({ selectedWorkPlanTaskConfigurationId, hasConflicts }) => {
  const [isEditing, setEditing] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const crmEntitiesByIds = useSelector(state => state.crmEntitiesResource.byIds);
  const communicationTablesByIds = useSelector(state => state.communicationTablesResource.byIds);
  const loadingCommunicationTables = useSelector(
    state => state.communicationTablesResource.loading
  );

  const currentUser = useSelector(getCurrentUser, isEqual);
  const currentUserId = currentUser?.id;
  const usersByIds = useSelector(getUsersByIds);
  const { tableLoading, tableName, tableColumns, tableId, tableFilters, meta } = useSelector(
    getUIClientInteractions,
    shallowEqual
  );

  const disabled = tableLoading || isEmpty(tableId);

  const selectCustomFieldsByKeys = useMemo(() => getCustomFieldsByKeysForReviews, []);
  const customFieldsByKeys = useSelector(state => selectCustomFieldsByKeys(state, true), isEqual);
  const currentUserPermissions = useSelector(state => getCurrentUserPermissions(state) || []);

  const clientInteractionsByIds = useSelector(state => state.clientInteractionsResource.byIds);
  const statusesByIds = useSelector(state => state.statusesResource.byIds);

  const activeTable = communicationTablesByIds[tableId];

  const communicationTablesCount = Object.values(communicationTablesByIds).length;

  const copyTable = async () => {
    try {
      const newTable = await dispatch(
        createCommunicationTable({
          name: `${communicationTablesByIds[tableId]?.name} ${t(
            'clientInteractionsPage.drawer.tables.strings.copyString'
          )}`,
          columns: communicationTablesByIds[tableId]?.columns,
          filters: communicationTablesByIds[tableId]?.filters
        })
      );
      message.success(t('clientInteractionsPage.drawer.tables.messages.tableSuccessfullyCopied'));
      localStorage.setItem('DEALAPP_ACTIVE_TABLE_ID', newTable.id);
    } catch (error) {
      console.log(error);
      message.error(t('clientInteractionsPage.drawer.tables.messages.copyTableFailed'));
    }
  };

  const updateTable = async ({ id, name }) => {
    try {
      const result = await dispatch(
        communicationTablesResource.operations.updateById({ id, name })
      );
      if (result) {
        message.success(
          t('clientInteractionsPage.drawer.tables.messages.tableSuccessfullyUpdated')
        );
      } else {
        message.error(t('clientInteractionsPage.drawer.tables.messages.updateTableFailed'));
      }
    } catch (error) {
      console.log(error);
      message.error(t('clientInteractionsPage.drawer.tables.messages.updateTableFailed'));
    }
  };

  const deleteTable = ({ id }) => {
    confirm({
      title: t('clientInteractionsPage.drawer.tables.confirmDelete.title'),
      okText: t('clientInteractionsPage.drawer.tables.confirmDelete.ok'),
      cancelText: t('clientInteractionsPage.drawer.tables.confirmDelete.cancel'),
      content: t('clientInteractionsPage.drawer.tables.confirmDelete.description'),
      onOk: async () => {
        try {
          const result = dispatch(deleteCommunicationTable({ id }));
          if (result) {
            message.success(
              t('clientInteractionsPage.drawer.tables.messages.tableSuccessfullyDeleted')
            );
          } else {
            message.error(t('clientInteractionsPage.drawer.tables.messages.deleteTableFailed'));
          }
        } catch (error) {
          console.log(error);
          message.error(t('clientInteractionsPage.drawer.tables.messages.deleteTableFailed'));
        }
      }
    });
  };

  const getUserNameForExport = ({ userId }) => {
    const shownUser = usersByIds[userId];
    const isVisibleReviewerName =
      currentUserPermissions.includes(PERMISSIONS.CAN_SEE_REVIEWER_NAME) ||
      currentUserId === userId;

    return isVisibleReviewerName
      ? getUserName({ user: shownUser })
      : t('constants.hiddenNamePlaceholder');
  };

  let modalInstance;

  const exportTable = () => {
    modalInstance = Modal.confirm({
      maskClosable: true,
      confirmLoading: loadingExport,
      title: t('clientInteractionsPage.exportModal.title'),
      autoFocusButton: null,
      okButtonProps: { style: { display: 'none' } },
      cancelButtonProps: { style: { display: 'none' } },
      content: (
        <div>
          <p className="ant-modal-confirm-content">
            {t('clientInteractionsPage.exportModal.content')}
          </p>
          <div className="ant-modal-confirm-btns">
            <Button type="primary" onClick={exportSendMail}>
              {t('clientInteractionsPage.exportModal.mail')}
            </Button>
            <Button type="primary" onClick={exportDownload}>
              {t('clientInteractionsPage.exportModal.download')}
            </Button>
            <Button
              type="default"
              onClick={() => {
                Modal.destroyAll();
              }}
              loading={loadingExport}
            >
              {t('clientInteractionsPage.exportModal.cancel2')}
            </Button>
          </div>
        </div>
      )
    });
  };
  const updateExportBtn = (loadingSendMail, loadingDownload) => {
    modalInstance.update({
      confirmLoading: false,
      content: (
        <div>
          <p className="ant-modal-confirm-content">
            {t('clientInteractionsPage.exportModal.content')}
          </p>
          <div className="ant-modal-confirm-btns">
            <Button type="primary" disabled loading={loadingSendMail}>
              {t('clientInteractionsPage.exportModal.mail')}
            </Button>
            <Button type="primary" onClick={exportDownload} disabled loading={loadingDownload}>
              {t('clientInteractionsPage.exportModal.download')}
            </Button>
            <Button
              type="default"
              onClick={() => {
                Modal.destroyAll();
              }}
              loading={loadingExport}
              disabled
            >
              {t('clientInteractionsPage.exportModal.cancel2')}
            </Button>
          </div>
        </div>
      )
    });
  };
  const exportSendMail = async () => {
    updateExportBtn(true);
    const updateFilters = isEmpty(tableFilters?.taskAssignmentsIds)
      ? tableFilters
      : { taskAssignmentsIds: tableFilters?.taskAssignmentsIds };

    const table = await dispatch(
      communicationTablesResource.operations.loadById(
        parseOptionsForApi({ page: { number: 1, size: meta?.totalCount || '25' }, id: tableId })
      )
    );

    const rows = await dispatch(
      buildTableRowsOnLoad({
        ...{ ...table, meta: { totalCount: meta?.totalCount || '25', totalPages: 1 } },
        filters: updateFilters
      })
    );

    const currentTimeZone = moment().format('Z');
    const body = {
      timezone: currentTimeZone,
      action: 'export',
      filterId: String(table.id),
      email: String(currentUser.email)
    };

    await exportSendEmail({
      body,
      successMessageKey:
        'clientInteractionsPage.drawer.tables.messages.tableSuccessfullyExportMessage1',
      errorMessageKey: 'clientInteractionsPage.drawer.tables.messages.exportTableFailed'
    }).catch(() => Modal.destroyAll());
    Modal.destroyAll();
  };
  const exportDownload = async () => {
    try {
      updateExportBtn(false, true);
      const updateFilters = isEmpty(tableFilters?.taskAssignmentsIds)
        ? tableFilters
        : { taskAssignmentsIds: tableFilters?.taskAssignmentsIds };

      const table = await dispatch(
        communicationTablesResource.operations.loadById(
          parseOptionsForApi({ page: { number: 1, size: meta?.totalCount || '25' }, id: tableId })
        )
      );

      const rows = await dispatch(
        buildTableRowsOnLoad({
          ...{ ...table, meta: { totalCount: meta?.totalCount || '25', totalPages: 1 } },
          filters: updateFilters
        })
      );

      const currentTimeZone = moment().format('Z');
      const exportBody = {
        timezone: currentTimeZone,
        action: 'export',
        filterId: String(table.id),
        email: String(currentUser.email)
      };

      const requestID = await getRequestID({
        body: exportBody,
        errorMessageKey: 'clientInteractionsPage.drawer.tables.messages.exportTableFailed'
      }).catch(() => Modal.destroyAll());

      const exportStatusBody = {
        requestId: requestID
      };

      let exportIntervalId;
      let exportStatus = 0;
      let exportModal;
      const exportModalTwo = () => {
        clearInterval(exportIntervalId);
        const modalInstance = confirm({
          confirmLoading: loadingExport,
          title: t('clientInteractionsPage.exportModal.title'),
          okText: t('clientInteractionsPage.exportModal.ok'),
          cancelText: t('clientInteractionsPage.exportModal.cancelWait'),
          content: t('clientInteractionsPage.exportModal.contentBreak'),
          onOk: exportHandleOk,
          onCancel: () => exportHandleCancel(modalInstance)
        });

        return modalInstance;
      };

      const exportHandleOk = () => {
        clearInterval(exportIntervalId);
        Modal.destroyAll();
      };
      const exportHandleCancel = async modalInstance => {
        modalInstance.destroy();
        if (exportIntervalId) {
          clearInterval(exportIntervalId);
        }
        exportModal = confirm({
          confirmLoading: true,
          title: t('clientInteractionsPage.exportModal.title'),
          autoFocusButton: null,
          okButtonProps: { style: { display: 'none' } },
          cancelText: t('clientInteractionsPage.exportModal.cancel2'),
          content: <Progress percent={exportStatus.progress} status="active" />,
          onCancel: () => exportModalTwo()
        });

        exportIntervalId = setInterval(async () => {
          exportStatus = await checkExportStatus({
            body: exportStatusBody,
            errorMessageKey: 'clientInteractionsPage.drawer.tables.messages.exportTableFailed'
          }).catch(() => {
            clearInterval(exportIntervalId);
            Modal.destroyAll();
          });

          if (exportStatus.progress === 100 || exportStatus.status === 'done') {
            clearInterval(exportIntervalId);
            exportModal.update({
              confirmLoading: false,
              content: <Progress percent={exportStatus.progress} status="success" />
            });
            await downloadFileAfterExport({
              body: exportStatusBody,
              errorMessageKey: 'clientInteractionsPage.drawer.tables.messages.exportTableFailed'
            }).catch(() => {
              exportModal.update({
                content: <Progress percent={exportStatus.progress} status="exception" />
              });
              Modal.destroyAll();
            });
            Modal.destroyAll();
          } else {
            exportModal.update({
              content: <Progress percent={exportStatus.progress} status="active" />
            });
          }
        }, 2000);
      };
      modalInstance.destroy();
      exportModal = confirm({
        confirmLoading: true,
        title: t('clientInteractionsPage.exportModal.title'),
        autoFocusButton: null,
        okButtonProps: { style: { display: 'none' } },
        cancelText: t('clientInteractionsPage.exportModal.cancel2'),
        content: <Progress percent={exportStatus.progress} status="active" />,
        onCancel: () => exportModalTwo()
      });

      exportIntervalId = setInterval(async () => {
        exportStatus = await checkExportStatus({
          body: exportStatusBody,
          errorMessageKey: 'clientInteractionsPage.drawer.tables.messages.exportTableFailed'
        }).catch(() => {
          clearInterval(exportIntervalId);
          Modal.destroyAll();
        });

        if (exportStatus.progress === 100 || exportStatus.status === 'done') {
          clearInterval(exportIntervalId);
          exportModal.update({
            confirmLoading: false,
            content: <Progress percent={exportStatus.progress} status="success" />
          });
          await downloadFileAfterExport({
            body: exportStatusBody,
            errorMessageKey: 'clientInteractionsPage.drawer.tables.messages.exportTableFailed'
          }).catch(() => {
            exportModal.update({
              content: <Progress percent={exportStatus.progress} status="exception" />
            });
            Modal.destroyAll();
          });
          Modal.destroyAll();
        } else {
          exportModal.update({
            content: <Progress percent={exportStatus.progress} status="active" />
          });
        }
      }, 2000);
    } catch (e) {
      Modal.destroyAll();
    }
  };

  const createTable = async () => {
    try {
      const newTable = await dispatch(
        createCommunicationTable({
          name:
            Object.keys(communicationTablesByIds).length === 0
              ? t('clientInteractionsPage.drawer.tables.strings.newTableString')
              : `${t('clientInteractionsPage.drawer.tables.strings.newTableString')} ${
                  Object.keys(communicationTablesByIds).length
                }`,
          columns: [],
          filters: {
            clientInteractionSymbolicTimeRange: SYMBOLIC_TIME_RANGE.THIS_MONTH
          }
        })
      );
      message.success(t('clientInteractionsPage.drawer.tables.messages.tableSuccessfullyCreated'));
      dispatch(setFilters({ clientInteractionSymbolicTimeRange: SYMBOLIC_TIME_RANGE.THIS_MONTH }));
      localStorage.setItem('DEALAPP_ACTIVE_TABLE_ID', newTable.id);
    } catch (error) {
      console.log(error);
      message.error(t('clientInteractionsPage.drawer.tables.messages.createTableFailed'));
    }
  };

  const handleMenuClick = ({ key }) => {
    const actions = {
      createTable,
      editTable: () => setEditing(!isEditing),
      editTableColumns: () => dispatch(toogleVisibleDrawer({})),
      copyTable,
      shareTable: () => dispatch(toogleVisibleShareSettings({})),
      exportTable,
      deleteTable: () => deleteTable({ id: tableId }),
      removeFromList: () => dispatch(removeCommunicationTableFromList({ id: tableId }))
    };

    return actions[key]();
  };

  const tableCreatorId = get(communicationTablesByIds, `${tableId}.creatorId`, '');

  const userOwnsTable = tableCreatorId === currentUserId;

  const menu = (
    <Menu onClick={e => handleMenuClick({ key: e.key })}>
      <Menu.Item key="createTable" disabled={hasConflicts}>
        <Icon icon={Plus} />
        <span>{t('clientInteractionsPage.drawer.tables.menu.addConfig')}</span>
      </Menu.Item>
      <Menu.Item key="editTable" disabled={!userOwnsTable || hasConflicts}>
        <Icon icon={Edit} />
        <span>{t('clientInteractionsPage.drawer.tables.menu.editTable')}</span>
      </Menu.Item>
      <Menu.Item key="editTableColumns" disabled={!userOwnsTable}>
        <Icon icon={MenuIcon} rotate={90} />
        <span>{t('clientInteractionsPage.drawer.tables.menu.editTableColumns')}</span>
      </Menu.Item>
      <Menu.Item key="copyTable" disabled={hasConflicts}>
        <Icon icon={Copy} />
        <span>{t('clientInteractionsPage.drawer.tables.menu.copyTable')}</span>
      </Menu.Item>
      <Menu.Item key="shareTable" disabled={!userOwnsTable || hasConflicts}>
        <Icon icon={Share2} />
        <span>{t('clientInteractionsPage.drawer.tables.menu.shareTable')}</span>
      </Menu.Item>
      <Menu.Item key="exportTable" disabled={!userOwnsTable || hasConflicts}>
        <Icon icon={ExcelIcon} color={hasConflicts && 'rgba(0, 0, 0, 0.25)'} />
        <span style={{ marginLeft: 8 }}>
          {t('clientInteractionsPage.drawer.tables.menu.export')}
        </span>
      </Menu.Item>
      {userOwnsTable && (
        <Menu.Item
          key="deleteTable"
          style={communicationTablesCount > 1 && { color: 'var(--red_primary)' }}
          disabled={communicationTablesCount <= 1}
        >
          <Icon icon={Trash2} />
          <span>{t('clientInteractionsPage.drawer.tables.menu.deleteTable')}</span>
        </Menu.Item>
      )}
      {isEmpty(!activeTable?.usersIds) && activeTable?.usersIds.includes(currentUserId) && (
        <Menu.Item
          key="removeFromList"
          style={communicationTablesCount > 1 && { color: 'var(--red_primary)' }}
          disabled={communicationTablesCount <= 1}
        >
          <Icon icon={Minus} />
          <span>Убрать из списка</span>
        </Menu.Item>
      )}
    </Menu>
  );

  const handleChangeTable = value => {
    dispatch(setFilters(communicationTablesByIds[value]?.filters));
    dispatch(
      setActiveTable({
        id: value,
        ...communicationTablesByIds[value]
      })
    );
    try {
      localStorage.setItem('DEALAPP_ACTIVE_TABLE_ID', value);
    } catch (error) {
      console.log(error);
    }
  };

  const renderTable = ({ creatorId, usersIds = [], name, id }) => {
    let optionTableName;

    if (creatorId === currentUserId && !isEmpty(usersIds))
      optionTableName = `${name} ${t('clientInteractionsPage.drawer.tables.strings.openAsccess')}`;

    if (isEmpty(!usersIds) && usersIds.includes(currentUserId))
      optionTableName = `${name} (${t('clientInteractionsPage.drawer.tables.strings.author')}: ${
        usersByIds[creatorId]?.name
      })`;

    if (isEmpty(usersIds)) optionTableName = `${name}`;

    return (
      <Option value={id} key={id}>
        {optionTableName}
      </Option>
    );
  };

  if (isEmpty(communicationTablesByIds)) {
    return (
      <Button block onClick={createTable} disabled={loadingCommunicationTables}>
        {t('clientInteractionsPage.drawer.tables.buttons.addConfig')}
      </Button>
    );
  }

  return (
    <Row type="flex" align="middle" justify="space-between">
      {isEditing ? (
        <Col span={24}>
          <EditableLabel
            initialState="edit"
            value={tableName}
            onSave={(name, { final }) => {
              dispatch(updateActiveTable({ tableName: name }));
              if (final) {
                updateTable({ id: tableId, name: tableName });
                setEditing(!isEditing);
              }
            }}
          />
        </Col>
      ) : (
        <Col span={24}>
          <Row type="flex" align="middle" justify="space-between">
            <Col span={20}>
              <Select
                placeholder={t('clientInteractionsPage.drawer.tables.selectTablePlaceholder')}
                onChange={handleChangeTable}
                value={tableId}
                style={{ width: '100%' }}
                disabled={disabled || selectedWorkPlanTaskConfigurationId}
              >
                {Object.values(communicationTablesByIds).map(renderTable)}
              </Select>
            </Col>
            <Col>
              <Dropdown
                overlay={menu}
                trigger={['click']}
                placement="bottomLeft"
                disabled={disabled || selectedWorkPlanTaskConfigurationId}
              >
                <Button icon={<Icon icon={MoreVertical} />} />
              </Dropdown>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default React.memo(Tables);
