import Icon from 'components/Icon';
import { Plus } from 'react-feather';
import { Button, Form } from 'antd';
import { omit } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  deleteMediaUrl,
  updateCustomCommunicationForm
} from 'redux/ui/customCommunicationReviewPage/reducer';
import uniqid from 'uniqid';
import { MediaUrlInput } from './MediaUrlInput';

const DynamicFields = ({ deleteMediaUrl }) => {
  const [fields, setFields] = useState({});
  const { t } = useTranslation();

  const remove = k => {
    const newState = omit(fields, k);
    setFields(newState);
    deleteMediaUrl({ name: `metadataMediaUrls[${k}]` });
  };

  const add = () => {
    const id = uniqid();
    const newState = { ...fields, [id]: '' };
    setFields(newState);
  };

  const checkUrl = (as, value) => {
    const validation = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
    if (validation.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t('customCommunicationPage.form.messages.wrongMediaUrl')));
  };

  const formItems = Object.keys(fields).map(id => (
    <Form.Item
      key={id}
      name={[`metadataMediaUrls`, id]}
      validateTrigger={['onChange', 'onBlur']}
      rules={[{ validator: checkUrl }]}
    >
      <MediaUrlInput allowRemove={Object.keys(fields).length > 0} id={id} remove={remove} />
    </Form.Item>
  ));

  return (
    <>
      {formItems}
      <Form.Item>
        <Button type="dashed" onClick={() => add()} block>
          <Icon icon={Plus} />
          <span>{t('customCommunicationPage.form.buttons.addUrl')}</span>
        </Button>
      </Form.Item>
    </>
  );
};

const mapDispatchToProps = {
  updateCustomCommunicationForm,
  deleteMediaUrl
};

export default connect(null, mapDispatchToProps)(DynamicFields);
