import { Button } from 'antd';
import Editor from 'components/Comments/Editor';
import { isEmpty, isFunction } from 'lodash';
import React, { useRef, useState } from 'react';
import { getCurrentUser } from 'redux/selectors/users';
import styled from 'styled-components';
import uniqid from 'uniqid';
import { useTranslation } from 'react-i18next';
import useEvent from '@react-hook/event';
import SRow from 'components/Standard/SRow';
import { useDispatch, useSelector } from 'react-redux';
import SButton from 'components/Standard/SButton';
import { stopPlaying } from 'redux/ui/recordPlayer/reducer';
import SCard from 'components/Standard/SCard';
import {
  setComments,
} from 'redux/ui/checklistEditor/reducer';

const StyledSCard = styled(SCard)`
  &.ant-card > .ant-card-body {
    border-radius: inherit;
  }
  &.ant-card > .ant-card-head {
    border-bottom: none;
  }
  &.ant-card > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
    padding: unset;
  }
`;

const defaultCommentState = {
  text: '',
  metadata: {
    ratingFlag: ''
  },
  isPublicTemplate: false,
  hidden: false,
  isTimestampAdded: true
};

const CreateCommentForm = ({
  seconds,
  onCancel,
  allowSelectTimestamp = true,
  onCommentSave,
  reviewId,
  callId,
  id
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(
    state => state.commentsResource.createStarted || state.commentsResource.loading
  );
  const playedSeconds = useSelector(state => seconds || state.uiRecordPlayer.playedSeconds);
  const currentUser = useSelector(state => getCurrentUser(state));
  const comments = useSelector(state => state.uiChecklistEditor.comments);
  const addingCommentsToQuestionId = useSelector(
    state => state.uiChecklistManager.addingCommentsToQuestionId
  );
  const [commentState, setCommentState] = useState(defaultCommentState);
  const [emp, setEmp] = useState(false);
  const { text, metadata, isTimestampAdded, hidden, uploadedFiles = [] } = commentState;

  const editorRef = useRef();

  const onSave = (e) => {
    localStorage.setItem("commId", e.currentTarget.id);
    if (!!e.currentTarget.id) {
      dispatch(setComments({ [e.currentTarget.id] : null }))
    }
    const commentParams = {
      text,
      id: uniqid(),
      reviewId,
      callId,
      metadata: {
        ...(metadata?.ratingFlag && { ratingFlag: metadata?.ratingFlag }),
        ...(metadata?.questionId && { questionId: metadata?.questionId }),
        ...(isTimestampAdded && playedSeconds && { timestamp: playedSeconds })
      },
      authorId: currentUser.id,
      hidden,
      commentType: 'review_comment',
      uploadedFilesIds: uploadedFiles.map(({ id }) => id)
    };

    if (isFunction(onCommentSave)) {
      onCommentSave(commentParams);
    }
    if (commentParams) {
      setCommentState(defaultCommentState);
    }
    if (editorRef.current !== null) {
      editorRef.current.setEditorState(defaultCommentState);
    }
  };

  const isEmptyComment = isEmpty(uploadedFiles) ? isEmpty(text?.trim()) : false;

  useEvent(document, 'keydown', e => {
    const drawer = document.getElementsByClassName('ant-drawer')[0];
    const isEditorKeyPress = e.target.classList.contains('public-DraftEditor-content');
    if (e.code === 'Enter' && (e.ctrlKey || e.metaKey) && !isEmptyComment && isEditorKeyPress) {
      onSave();
      // if (addingCommentsToQuestionId) {
      //   if (drawer) drawer.focus();
      // }
    }
  });

  return (
    <StyledSCard
      bordered={editorRef.current !== null ? '2px solid #C5D2FB;' : true}
      shadowed={
        editorRef.current !== null
          ? 'inset 0px -1px 0px #597ef7, inset 0px 1px 0px #597ef7, inset -1px 0px 0px #597ef7, inset 1px 0px 0px #597ef7'
          : true
      }
      headPadding="16px 16px 0px"
    >
      <SRow>
        <Editor
          commentState={commentState}
          setCommentState={setCommentState}
          setEmp={setEmp}
          id={id}
          showTemplates
          showFlags
          showAttachments
          allowHidden
          allowSelectTimestamp={allowSelectTimestamp}
          playedSeconds={playedSeconds}
          isTimestampAdded={isTimestampAdded}
          stopPlaying={stopPlaying}
          ref={editorRef}
          actionsComponent={[
            <Button key="cancel" type="default" onClick={onCancel} className="BraftEditor-actions">
              {t('components.commentsPanel.buttons.cancel')}
            </Button>,
            <SButton
              key="save"
              type="primary"
              id={id}
              onClick={onSave}
              disabled={(!!!commentState.text && !!!comments[id]?.toText()) || emp}
              className="BraftEditor-actions"
            >
              {t('components.commentsPanel.buttons.send')}
            </SButton>
          ]}
        />
      </SRow>
    </StyledSCard>
  );
};

export default CreateCommentForm;
