import React, { useEffect, useRef, useState } from 'react';
import {
  Modal,
  Input,
  Checkbox,
  Radio,
  Row,
  Col,
  Button,
  Select,
  Typography,
  Form,
  message
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { DATE_PICKER_MODES } from '../../core/utils/constants';
import { setDefaultState } from '../../redux/ui/clientInteractions/reducer';
import { usersResource } from '../../redux/resources/users';
import { customFieldsResource } from '../../redux/resources/customFields';
import { statusesResource } from '../../redux/resources/status';
import { rolesResource } from '../../redux/resources/roles';
import { unitsResource } from '../../redux/resources/units';
import CustomDatePicker from '../../components/DatePicker/DatePicker';
import { checklistDefinitionsResource } from '../../redux/resources/checklistDefinitions';
import { actions } from '../../redux/lists/appellationsListReducer';
import { aiSettingsResource } from '../../redux/resources/aiSettings';
import api from '../../core/api';

const ModalTask = ({
  isModalOpen,
  setIsModalOpen,
  selectedRows = null,
  isEditingData = false,
  setIsEditingData,
  loadStatuses,
  loadCustomFields,
  loadRoles,
  loadUsers,
  loadChecklistDefinitions
}) => {
  const { t } = useTranslation();
  const status = useSelector(state => state.statusesResource.byIds, isEqual);
  const users = useSelector(state => state.usersResource.byIds, isEqual);
  const roles = useSelector(state => state.rolesResource.byIds, isEqual);
  const units = useSelector(state => state.unitsResource.byIds, isEqual);
  const checklistDefinitions = useSelector(
    state => state.checklistDefinitionsResource.byIds,
    isEqual
  );
  const [loading, setLoading] = useState(false);
  const customFields = useSelector(state => state.customFieldsResource.byIds, isEqual);
  const [activeConditionSlector, setActiveConditionSlector] = useState(false);
  const [conditionsDrawingSelect, setConditionsDrawingSelect] = useState([
    { value: 'Статус проверки', label: 'Статус проверки', isActive: true },
    { value: 'Операторы', label: 'Операторы', isActive: true },
    { value: 'Уровни', label: 'Уровни', isActive: true },
    { value: 'Отдел', label: 'Отдел', isActive: true },
    { value: 'Период', label: 'Период', isActive: true },
    { value: 'Статус контакта', label: 'Статус контакта', isActive: true }
  ]);
  const { filters } = useSelector(state => state.appellationsList);
  const [selectedDataset, setSelectedDataset] = useState([]);
  const currentUserAttributes = useSelector(state => state.reduxTokenAuth.currentUser.attributes);
  const organization_id = currentUserAttributes?.user['organization-id'];
  const selectRefAddConditions = useRef();
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  useEffect(async () => {
    if (isEmpty(users)) {
      loadUsers({
        pagination: 'false',
        include: 'unit,role.levels',
        sort: 'last_name',
        'filters[with_inactive]': 'true'
      });
    }
    if (isEmpty(status)) {
      loadStatuses({ pagination: 'false' });
    }
    if (isEmpty(customFields)) {
      loadCustomFields({ pagination: 'false' });
    }
    if (isEmpty(roles)) {
      loadRoles();
    }
    if (isEmpty(checklistDefinitions)) {
      loadChecklistDefinitions();
    }
    if (isEmpty(selectedDataset)) {
      await api.getDataSet({ organization_id }).then(response => {
        setSelectedDataset(response.body.data);
      });
    }
    form.setFieldsValue({
      taskName: '',
      taskEnable: true,
      taskType: 'disposable',
      checklistDefinition: null,
      dataset: null,
      prompts: [],
      operators: [],
      contactStatus: [],
      departments: [],
      levels: [],
      selectedStatus: null
    });
    return setDefaultState;
  }, []);
  useEffect(async () => {
    if (isEditingData) {
      await initEditDataModal(selectedRows);
    }
  }, [selectedRows]);
  function initEditDataModal(initData) {
    const filter = JSON.parse(initData.filter);
    form.setFieldsValue({
      taskName: initData.name || '',
      taskEnable: initData.isActive || true,
      taskType: initData.taskType || 'disposable',
      checklistDefinition: initData.checklistId || null,
      dataset: filter.dataset || null,
      prompts: JSON.parse(initData.customFieldsIds) || [],
      operators: filter.operators || [],
      contactStatus: filter.contactStatus || [],
      departments: filter.departments || [],
      levels: filter.levels || [],
      selectedStatus: filter.checkedStatus || null
    });
    dispatch(
      actions.updateFilters({
        appellationCreatedAtSymbolicTimeRange: filter?.period?.symbolicTimeRange,
        appellationCreatedAtTimeFrom: filter?.period?.timeFrom,
        appellationCreatedAtTimeTo: filter?.period?.timeTo
      })
    );

    setConditionsDrawingSelect(prevState => {
      return prevState.map(condition => {
        switch (condition.value) {
          case 'Статус проверки':
            condition.isActive = !filter?.checkedStatus;
            break;
          case 'Операторы':
            condition.isActive = !filter?.operators;
            break;
          case 'Уровни':
            condition.isActive = !filter?.levels;
            break;
          case 'Отдел':
            condition.isActive = !filter?.departments;
            break;
          case 'Период':
            condition.isActive = !filter?.period;
            break;
          case 'Статус контакта':
            condition.isActive = !filter?.contactStatus;
            break;
          default:
            break;
        }
        return condition;
      });
    });
  }

  const resetDataModal = () => {
    setIsModalOpen(false);
    setIsEditingData(false);
    form.setFieldsValue({
      taskName: '',
      taskEnable: true,
      taskType: 'disposable',
      checklistDefinition: null,
      dataset: null,
      prompts: [],
      operators: [],
      contactStatus: [],
      departments: [],
      levels: [],
      selectedStatus: null
    });
    const updatedConditions = conditionsDrawingSelect.map(condition => {
      return { ...condition, isActive: true };
    });
    setConditionsDrawingSelect(updatedConditions);
  };
  const handleSaveTask = async value => {
    setLoading(true);
    let dataPeriod;
    conditionsDrawingSelect.forEach(condition => {
      if (!condition.isActive) {
        switch (condition.value) {
          case 'Период':
            dataPeriod = {
              symbolicTimeRange: filters.appellationCreatedAtSymbolicTimeRange,
              timeFrom: filters.appellationCreatedAtTimeFrom,
              timeTo: filters.appellationCreatedAtTimeTo
            };
            break;
        }
      }
    });
    const filter = {
      levels: value.levels,
      period: dataPeriod,
      operators: value.operators,
      departments: value.departments,
      checkedStatus: value.checkedStatus,
      contactStatus: value.contactStatus,
      dataset: value.dataset
    };

    if (isEditingData) {
      await dispatch(
        aiSettingsResource.operations.updateById({
          id: selectedRows.id,
          actions: {
            update_name: value.taskName,
            update_activity: value.taskEnable,
            update_filter: JSON.stringify(filter),
            update_checklist_id: value.checklistDefinition,
            update_custom_fields_ids: value.prompts
          }
        })
      ).then(response => {
        if (response?.id) {
          message.success(t('aiSettingsPage.operations.operationSuccessfully'));
        }
        resetDataModal();
      });
    } else {
     await dispatch(
        aiSettingsResource.operations.create({
          organization_id,
          name: value.taskName,
          checklist_id: value.checklistDefinition,
          custom_fields_ids: value.prompts,
          task_type: value.taskType,
          filter: JSON.stringify(filter)
        })
      ).then(response => {
        if (response?.id) {
          Modal.warning({
            content: t('aiSettingsPage.operations.saveAiTask')
          });
        }
        resetDataModal();
      });
    }
    setLoading(false);
  };

  const handleConditionChange = value => {
    const updatedConditions = conditionsDrawingSelect.map(condition => {
      if (condition.value === value) {
        return { ...condition, isActive: !condition.isActive };
      }
      return condition;
    });
    setConditionsDrawingSelect(updatedConditions);
    setActiveConditionSlector(false);
  };

  const handleAddCondition = () => {
    setActiveConditionSlector(true);
    setTimeout(() => {
      selectRefAddConditions.current.focus();
    }, 0);
  };
  const allInactive = conditionsDrawingSelect.every(item => !item.isActive);
  return (
    <Modal
      title={
        isEditingData
          ? t('aiSettingsPage.taskSettings.edit')
          : t('aiSettingsPage.taskSettings.newTask')
      }
      centered
      visible={isModalOpen}
      footer={null}
      style={{ width: '500px' }}
      onCancel={resetDataModal}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={values => {
          if (!values.checklistDefinition && values.prompts.length === 0) {
            message.error(t('aiSettingsPage.operations.checkAIAssessmentFormOrPromptsValues'));
          } else {
            handleSaveTask(values);
          }
        }}
      >
        <Form.Item
          label={t('aiSettingsPage.taskSettings.enterName')}
          name="taskName"
          rules={[
            { required: true, message: t('aiSettingsPage.taskSettings.pleaseEnterTaskName') }
          ]}
        >
          <Input placeholder={t('aiSettingsPage.taskSettings.enterName')} />
        </Form.Item>
        <Form.Item name="taskEnable" valuePropName="checked">
          <Checkbox disabled={!isEditingData}>
            {t('aiSettingsPage.taskSettings.disableEnable')}
          </Checkbox>
        </Form.Item>
        <Form.Item name="taskType" label={t('aiSettingsPage.taskSettings.taskType')}>
          <Radio.Group disabled={isEditingData}>
            <Radio value="disposable">{t('aiSettingsPage.taskSettings.oneTime')}</Radio>
            <Radio value="constant">{t('aiSettingsPage.taskSettings.regular')}</Radio>
          </Radio.Group>
        </Form.Item>
        <Row style={{ margin: '16px 0' }}>
          <Col>
            <Typography.Text>
              {t('aiSettingsPage.taskSettings.communicationFilter')}
            </Typography.Text>
          </Col>
        </Row>
        {conditionsDrawingSelect.map(
          condition =>
            !condition.isActive && (
              <Row
                style={{
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                  marginTop: '8px'
                }}
              >
                <Col style={{ width: '100%' }}>
                  {condition.value === 'Операторы' && (
                    <Form.Item name="operators" style={{ marginBottom: '0' }}>
                      <Select
                        mode="multiple"
                        placeholder={t('aiSettingsPage.taskSettings.operators')}
                        size="middle"
                        maxTagCount={0}
                        maxTagPlaceholder={selectedKeys =>
                          `${t('aiSettingsPage.taskSettings.operators')}: ${selectedKeys.length}`
                        }
                        defaultOpen={isEditingData ? false : !!isModalOpen}
                      >
                        {Object.values(users).map(unit => (
                          <Select.Option key={unit.id} value={unit.id}>
                            {unit.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  {condition.value === 'Статус контакта' && (
                    <Form.Item name="contactStatus" style={{ marginBottom: '0' }}>
                      <Select
                        mode="multiple"
                        placeholder={t('aiSettingsPage.taskSettings.contactStatus')}
                        size="middle"
                        maxTagCount={0}
                        maxTagPlaceholder={selectedKeys =>
                          `${t('aiSettingsPage.taskSettings.contactStatus')}: ${
                            selectedKeys.length
                          }`
                        }
                        defaultOpen={isEditingData ? false : !!isModalOpen}
                      >
                        {Object.values(status).map(unit => (
                          <Select.Option key={unit.id} value={unit.id}>
                            {unit.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  {condition.value === 'Отдел' && (
                    <Form.Item name="departments" style={{ marginBottom: '0' }}>
                      <Select
                        mode="multiple"
                        placeholder={t('aiSettingsPage.taskSettings.department')}
                        size="middle"
                        maxTagCount={0}
                        maxTagPlaceholder={selectedKeys =>
                          `${t('aiSettingsPage.taskSettings.department')}: ${selectedKeys.length}`
                        }
                        defaultOpen={isEditingData ? false : !!isModalOpen}
                      >
                        {Object.values(units).map(unit => (
                          <Select.Option key={unit.id} value={unit.id}>
                            {unit.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}

                  {condition.value === 'Уровни' && (
                    <Form.Item name="levels" style={{ marginBottom: '0' }}>
                      <Select
                        mode="multiple"
                        placeholder={t('aiSettingsPage.taskSettings.levels')}
                        size="middle"
                        maxTagCount={0}
                        maxTagPlaceholder={selectedKeys =>
                          `${t('aiSettingsPage.taskSettings.levels')}: ${selectedKeys.length}`
                        }
                        defaultOpen={isEditingData ? false : !!isModalOpen}
                      >
                        {Object.values(roles).map(unit => (
                          <Select.Option key={unit.id} value={unit.id}>
                            {unit.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  {condition.value === 'Период' && (
                    <Form.Item style={{ marginBottom: '0' }}>
                      <CustomDatePicker
                        onChange={filters => {
                          dispatch(actions.updateFilters({ ...filters }));
                        }}
                        value={{
                          symbolicTimeRange: filters?.appellationCreatedAtSymbolicTimeRange,
                          timeFrom: filters?.appellationCreatedAtTimeFrom,
                          timeTo: filters?.appellationCreatedAtTimeTo
                        }}
                        position="left"
                        mode={DATE_PICKER_MODES.custom.appellation}
                      />
                    </Form.Item>
                  )}
                  {condition.value === 'Статус проверки' && (
                    <Form.Item name="selectedStatus" style={{ marginBottom: '0' }}>
                      <Select
                        placeholder={t('aiSettingsPage.taskSettings.checkStatus')}
                        size="middle"
                        defaultValue="all"
                        optionLabelProp="label"
                        defaultOpen={isEditingData ? false : !!isModalOpen}
                      >
                        <Select.Option
                          key="all"
                          value="all"
                          label={`${t(
                            'clientInteractionsPage.tableFilters.tableGeneralFilters.form.reviewStatus'
                          )}: ${t('constants.reviewStatuses.all')}`}
                        >
                          {t('constants.reviewStatuses.all')}
                        </Select.Option>
                        <Select.Option
                          key="reviewed"
                          value="true"
                          label={`${t(
                            'clientInteractionsPage.tableFilters.tableGeneralFilters.form.reviewStatus'
                          )}: ${t('constants.reviewStatuses.reviewed')}`}
                        >
                          {t('constants.reviewStatuses.reviewed')}
                        </Select.Option>
                        <Select.Option
                          key="notReviewed"
                          value="false"
                          label={`${t(
                            'clientInteractionsPage.tableFilters.tableGeneralFilters.form.reviewStatus'
                          )}: ${t('constants.reviewStatuses.notReviewed')}`}
                        >
                          {t('constants.reviewStatuses.notReviewed')}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  )}
                </Col>

                <Col>
                  <DeleteOutlined
                    style={{ color: 'red', marginLeft: '8px', fontSize: '20px' }}
                    onClick={() => handleConditionChange(condition.value)}
                  />
                </Col>
              </Row>
            )
        )}

        <Form.Item name="condition">
          {!activeConditionSlector && !allInactive && (
            <Col style={{ color: '#85a5ff', cursor: 'pointer' }} onClick={handleAddCondition}>
              {t('aiSettingsPage.taskSettings.addCondition')}
            </Col>
          )}
          {activeConditionSlector && conditionsDrawingSelect.length !== 0 && (
            <Select
              ref={selectRefAddConditions}
              placeholder={t('aiSettingsPage.taskSettings.addCondition')}
              size="middle"
              onChange={handleConditionChange}
              open={activeConditionSlector && conditionsDrawingSelect.length !== 0}
              style={{ marginTop: '16px' }}
            >
              {conditionsDrawingSelect.map(
                conditionDrawingSelect =>
                  conditionDrawingSelect.isActive && (
                    <Select.Option value={conditionDrawingSelect.value}>
                      {conditionDrawingSelect.label}
                    </Select.Option>
                  )
              )}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={t('aiSettingsPage.taskSettings.selectAiRatingForm')}
          name="checklistDefinition"
        >
          <Select placeholder={t('aiSettingsPage.taskSettings.rating')} size="middle">
            {Object.values(checklistDefinitions).map(unit => (
              <Select.Option key={unit.id} value={unit.id}>
                {unit.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('aiSettingsPage.taskSettings.selectPrompts')} name="prompts">
          <Select
            mode="multiple"
            placeholder={t('aiSettingsPage.taskSettings.prompt')}
            size="middle"
            maxTagCount={0}
            maxTagPlaceholder={selectedKeys =>
              `${t('aiSettingsPage.taskSettings.prompt')}: ${selectedKeys.length}`
            }
          >
            {Object.values(customFields).map(unit => (
              <Select.Option key={unit.id} value={unit.id}>
                {unit.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('aiSettingsPage.taskSettings.selectDataset')} name="dataset">
          <Select placeholder={t('components.timeSpecificSelector.selectButton')} size="middle">
            {Array.isArray(selectedDataset) &&
              Object.values(selectedDataset).map(unit => (
                <Select.Option key={unit.id} value={unit?.attributes?.name}>
                  {unit?.attributes?.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Row justify="end">
            <Button onClick={resetDataModal} style={{ marginRight: 8 }}>
              {t('general.cancel')}
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              {isEditingData ? t('general.edit') : t('general.save')}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { filters } = state.clientInteractionsList;
  return {
    filters: ownProps?.hasConflicts ? {} : filters,
    customFields: state.customFieldsResource.byIds
  };
};

const mapDispatchToProps = {
  loadCustomFields: customFieldsResource.operations.load,
  loadStatuses: statusesResource.operations.load,
  loadRoles: rolesResource.operations.load,
  loadUsers: usersResource.operations.load,
  loadUnits: unitsResource.operations.loadUnits,
  setDefaultState,
  loadChecklistDefinitions: checklistDefinitionsResource.operations.loadWithInclude
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalTask);
