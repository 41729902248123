export default {
  dashboardPage: {
    widget: {
      filtersIsNotInstalled: 'Filtri non installati',
      unitName: 'Dipartimento',
      needToInstallFilters: 'I filtri devono essere installati',
      checkFilters: 'Controlla i filtri',
      emptyFiltersData: 'Nessun dato trovato per questi filtri',
      emptyName: 'Nome non impostato',
      outdated: {
        title: 'Widget non supportato',
        outdatedFlagsAndCommentsWidgets:
          'Per continuare a lavorare, crea il widget XL "Bandiere e commenti"'
      },
      conflicts: {
        title: 'Los ajustes establecidos no están disponibles\n',
        description1:
          "A causa di un cambiamento nelle impostazioni del profilo, hai perso l'accesso ai dati.",
        description2:
          'Per cambiare le impostazioni del widget, premi "Clear widget" e imposta nuovi filtri. Per riottenere l\'accesso alle impostazioni precedentemente impostate, contattate l\'amministratore.'
      },
      buttons: {
        more: " Piu' dettagli",
        export: 'Esportare in Excel'
      },
      messages: {
        analyticsSuccessfullyExported: 'Analitica esportata',
        exportAnalyticsFailed: "Impossibile esportare l'analitica",
        needMoreData: 'Per costruire un grafico, sono necessarie stime per almeno due giorni'
      },
      utils: {
        emptyName: 'Il nome non trovato',
        nameIsNotInstalled: 'Nome non installato:',
        emptyDescription: 'Descrizione non trovata',
        allTime: 'Per tutto il tempo',
        info: 'Informazione'
      },
      modal: {
        tooltip: 'Impostazioni',
        title: 'Impostazioni del widget',
        buttons: {
          reset: 'Ripristina widget',
          cancel: 'Annulla',
          save: 'Salvare'
        },
        messages: {
          widgetSuccessfullyUpdated: 'Widget aggiornato',
          updateWidgetFailed: 'Impossibile aggiornare il widget',
          requiredField: '(Campo obbligatorio)'
        },
        form: {
          type: 'Tipo di dati',
          typePlaceholder: 'Seleziona il tipo di dati da visualizzare',
          name: 'Nome del widget',
          namePlaceholder: 'Inserisci un nome del widget',
          communicationDate: 'Data di comunicazione',
          communicationsCreationPeriod: 'Periodo di creazione della comunicazione',
          communicationsCreationPeriodPlaceholder: 'Seleziona il mese',
          isWeekendView: 'Mostra il fine settimana',
          isWeekendViewInfo:
            'Consente di scegliere se visualizzare nel widget le colonne per i fine settimana' +
            ' inclusi nel periodo specificato e se verranno prese in considerazione nel calcolo della colonna "Totale".',
          isWeekendViewPlaceholder: 'Seleziona lo stato di visualizzazione del fine settimana',
          reviewDate: 'Data di valutazione',
          date: 'Data',
          unit: 'Dipartimento',
          departmentBeingAudited: 'Dipartimento in fase di verifica',
          departmentBeingAuditedPlaceholder: 'Seleziona il reparto da controllare',
          unitPlaceholder: 'Seleziona un dipartimento',
          operators: 'Operatori',
          operatorsPlaceHolder: 'Seleziona operatori',
          reviewers: 'Supervisori',
          reviewersPlaceHolder: 'Seleziona i supervisori',
          checklistDefinition: 'Modulo di valutazione ',
          taskDefinitions: 'Tag',
          taskDefinitionsPlaceHolder: 'Seleziona i tag',
          isReviewed: 'Stato della valutazione',
          isReviewedPlaceHolder: 'Seleziona lo stato della valutazione',
          direction: 'Direzione della chiamata',
          directionPlaceHolder: 'Seleziona la direzione della chiamata',
          dateFormat: 'Formato di visualizzazione',
          dateFormatPlaceHolder: 'Selezione il formato di visualizzazione'
        }
      }
    },
    dashboard: {
      emptyDashboard: 'Nessun dashboard creato',
      emptyDashboardRows: 'Nessuna linea creata',
      newDashboard: 'Nuovo dashboard',
      loading: "Carricamento dell'analisi",
      messages: {
        dashboardSuccessfullyCreated: 'Dashboard creato',
        createDashboardFailed: 'Impossibile creare il dashboard'
      },
      buttons: { create: 'Creare' },
      reviewsCountShort: 'Controlli',
      drawer: {
        title: 'Impostazioni dashboard',
        dashboards: {
          title: 'Dashboard',
          confirmDelete: {
            title: 'Sei sicuro di voler eliminare questo dashboard?',
            description:
              'Dopo aver eliminato un dashboard, le sue impostazioni non possono essere ripristinate'
          },
          messages: {
            rowsLimit: "Non sono disponibili piu' di 10 righe per un dashboard",
            dashboardSuccessfullyDeleted: 'Dashboard eliminato',
            dashboardDeleteFailed: 'Impossibile eliminare il dashboard',
            dashboardSuccessfullyCopied: 'Dashboard copiato',
            copyDashboardFailed: 'Impossibile copiare il dashboard',
            getPro: "Passa a un'altra tariffa per creare piu' dashboard. "
          },
          menu: {
            edit: 'Rinomina dashboard',
            copy: 'Copia dashboard',
            delete: 'Elimina dashboard'
          },
          buttons: {
            more: "Piu' dettagli",
            addDashboard: 'Aggiungere un nuovo dashboard'
          }
        },
        rows: {
          title: 'Widget',
          confirmDelete: {
            title: 'Sei sicuro di voler eliminare la riga?',
            ok: 'Si',
            cancel: 'No'
          },
          buttons: { addRow: 'Aggiungi una riga' },
          rowsCount1: 'Linea con 1 widget',
          rowsCountPT1: 'Linea con ',
          rowsCountPT2: 'widget'
        }
      }
    },
    tableReviewsCountByOperators: {
      table: {
        columns: {
          dates: 'Date',
          totalScore: 'Risultato',
          reviewsCount: 'Numero di valutazioni',
          averageScore: 'Media'
        }
      },
      viewModes: {
        types: {
          table: { name: 'Tabella' },
          chart: {
            name: 'Grafico',
            seriesNames: {
              averageScoreByOperator: 'Risultato medio per dipendente',
              totalAverageScore: 'Risultato medio di tutti dipendenti'
            },
            yaxis: 'Punteggio medio',
            scoreStr: 'punteggio',
            percantageStr: '%',
            table: {
              name: 'Nome',
              description: 'Descrizione',
              averageScoreStr: 'Punteggio medio (max:'
            }
          }
        }
      }
    },
    tableChecklistItemsByOperators: {
      table: {
        columns: {
          questions: 'Criteri',
          groups: 'Gruppi',
          totalScore: 'Risultato',
          average: 'Media'
        }
      },
      viewModes: {
        questions: 'Criteri',
        groups: 'Gruppi'
      }
    },
    tableChecklistItemsHistory: {
      table: {
        columns: {
          dates: 'Date',
          totalScore: 'Risultato',
          reviewsCount: 'Numero di valutazioni',
          average: 'Media'
        }
      },
      viewModes: {
        questions: 'Criteri',
        groups: 'Gruppi'
      }
    },
    flagsAndComments: {
      viewModes: {
        operators: 'Operatori',
        units: 'Dipartimenti'
      },
      table: {
        columns: {
          name: 'Nome',
          commentsCount: 'Numero di bandiere e commenti'
        }
      }
    },
    tableChecklistsScoresByOperators: {
      firstColumnNames: {
        operators: 'Dipendenti',
        units: 'Dipartimenti'
      },
      columns: { totalScore: 'Risultato' },
      rows: { footer: 'Media' }
    },
    tableReviewsCountByReviewers: {
      changeViewType: "Cambia modalita' di visualizzazione",
      firstColumnNames: {
        reviewers: 'Supervisori',
        units: 'Dipartimenti'
      },
      rows: {
        header: 'Tempo totale per il controllo delle chiamate',
        headerInfo:
          'La durata totale delle chiamate verificate. Tiene conto della durata delle chiamate controllate tutte le volte che sono state controllate.',
        footer: 'Numero totale di valutazioni'
      },
      viewModes: {
        chart: {
          table: {
            columns: {
              name: 'Nome',
              reviewsCount: 'Valutazioni',
              reviewsPercentage: '% di valutazioni',
              totalReviewsTime: 'Tempo'
            },
            rows: { total: 'Totale' }
          },
          chart: {
            series: {
              reviewed: 'Verificato dal dipendente',
              totalReviewed: 'Contatti valutati totali'
            },
            yAxis: 'Numero di valutazioni'
          }
        }
      }
    },
    tableReviewsWithScores: {
      table: {
        columns: {
          type: 'Un tipo',
          operatorId: 'Operatore',
          communicationKey: 'Numero di telefono / ID comunicazione',
          totalScore: 'Risultato',
          clientInteractionStartedAt: 'Data di comunicazione',
          reviewCreatedAt: 'Data di valutazione'
        }
      },
      exportError:
        'Stai tentando di scaricare più di 5000 valutazioni. Abbreviare il periodo per ridurre il numero di valutazioni esportate'
    },
    widgetMeta: {
      longSortMessage: "L'ordinamento in base ai criteri può richiedere molto tempo.",
      maxItemsCount: 'Il numero massimo di assegni non può essere superiore a 1000.',
      foundReviews: 'Controlli trovati: '
    }
  }
};
