import { SwapOutlined } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import { COLUMN_TYPES } from 'core/utils/constants';
import { get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

const defaultCustomFields = {};
// TODO: prefix
const ColumnTitle = ({ columnType, customFieldsByKeys = defaultCustomFields, tableView }) => {
  const { Text } = Typography;
  const { t } = useTranslation();

  const Icon = get(COLUMN_TYPES, `${columnType}.icon`, SwapOutlined);

  const columnTitle = get(
    COLUMN_TYPES,
    `${columnType}.name`,
    customFieldsByKeys[columnType]?.name || ''
  );

  return (
    <Row type="flex" align="middle" gutter={[4, 0]}>
      <Col>
        <Icon style={{ marginTop: 4 }} />
      </Col>
      <Col>
        <Text>
          {tableView
            ? t(get(COLUMN_TYPES, `${columnType}.columnName`, columnTitle))
            : t(columnTitle)}
        </Text>
      </Col>
    </Row>
  );
};

export default ColumnTitle;
