import { Input, message, Space, Upload } from 'antd';
import Axios from 'axios';
import BraftEditor from 'bf-editor-qolio';
import 'bf-editor-qolio/dist/index.css';
import Icon from 'components/Icon';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { API_V1_PATH } from 'core/api';
import { apiUrl } from 'core/config';
import { processJsonApiObject } from 'core/jsonapi';
import { handleError } from 'core/services/errors';
import { get, isEmpty, isFunction } from 'lodash';
import React, { useImperativeHandle, useState } from 'react';
import { Paperclip } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { uploadedFilesResource } from 'redux/resources/uploadedFiles';
import { getCurrentUser } from 'redux/selectors/users';
import { ConditionHideCommentCheckbox } from 'components/Comments/CommentsList/Comment/Components/ConditionaHideCommentCheckbox';
import { utils } from 'react-media-player';
import styled from 'styled-components';
import ClearOutlined from '@ant-design/icons/lib/icons/ClearOutlined';
import { LOCALES } from 'core/utils/constants';
import CommentFlags from 'components/Comments/Editor/Components/CommentFlags';
import UploadedFile from './UploadedFile';
import CommentTemplates from './Components/TemplateSelector/CommentTemplates';
import {
  setComments,
} from 'redux/ui/checklistEditor/reducer';

const CommentTimestamp = ({ playedSeconds }) => {
  return (
    <span>
      <span>
        <Trans i18nKey="components.timestamp" />
      </span>
      <Time value={utils.formatTime(playedSeconds)} disabled placeholder="00:00" />
    </span>
  );
};

const Time = styled(Input)`
  &.ant-input-disabled {
    width: 60px !important;
    margin: 0 0px 0 10px !important;
    background-color: white !important;
    color: #262626 !important;
  }
`;

const Editor = React.forwardRef(
  (
    {
      commentState,
      setCommentState,
      setEmp,
      showTemplates,
      showAttachments,
      stopPlaying,
      showFlags,
      allowHidden,
      allowSelectTimestamp,
      playedSeconds,
      isTimestampAdded,
      actionsComponent = null,
      id
    },
    ref
  ) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const preferedLocale = useSelector(state => getCurrentUser(state)['prefered-locale']);
    const [editorState, setEditorState] = useState(
      BraftEditor.createEditorState(commentState?.text)
    );
    const [uploadError, setUploadError] = useState(false);
    const comments = useSelector(state => state.uiChecklistEditor.comments);
    useImperativeHandle(ref, () => ({
      setEditorState: commentState =>
        setEditorState(BraftEditor.createEditorState(commentState?.text)),
      editorState
    }));

    const onDeleteFile = id => {
      setCommentState({
        ...commentState,
        uploadedFiles: commentState?.uploadedFiles.filter(({ id: fileId }) => fileId !== id),
        uploadedFilesIds: commentState.uploadedFilesIds
          ?.filter(fileId => fileId !== id)
          .map(({ id }) => id)
      });
    };

    const uploadHandler = async ({ file, onError, onProgress }) => {
      // TODO: upload file here
      const headers = {
        'access-token': localStorage.getItem('access-token'),
        client: localStorage.getItem('client'),
        uid: localStorage.getItem('uid'),
        'content-type': 'multipart/form-data'
      };
      const uploadEndpoint = `${apiUrl}${API_V1_PATH}/organization/uploaded_files`;
      const onUploadProgress = ({ total, loaded }) => {
        const percent = Math.round((loaded * 100) / total);
        onProgress({ percent });
      };
      const body = new FormData();
      body.append('file', file);
      body.append('name', file.name);
      const hide = message.loading(t('integrationsSettingsPage.manageUserAccounts.sendingFile'), 0);
      try {
        const response = await Axios.post(uploadEndpoint, body, { headers, onUploadProgress });
        hide();
        const uploadedFile = processJsonApiObject(response.data.data);
        dispatch(uploadedFilesResource.actions.loadByIdSucceed(uploadedFile));
        setCommentState({
          ...commentState,
          uploadedFiles: [...(commentState?.uploadedFiles || []), uploadedFile]
        });
      } catch (error) {
        console.log(error);
        hide();
        handleError(error?.response?.status, error?.response?.data);
        onError(error);
      }
    };

    const beforeUpload = file => {
      const isLt2M = file.size / 1024 / 1024 < 3;

      if (!isLt2M) {
        // message.error('Image must smaller than 30MB!');
        setUploadError('Image must smaller than 3MB!');
        // TODO: set error for file size
      }

      return isLt2M;
    };

    const extendControls = [
      {
        key: 'attachment',
        type: 'component',
        component: (
          <SRow display="inline-flex" width="100%">
            {showAttachments && (
              <SCol flex="none">
                <Upload
                  showUploadList={false}
                  accept="image/*"
                  beforeUpload={beforeUpload}
                  customRequest={uploadHandler}
                >
                  <button
                    type="button"
                    className="control-item button upload-button"
                    data-title={t('general.uploadImage')}
                  >
                    <Icon icon={Paperclip} />
                  </button>
                </Upload>
              </SCol>
            )}
            {showFlags && commentState?.commentType !== 'comment_reply' && (
              <SCol
                flex="auto"
                display="inline-grid"
                justifyContent="flex-end"
                alignItems="flex-start"
                paddingTop="5px"
              >
                <CommentFlags commentState={commentState} setCommentState={setCommentState} />
              </SCol>
            )}
          </SRow>
        )
      }
    ];

    const handleEditorChange = editorState => {
      const isEmptyText = isEmpty(editorState.toText().trim());
      setEditorState(editorState);
      if (!!setEmp) {
        setEmp(isEmptyText);
      }

      if (isFunction(setCommentState)) {
        setCommentState({ ...commentState, text: isEmptyText ? '' : editorState.toHTML() });
      }
    };

    const toogleHidden = () => {
      const { hidden: isHidden } = commentState;
      setCommentState({ ...commentState, hidden: !isHidden });
    };

    const handleFocusOut = editorState => {
      if (!!id) {
        dispatch(setComments({ [id] : editorState }))
      }
    }

    const uploadedFiles = get(commentState, 'uploadedFiles', []);
    let comment = editorState.getSelection().hasFocus ? editorState : comments.hasOwnProperty(id) ? comments[id] : "";
    // if (!comment && !editorState.getSelection().hasFocus && !comments.hasOwnProperty(id)) {
    //   console.log("comments1", editorState.toText());
    //   comment = editorState;
    // }
    if (!editorState.getSelection().hasFocus && editorState.toText()) {
      comment = editorState;
    }
    
    return (
      <>
        <SCol span={24}>
          {showTemplates && commentState?.commentType !== 'comment_reply' && (
            <SCol
              style={{
                width: '46px',
                position: 'absolute',
                left: '174px',
                top: '3px',
                zIndex: 2
              }}
            >
              <CommentTemplates
                commentState={commentState}
                setCommentState={setCommentState}
                setEditorState={setEditorState}
              />
            </SCol>
          )}
          <BraftEditor
            value={comment}
            placeholder={t('components.commentsPanel.commentPlaceholder')}
            language={preferedLocale === LOCALES.RU.value ? LOCALES.RU.value : LOCALES.EN.value}
            controls={['bold', 'link', 'emoji', { key: 'remove-styles', text: <ClearOutlined /> }]}
            stripPastedStyles
            onFocus={() => (stopPlaying ? dispatch(stopPlaying()) : null)}
            onChange={handleEditorChange}
            draftProps={{ spellCheck: true }}
            extendControls={extendControls}
            onBlur={handleFocusOut}
          />
        </SCol>

        {uploadedFiles?.length > 0 && (
          <SCol span={24}>
            <SRow type="flex" width="100%" paddingBottom="8px">
              {uploadedFiles?.map(file => (
                <UploadedFile
                  key={file?.id}
                  uploadedFile={file}
                  allFiles={commentState?.uploadedFiles}
                  onDelete={onDeleteFile}
                />
              ))}
            </SRow>
          </SCol>
        )}

        <SCol span={24}>
          <SRow type="flex" display="flex" justifyContent="space-between" gutter={[8, 8]}>
            <Space direction="horizontal" size={[8, 0]}>
              {allowHidden && (
                <SCol display="flex" flex="none" alignItems="center">
                  <ConditionHideCommentCheckbox
                    checked={commentState?.hidden}
                    onChange={toogleHidden}
                  >
                    {t('components.commentsPanel.buttons.hidden')}
                  </ConditionHideCommentCheckbox>
                </SCol>
              )}

              {allowSelectTimestamp && (
                <SCol flex="none">
                  <CommentTimestamp
                    playedSeconds={playedSeconds}
                    isTimestampAdded={isTimestampAdded}
                  />
                </SCol>
              )}
            </Space>

            {actionsComponent && (
              <SCol display="flex" flex="auto" justifyContent="end">
                <SRow
                  display="flex"
                  marginLeft="auto"
                  width="max-content"
                  justifyContent="end"
                  className="BraftEditor-actions"
                >
                  <Space value={4}>{actionsComponent}</Space>
                </SRow>
              </SCol>
            )}
          </SRow>
        </SCol>
      </>
    );
  }
);

export default React.memo(Editor);
