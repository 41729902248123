import { Button, Carousel, Col, Form, Input, Row, message, Select, Typography } from 'antd';
import carouselOne from 'assets/images/carousel/img1s.png';
import carouselTwo from 'assets/images/carousel/img2s.png';
import carouselThree from 'assets/images/carousel/img3s.png';
import carouselFour from 'assets/images/carousel/img4s.png';
import SCard from 'components/Standard/SCard';
import React, { useState } from 'react';
import parseUrl from 'url-parse';
import { Skeleton } from 'antd';
import api from './../../../core/api';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link, withRouter, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { AuthLogo, LinkToLandingPage } from '../styled';
import Checkbox from 'antd/lib/checkbox/Checkbox';

const { Text, Title } = Typography;

const RegisterForm = ({ loading }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const onFinishRegistration = async values => {
    const { first_name, last_name, email, password, website, phone, organization_name, demo, agreement } = values;
    const agree = !!agreement;
    const url = parseUrl(website);
    const domain = url.hostname;
    setLoading(true);
    if (!!!demo) {
      await api.noDataRegister({
        first_name: first_name, 
        last_name: last_name, 
        admin_email: email, 
        organization_name: organization_name,
        admin_password: password,
        agreement: agree,
        phone: phone
      });
    } else {
      await api.withDataRegister({
        first_name: first_name, 
        last_name: last_name, 
        organization_domain: domain,
        admin_email: email, 
        organization_name: organization_name,
        admin_password: password,
        agreement: agree,
        phone: phone
      });
    }
    
    onSuccess();

    setTimeout(() => {
      history.push('/login');
    }, 1000);
    
  }

  const onSuccess = () => {
    message.success(t('loginPage.form.messages.successReg'));
  };

  const isValidUrl = (url) => {
    const pattern = /^(?:https?:\/\/)?(?:www\.)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
    return pattern.test(url);
  };

  const isValidPhone = (str) => {
    const regex = /^\+?\d{6,15}$/;
    return regex.test(str);
  };
  
  return (
    !isLoading ? 
    <Form name="register" onFinish={onFinishRegistration}>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: t('loginPage.form.messages.enterEmail') },
          { type: 'email', message: t('loginPage.form.messages.wrongEmail') }
        ]}
        validateTrigger={['onBlur']}
      >
        <Input placeholder={t('loginPage.form.fields.emailPlaceholder')} type="email" />
      </Form.Item>

      <Form.Item
        name="phone"
        rules={[
          // {
          //   required: true,
          //   message: t('loginPage.form.messages.enterPhone'),
          // },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (isValidPhone(value)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('loginPage.form.messages.errorPhone')));
            },
          }),
        ]}
      >
        <Input
          placeholder={t('loginPage.form.fields.phonePlaceholder')}
          style={{
            width: '100%',
          }}
        />
      </Form.Item>

      <Form.Item
        name="first_name"
        rules={[
          { 
            required: true, 
            message: t('loginPage.form.messages.enterFirstName')
          },
          {
            whitespace: true,
            // message: 'Custom error message for whitespace validation',
          },
        ]}
      >
        <Input placeholder={t('loginPage.form.fields.firstNamePlaceholder')}/>
      </Form.Item>

      <Form.Item
        name="last_name"
        rules={[
          { 
            required: true, 
            message: t('loginPage.form.messages.enterLastName')
          },
          {
            whitespace: true,
            // message: 'Custom error message for whitespace validation',
          },
        ]}
      >
        <Input placeholder={t('loginPage.form.fields.lastNamePlaceholder')}/>
      </Form.Item>

      <Form.Item
        name="organization_name"
        rules={[
          { 
            required: true, 
            message: t('loginPage.form.messages.enterOrgName')
          },
          {
            whitespace: true,
            // message: 'Custom error message for whitespace validation',
          },
        ]}
      >
        <Input placeholder={t('loginPage.form.fields.orgNamePlaceholder')}/>
      </Form.Item>

      <Form.Item
        name="website"
        rules={[
          // { 
          //   required: true, 
          //   message: t('loginPage.form.messages.enterWebsite')
          // },
          {
            whitespace: true,
            // message: 'Custom error message for whitespace validation',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (isValidUrl(value)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('loginPage.form.messages.errorUrl')));
            },
          }),
        ]}
        validateTrigger={['onBlur']}
      >
        <Input placeholder={t('loginPage.form.fields.WebsitePlaceholder')}/>
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          { required: true, message: t('loginPage.form.messages.enterPassword') },
          { min: 6, message: t('loginPage.form.messages.minLengthPassword') }
        ]}
        validateTrigger={['onBlur']}
      >
        <Input type="password" placeholder={t('loginPage.form.fields.passwordPlaceholder')} />
      </Form.Item>
      <Form.Item
        name="password_confirmation"
        dependencies={['password']}
        rules={[
          { required: true, message: t('loginPage.form.messages.confirmPassword') },
          { min: 6, message: t('loginPage.form.messages.minLengthPassword') },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The new password that you entered do not match!'));
            },
          }),
        ]}
        validateTrigger={['onBlur']}
      >
        <Input type="password" placeholder={t('loginPage.form.fields.enterConfimationPasswordPlaceholder')} />
      </Form.Item>

      <Form.Item
        style={{ marginBottom: '-4px' }}
        name="demo"
        valuePropName="checked"
      >
        <Checkbox>
          {t('loginPage.form.fields.demoPlaceholder')}
        </Checkbox>
      </Form.Item>

      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error(t('loginPage.form.messages.shouldAccept'))),
          },
        ]}
      >
        <Checkbox>
          {t('loginPage.form.messages.agreeWith')} <a href="https://qolio.io/offer" target="_blank">{t('loginPage.form.messages.offer')}</a> {t('loginPage.form.messages.and')} <a href="https://qolio.io/privacy_policy" target="_blank">{t('loginPage.form.messages.privacy')}</a>
        </Checkbox>
      </Form.Item>

      <Form.Item>
        <Row gutter={[8, 8]} style={{ margin: '-4px' }}>
          <Col span={24}>
            <Button type="primary" htmlType="submit" block onFinish={onFinishRegistration} loading={loading}>
              {t('loginPage.form.buttons.register')}
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form> :
    <Skeleton active />
  );
};


const FormContainer = styled(Col)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-card {
      width: 80%;
      max-width: 560px;
    }
  }
`;

const SlideImage = styled.img`
  margin: 0 auto;
  display: block;
  max-width: 100%;
  height: 50vh;
`;

const sharedTextStyle = css`
  width: 100%;
  text-align: center;
  display: block;
`;

const SlideText = styled(Text)`
  ${sharedTextStyle}
`;

const SlideTitle = styled(Title)`
  ${sharedTextStyle}
`;

const StyledCarousel = styled(Carousel)`
  && {
    .slick-dots.slick-dots-bottom {
      bottom: -8px;

      li {
        opacity: 0.5;
        button {
          background: 'var(--blue_6)';
        }

        &.slick-active {
          opacity: 1;
        }
      }
    }
  }
`;

const LoginPage = ({ loading }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();


  return (
    <>
      <Helmet>
        <title>{t('pagesMeta.registerPage.title')}</title>
      </Helmet>
      <AuthLogo />
      <Col span={12}>
        <Row
          type="flex"
          gutter={[32, 32]}
          align="middle"
          justify="center"
          style={{ height: '100%' }}
        >
          <Col span={20}>
            <StyledCarousel autoplay dotsClass="blue-dots">
              <Row type="flex" align="middle" gutter={[16, 16]}>
                <Col span={24}>
                  <SlideTitle>{t('registerPage.layout.slides.efficiency.title')}</SlideTitle>
                </Col>
                <Col span={24}>
                  <SlideImage src={carouselOne} />
                </Col>
                <Col span={24}>
                  <SlideText strong>
                    {t('registerPage.layout.slides.efficiency.description')}
                  </SlideText>
                </Col>
              </Row>
              <Row type="flex" align="middle" gutter={[16, 16]}>
                <Col span={24}>
                  <SlideTitle>{t('registerPage.layout.slides.motivation.title')}</SlideTitle>
                </Col>
                <Col span={24}>
                  <SlideImage src={carouselTwo} />
                </Col>
                <Col span={24}>
                  <SlideText strong>
                    {t('registerPage.layout.slides.motivation.description')}
                  </SlideText>
                </Col>
              </Row>
              <Row type="flex" align="middle" gutter={[16, 16]}>
                <Col span={24}>
                  <SlideTitle>{t('registerPage.layout.slides.simplicity.title')}</SlideTitle>
                </Col>
                <Col span={24}>
                  <SlideImage src={carouselThree} />
                </Col>
                <Col span={24}>
                  <SlideText strong>
                    {t('registerPage.layout.slides.simplicity.description')}
                  </SlideText>
                </Col>
              </Row>
              <Row type="flex" align="middle" gutter={[16, 16]}>
                <Col span={24}>
                  <SlideTitle>{t('registerPage.layout.slides.analytics.title')}</SlideTitle>
                </Col>
                <Col span={24}>
                  <SlideImage src={carouselFour} />
                </Col>
                <Col span={24}>
                  <SlideText strong>
                    {t('registerPage.layout.slides.analytics.description')}
                  </SlideText>
                </Col>
              </Row>
            </StyledCarousel>
          </Col>
        </Row>
      </Col>

      <FormContainer span={12}>
        <SCard shadowed>
          <Row justify="space-between" style={{ marginBottom: '10px' }} type="flex" align="middle">
            <Col>
              <Title level={3}>{t('registerPage.form.title')}</Title>
            </Col>
            <Col>
              <Link to="/login">{t('registerPage.form.buttons.login')}</Link>
            </Col>
          </Row>
          <RegisterForm />
        </SCard>
      </FormContainer>
      <LinkToLandingPage />
    </>
  );
};

export default withRouter(LoginPage);
