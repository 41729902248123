import React, { useState } from 'react';
import { Modal, Button, Tooltip } from 'antd';
import STable from '../../components/Standard/STable';
import SText from '../../components/Standard/SText';
import ClientInteractionLink from '../../components/ClientInteractionLink';

const ModalChecklistDefinitions = ({ isModalOpen, setIsModalOpen }) => {
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10
  });
  const data = [];
  for (let i = 0; i < 10; i++) {
    data.push({
      date: '02/06/2023, 15:41',
      phone: '564646546456',
      direction: 'Исходящий',
      duration: '1:41'
    });
  }
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [conditionInputs, setConditionInputs] = useState(['']);

  const handleAddCondition = () => {
    setConditionInputs([...conditionInputs, '']);
  };

  const handleDeleteCondition = index => {
    const newInputs = [...conditionInputs];
    newInputs.splice(index, 1);
    setConditionInputs(newInputs);
  };

  const onPaginationChange = (page, pageSize) => {
    setPagination({ ...pagination, current: page, pageSize });
  };

  const onPageSizeChange = newPageSize => {
    setPagination({ ...pagination, pageSize: newPageSize, current: 1 });
  };
  const tableConfig = {
    columns: [
      {
        title: '',
        dataIndex: 'call',
        key: 'call',
        fixed: 'left',
        width: 30,
        render: () => (
          <ClientInteractionLink id={data.date} communication={data} useDrawer={false} />
        )
      },
      {
        title: 'Дата и время',
        dataIndex: 'date',
        key: 'date',
        fixed: 'left',
        render: name => {
          return (
            <Tooltip title={name} placement="topLeft">
              <SText>02/06/2023, 15:41</SText>
            </Tooltip>
          );
        }
      },
      {
        title: 'Номер телефона',
        dataIndex: 'phone',
        key: 'phone',
        align: 'center',
        render: name => {
          return (
            <Tooltip title={name} placement="topLeft">
              <SText>775464564566</SText>
            </Tooltip>
          );
        }
      },
      {
        title: 'Направление',
        dataIndex: 'direction',
        key: 'direction',
        align: 'center',
        render: name => {
          return (
            <Tooltip title={name} placement="topLeft">
              <SText>Исходящий</SText>
            </Tooltip>
          );
        }
      },
      {
        title: 'Прод-ть',
        dataIndex: 'duration',
        key: 'duration',
        align: 'center',
        render: name => {
          return (
            <Tooltip title={name} placement="topLeft">
              <SText>1:41</SText>
            </Tooltip>
          );
        }
      }
    ]
  };

  return (
    <Modal
      title="Список коммуникаций"
      centered
      visible={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      width={755}
    >
      <STable
        dataSource={data}
        rowKey="id"
        size="small"
        loading={!isModalOpen}
        columns={tableConfig.columns}
        border
        current={pagination.current}
        total={pagination.total * pagination.pageSize}
        pageSize={pagination.pageSize}
        onChange={onPaginationChange}
        showSizeChanger
        onShowSizeChange={onPaginationChange}
        pageSizeOptions={['10', '25', '50']}
      />
      <Button
        key="export"
        type="primary"
        style={{ float: 'left', top: '-28px' }}
        onClick={handleOk}
      >
        Экспортировать
      </Button>
    </Modal>
  );
};

export default ModalChecklistDefinitions;
