import TextCommunicationContent from 'components/TextCommunicationContent';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setOperatorId } from 'redux/ui/clientInteractionPage/reducer';
import { textCommunicationsResource } from 'redux/resources/textCommunications';
import ClientInteractionPage from './ClientInteractionPage';

const TextCommunicationPage = ({
  loadTextCommunication,
  id,
  loading,
  textCommunication,
  offsetTop,
  shouldLoad = true,
  fromDrawer = false
}) => {
  useEffect(() => {
    loadTextCommunication({ id, include: 'operator.unit,text_communication_parts.operator' });
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('pagesMeta.textCommunicationPage.title')}</title>
      </Helmet>
      <ClientInteractionPage
        Content={TextCommunicationContent}
        id={id}
        clientInteraction={textCommunication}
        loading={loading}
        offsetTop={offsetTop}
        shouldLoad={shouldLoad}
        fromDrawer={fromDrawer}
      />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.id || ownProps?.match?.params?.id;

  const { byIds, loading, loadByIdStarted } = state.textCommunicationsResource;
  const textCommunication = byIds[id];

  if (loading || loadByIdStarted || isEmpty(textCommunication) || textCommunication.loading)
    return { loading: true, id };

  return {
    id,
    textCommunication
  };
};

const mapDispatchToProps = {
  loadTextCommunication: textCommunicationsResource.operations.loadById,
  setOperatorId
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TextCommunicationPage));
