import { Copy, Trash2, ChevronDown, Inbox, Settings } from 'react-feather';
import {
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Menu,
  message,
  Modal,
  Row,
  Skeleton,
  Tooltip,
  Typography
} from 'antd';
import ChecklistDefinitionStatusTag from 'components/Checklist/ChecklistDefinitionStatusTag';
import ColorZones from 'components/ColorZones';
import Icon from 'components/Icon';
import {
  CHECKLIST_DEFINITION_STATUS,
  CLIENT_INTERACTIONS_TYPES_LITERALS,
  RATING_CALCULATION,
  RATING_CALCULATION_LITERAL,
  RATING_METHOD,
  RATING_METHOD_LITERAL,
  RATING_MODE,
  SCALE_TYPES
} from 'core/utils/constants';
import { calculateQuestions } from 'core/utils/ratingsCalculations';
import truncateString from 'core/utils/truncateString';
import { find, get, isEmpty, max, min as getMin, minBy, some, subtract, sumBy } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import {
  getChecklistDefinitionBindings,
  getChecklistDefinitionQuestionGroups,
  getChecklistDefinitionQuestions
} from 'redux/selectors/checklistItems/checklistItems';
import { setEditingColorZonesBeforePublish } from 'redux/ui/checklistEditor/reducer';
import { setEditingQuestionWithBinding } from 'redux/ui/questionModal/reducer';
import { InfoCircleOutlined } from '@ant-design/icons';
import { IconButton } from 'components/Buttons';
import SCard from 'components/Standard/SCard';
import { ChecklistInfoText, MarginLeftButton, ValuesContainer } from './styled';

const ChecklistInfo = ({
  currentChecklistQuestions,
  setEditingColorZonesBeforePublish,
  editingColorZonesBeforePublish,
  sumRates,
  sumPercentage,
  currentChecklist,
  unitsByIds,
  minimalRate,
  history,
  deleteChecklist,
  updateChecklist,
  copyChecklistDefinition,
  loadingChecklist
}) => {
  const { t } = useTranslation();
  const id = get(currentChecklist, 'id', '');
  const name = get(currentChecklist, 'name', t('checklistsPage.checklistInfo.emptyName'));
  const ratingMethod = get(
    currentChecklist,
    'ratingMethod',
    t('checklistsPage.checklistInfo.emptyName')
  );
  const ratingMode = get(currentChecklist, 'ratingMode', RATING_MODE.NUMBERS);
  const ratingCalculation = get(
    currentChecklist,
    'ratingCalculation',
    t('checklistsPage.checklistInfo.emptyName')
  );
  const communicationTypes = get(currentChecklist, 'communicationTypes', []);
  const scaleType = get(currentChecklist, 'scaleType', '');
  const unitsIds = get(currentChecklist, 'unitsIds', []);
  const status = get(currentChecklist, 'status', '');
  const { confirm } = Modal;

  const maxRatingValue = sumRates;

  const minRatingValue = minimalRate;

  const updateCurrentChecklist = async ({ status, colorZones, message }) => {
    const updatedChecklist = await updateChecklist({
      id,
      name,
      status,
      unitsIds,
      colorZones
    });

    if (updatedChecklist && updatedChecklist.type === 'checklist-definitions') {
      setEditingColorZonesBeforePublish({});
      Modal.destroyAll();
      history.push('/checklists');
      message.success(message);
    }
  };

  const createCopy = async ({ id, name }) => {
    try {
      const result = await copyChecklistDefinition({ id, name });
      if (result.type === 'checklist-definitions') {
        history.push(`/checklist-editor/${result.id}`);
        message.success(t('checklistsPage.checklistInfo.messages.copySuccessfullyCreated'));
      }
    } catch (error) {
      console.log(error);
      message.error(t('checklistsPage.checklistInfo.messages.createCopyfailed'));
    }
  };

  const handleMenuClick = e => {
    switch (e.key) {
      case 'settings':
        return history.push(`/checklist-settings/${id}`);

      case 'copy':
        return createCopy({
          id,
          name: `${name} ${t('checklistsPage.checklistInfo.copy')}`
        });

      case 'archive':
        return confirm({
          okText: t('checklistsPage.checklistInfo.confirmArchive.ok'),
          cancelText: t('checklistsPage.checklistInfo.confirmArchive.cancel'),
          title: t('checklistsPage.checklistInfo.confirmArchive.title'),
          content: t('checklistsPage.checklistInfo.confirmArchive.description'),
          onOk: () =>
            updateCurrentChecklist({
              status: CHECKLIST_DEFINITION_STATUS.ARCHIVED.value,
              message: t('checklistsPage.checklistInfo.messages.checklistSuccessfullyArchived')
            })
        });

      case 'delete':
        return confirm({
          okText: t('checklistsPage.checklistInfo.confirmDelete.ok'),
          cancelText: t('checklistsPage.checklistInfo.confirmDelete.cancel'),
          title: t('checklistsPage.checklistInfo.confirmDelete.title'),
          okType: 'danger',
          content: t('checklistsPage.checklistInfo.confirmDelete.description'),
          onOk: async () => {
            const deletedChecklist = await deleteChecklist({ id });
            if (deletedChecklist) {
              history.push('/checklists');
              message.success(
                t('checklistsPage.checklistInfo.messages.checklistSuccessfullyDeleted')
              );
            }
          }
        });

      default:
        console.log();
    }
  };

  const onPublish = async () => {
    if (ratingMethod === RATING_METHOD.WEIGHTED && sumPercentage < 100)
      return message.warning(t('checklistsPage.checklistInfo.messages.completedQuestionsSumm'));

    if (isEmpty(currentChecklistQuestions)) {
      return message.warning(t('checklistsPage.checklistInfo.messages.emptyChecklistQuestions'));
    }

    if (
      (scaleType === SCALE_TYPES.custom.type ||
        scaleType === SCALE_TYPES.custom_binary.type ||
        ratingCalculation === RATING_CALCULATION.SUM) &&
      ratingMode === RATING_MODE.NUMBERS
    ) {
      return setEditingColorZonesBeforePublish({
        checklistDefinitionId: id,
        colorZones:
          get(currentChecklist, 'colorZones', SCALE_TYPES[scaleType].colorZones) ||
          SCALE_TYPES.max_5.colorZones
      });
    }

    return Modal.confirm({
      title: t('checklistsPage.checklistInfo.confirmPublish.title'),
      content: t('checklistsPage.checklistInfo.confirmPublish.description'),
      onOk: async () =>
        updateCurrentChecklist({
          status: CHECKLIST_DEFINITION_STATUS.PUBLISHED.value,
          message: t('checklistsPage.checklistInfo.messages.checklistSuccessfullyPublished')
        }),
      okText: t('checklistsPage.checklistInfo.confirmPublish.ok'),
      cancelText: t('checklistsPage.checklistInfo.confirmPublish.cancel')
    });
  };

  const submitFromColorZonesModal = colorZones => {
    Modal.destroyAll();
    return Modal.confirm({
      title: t('checklistsPage.checklistInfo.confirmPublish.title'),
      content: t('checklistsPage.checklistInfo.confirmPublish.description'),
      onOk: async () => {
        await updateCurrentChecklist({
          status: CHECKLIST_DEFINITION_STATUS.PUBLISHED.value,
          colorZones,
          message: t('checklistsPage.checklistInfo.messages.checklistSuccessfullyPublished')
        });
        setEditingColorZonesBeforePublish({});
      },
      okText: t('checklistsPage.checklistInfo.confirmPublish.ok'),
      cancelText: t('checklistsPage.checklistInfo.confirmPublish.cancel')
    });
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="settings">
        <Icon icon={Settings} />
        <span>{t('checklistsPage.checklistInfo.menu.setting')}</span>
      </Menu.Item>
      {status !== CHECKLIST_DEFINITION_STATUS.ARCHIVED.value && (
        <Menu.Item key="copy">
          <Icon icon={Copy} />
          <span>{t('checklistsPage.checklistInfo.menu.copy')}</span>
        </Menu.Item>
      )}
      {status !== CHECKLIST_DEFINITION_STATUS.ARCHIVED.value && (
        <Menu.Item key="archive">
          <Icon icon={Inbox} />
          <span>{t('checklistsPage.checklistInfo.menu.archive')}</span>
        </Menu.Item>
      )}
      {status === CHECKLIST_DEFINITION_STATUS.DRAFT.value && (
        <Menu.Item key="delete" style={{ color: 'var(--red_primary)' }}>
          <Icon icon={Trash2} />
          <span>{t('checklistsPage.checklistInfo.menu.delete')}</span>
        </Menu.Item>
      )}
    </Menu>
  );

  const { Title, Text } = Typography;

  const unitsNames = unitsIds.map(unitid => `${get(unitsByIds, `${unitid}.name`, '')}`).join(', ');

  const range = subtract(maxRatingValue, minRatingValue);
  const binary = maxRatingValue === minRatingValue || range === 1 || range === -1;

  return (
    <SCard rounded={false} bordered="4px">
      <Skeleton active loading={loadingChecklist}>
        <Row type="flex" align="middle" justify="space-between">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/checklists">
                  {t('checklistsPage.checklistInfo.breadcrumbs.checklists')}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/checklist-settings/${currentChecklist.id}`}>
                  {t('checklistsPage.checklistInfo.breadcrumbs.checklistSettings')}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/checklist-editor/${currentChecklist.id}`}>
                  {t('checklistsPage.checklistInfo.breadcrumbs.checklistQuestions')}
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button>
                <Icon icon={ChevronDown} />
                {t('checklistsPage.checklistInfo.buttons.actions')}
              </Button>
            </Dropdown>

            {status !== CHECKLIST_DEFINITION_STATUS.PUBLISHED.value && (
              <MarginLeftButton type="primary" onClick={onPublish}>
                {t('checklistsPage.checklistInfo.buttons.publish')}
              </MarginLeftButton>
            )}
          </Col>
        </Row>
        <Row type="flex" align="middle" justify="start" gutter={[8, 8]} style={{ margin: '-4px' }}>
          <Col>
            <Title level={4} style={{ margin: 0 }}>
              {name}
            </Title>
          </Col>
          <Col>
            <ChecklistDefinitionStatusTag status={status} />
          </Col>
        </Row>
        <Row type="flex" justify="start" gutter={[8, 8]} style={{ margin: '-4px' }}>
          <Col lg={14} xl={16} xxl={16}>
            <Col lg={12} xl={11} xxl={8}>
              <ChecklistInfoText>
                <Text strong>{t('checklistsPage.checklistInfo.items.ratingMethod')}</Text>
                <Text>{t(RATING_METHOD_LITERAL[ratingMethod])}</Text>
              </ChecklistInfoText>

              <ChecklistInfoText>
                <Text strong>{t('checklistsPage.checklistInfo.items.ratingCalculation')}</Text>
                <Text>{t(RATING_CALCULATION_LITERAL[ratingCalculation])}</Text>
              </ChecklistInfoText>

              <ChecklistInfoText>
                <Text strong>{t('checklistsPage.checklistInfo.items.scaleType')}</Text>
                {scaleType && <Text>{t(find(SCALE_TYPES, { type: scaleType }).name)}</Text>}
              </ChecklistInfoText>
            </Col>

            <Col lg={12} xl={13} xxl={16}>
              <ChecklistInfoText>
                <Text strong>{t('checklistsPage.checklistInfo.items.communicationTypes')}</Text>
                <Text>
                  {communicationTypes
                    .map(communicationType =>
                      t(CLIENT_INTERACTIONS_TYPES_LITERALS[communicationType])
                    )
                    .join(', ')}
                </Text>
              </ChecklistInfoText>

              <ChecklistInfoText>
                <Text strong>{t('checklistsPage.checklistInfo.items.units')}</Text>

                <Tooltip title={unitsNames}>
                  <Text>{truncateString(unitsNames, 100)}</Text>
                </Tooltip>
              </ChecklistInfoText>
            </Col>
          </Col>

          <ValuesContainer lg={10} xl={8} xxl={8} width="100%" justify="end">
            <Col>
              <Row type="flex">
                <IconButton
                  tooltip={{
                    title: t('checklistsPage.checklistInfo.items.totalChecklistScore')
                  }}
                  button={{
                    icon: <Icon icon={InfoCircleOutlined} />,
                    size: 'icon'
                  }}
                />
                <Text>{t('checklistsPage.checklistInfo.items.checklistScore')}</Text>
                <Text strong>
                  &nbsp;
                  {sumRates}
                </Text>
              </Row>
            </Col>
            {ratingMethod === RATING_METHOD.WEIGHTED && (
              <Col>
                <Row type="flex">
                  <IconButton
                    tooltip={{
                      title: t('checklistsPage.checklistInfo.items.totalSumPercentage')
                    }}
                    button={{
                      icon: <Icon icon={InfoCircleOutlined} />,
                      size: 'icon'
                    }}
                  />
                  <Text strong>
                    &nbsp;
                    {`${sumPercentage}%`}
                  </Text>
                </Row>
              </Col>
            )}
          </ValuesContainer>
        </Row>
      </Skeleton>
      <Modal
        destroyOnClose
        visible={!isEmpty(editingColorZonesBeforePublish)}
        width={980}
        title={t('checklistsPage.checklistInfo.modal.title')}
        okText={t('checklistsPage.checklistInfo.modal.ok')}
        cancelText={t('checklistsPage.checklistInfo.modal.cancel')}
        onCancel={() => setEditingColorZonesBeforePublish({})}
        onOk={() => submitFromColorZonesModal(editingColorZonesBeforePublish.colorZones)}
      >
        <ColorZones
          max={maxRatingValue}
          min={minRatingValue}
          onChange={colorZones =>
            setEditingColorZonesBeforePublish({ ...editingColorZonesBeforePublish, colorZones })
          }
          binary={binary}
          scaleType={scaleType}
          colorZones={
            editingColorZonesBeforePublish.colorZones || get(SCALE_TYPES, [scaleType, 'colorZones'])
          }
          col={{ span: 24 }}
        />
      </Modal>
    </SCard>
  );
};

const mapStateToProps = state => {
  const {
    currentChecklist,
    addingQuestionGroup,
    editingColorZonesBeforePublish
  } = state.uiChecklistEditor;
  const { ratingCalculation, ratingMethod } = currentChecklist;

  const currentChecklistBindings = getChecklistDefinitionBindings(state, currentChecklist);
  const currentChecklistQuestionGroups = getChecklistDefinitionQuestionGroups(
    state,
    currentChecklist
  );
  const currentChecklistQuestions = getChecklistDefinitionQuestions(state, currentChecklist);

  const allRatingValues = [...currentChecklistQuestions].map(question => ({
    ...question,
    value: max(get(question, 'ratingValues', [])),
    percentage: get(find(currentChecklistBindings, { questionId: question.id }), 'percentage', 0)
  }));

  const sumRates = calculateQuestions({
    ratingCalculation,
    ratingMethod,
    questions: allRatingValues
  });

  const sumPercentage = sumBy(currentChecklistBindings, 'percentage');

  const minimalRate = get(minBy(allRatingValues, 'min'), 'min', 1);

  // * calc min
  const minFromAllRatingValues = some(
    allRatingValues,
    ({ binding }) => binding?.questionGroup?.percentageCalculationStartingWithZero
  )
    ? 0
    : calculateQuestions({
        trueResult: true,
        max,
        ratingMethod,
        ratingCalculation,
        questions: allRatingValues.map(({ ratingValues, ...question }) => ({
          ...question,
          value: getMin(ratingValues)
        }))
      });

  const onlyPositiveScores = get(currentChecklist, 'onlyPositiveScores', false);
  const minimalRateValue = onlyPositiveScores ? 0 : getMin([minimalRate, minFromAllRatingValues]);

  return {
    minimalRate: minimalRateValue,
    currentChecklistQuestions,
    editingColorZonesBeforePublish,
    sumRates,
    sumPercentage,
    unitsByIds: state.unitsResource.byIds,
    currentChecklistQuestionGroups,
    addingQuestionGroup
  };
};

const mapDispatchToProps = {
  setEditingQuestionWithBinding,
  setEditingColorZonesBeforePublish,
  deleteChecklist: checklistDefinitionsResource.operations.deleteById,
  updateChecklist: checklistDefinitionsResource.operations.updateById,
  copyChecklistDefinition: checklistDefinitionsResource.operations.copyById
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistInfo));
