import { LinkOutlined } from '@ant-design/icons';
import { message, Tooltip } from 'antd';
import React from 'react';
import IconButton from 'components/IconButton';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';

const CopyLinkButton = ({
  reviewId,
  destinationId,
  destinationType,
  iconStyle = {},
  icon = null
}) => {
  const { t } = useTranslation();
  const pageLink = reviewId
    ? `${window.location.origin}/reviews/${reviewId}`
    : `${window.location.origin}/${destinationType}/${destinationId}`;

  const onCopy = () => message.success(t('components.recordPlayer.copyLinkSuccess'));
  const IconComponent = icon || LinkOutlined;

  return (
    <CopyToClipboard text={pageLink} onCopy={onCopy}>
      <Tooltip title={t('components.recordPlayer.copyLink')} placement="top">
        <IconButton icon={<Icon icon={IconComponent} {...iconStyle} />} size="small" />
      </Tooltip>
    </CopyToClipboard>
  );
};

export default CopyLinkButton;
