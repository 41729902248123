import { Modal, Skeleton } from 'antd';
import { CommentsModal } from 'components/Comments/CommentsModal';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import { CHECKLIST_MANAGER_STATES, PERMISSIONS } from 'core/utils/constants';
import { get, isEmpty, pickBy } from 'lodash';
import qs from 'qs';
import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import { createChecklistFromDefinition } from 'redux/ui/checklistManager/operations';
import {
  setAddingCommentsToQuestionId,
  setChecklistManagerState
} from 'redux/ui/checklistManager/reducer';

import SCard from 'components/Standard/SCard';
import QuestionDescriptionModal from './components/QuestionDescriptionModal';
import SelectChecklist from './SelectChecklist';
import ChecklistBody from './components/ChecklistBody';

const loadChecklistDefinitions = checklistDefinitionsResource.operations.loadWithInclude;

const ChecklistManager = ({
  reviewId,
  customCommunicationView = false,
  communication,
  onSubmit,
  onCommentSave,
  onDeleteComment,
  onUpdateComment,
  allowCommenting,
  comments,
  history,
  addingComment
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    checklistManagerState,
    currentChecklist: uiCurrentChecklist,
    loading: uiChecklistManagerLoading,
    addingCommentsToQuestionId
  } = useSelector(state => state.uiChecklistManager);
  const { editingCommentId } = useSelector(state => state.uiClientInteractionPage);
  const { loading: checklistDefinitionsResourceLoading } = useSelector(
    state => state.checklistDefinitionsResource
  );
  const review = useSelector(state => state.reviewsResource.byIds[reviewId]);

  const currentChecklist = isEmpty(uiCurrentChecklist) ? undefined : uiCurrentChecklist;
  const loading = uiChecklistManagerLoading || checklistDefinitionsResourceLoading;

  const initialLoad = async () => {
    const response = await dispatch(
      loadChecklistDefinitions({
        pagination: false,
        sort: 'created_at',
        filters: { status: 'published' }
      })
    );

    const { search } = history.location;

    if (search) {
      const { checklistDefinitionId } = qs.parse(search, { ignoreQueryPrefix: true });

      if (checklistDefinitionId && get(response, checklistDefinitionId) && !reviewId) {
        dispatch(createChecklistFromDefinition({ currentChecklist, checklistDefinitionId }));
      }
    }
  };

  useEffect(() => {
    if (checklistManagerState === CHECKLIST_MANAGER_STATES.INITIAL && !review) initialLoad();
  }, []);

  const onAutoFail = useCallback(() => {
    return Modal.confirm({
      title: t('components.checklistManager.autoFailModal.title'),
      content: t('components.checklistManager.autoFailModal.description'),
      onOk: onSubmit,
      okText: t('components.checklistManager.autoFailModal.ok'),
      cancelText: t('components.checklistManager.autoFailModal.cancel')
    });
  }, [onSubmit]);

  const handleCommentSave = useCallback(
    comment => {
      dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.EDITING));

      onCommentSave({
        ...comment,
        metadata: { ...(comment.metadata || {}), questionId: addingCommentsToQuestionId }
      });
    },
    [dispatch, addingCommentsToQuestionId]
  );

  if (loading)
    return (
      <SCard bordered shadowed loading>
        <Skeleton active />
      </SCard>
    );

  if (!loading && !currentChecklist && !customCommunicationView) {
    const ConditionalSelectChecklist = withConditionalRender(
      { allowedPermissions: [PERMISSIONS.CAN_MAKE_REVIEW] },
      SelectChecklist
    );

    return (
      <ConditionalSelectChecklist
        communication={communication}
        onSelect={({ checklistDefinitionId }) =>
          dispatch(createChecklistFromDefinition({ currentChecklist, checklistDefinitionId }))
        }
      />
    );
  }

  return (
    <>
      <ChecklistBody
        onAutoFail={onAutoFail}
        reviewId={reviewId}
        comments={comments}
        onDeleteComment={onDeleteComment}
        onUpdateComment={onUpdateComment}
        allowCommenting={allowCommenting}
        handleCommentSave={handleCommentSave}
        customCommunicationView={customCommunicationView}
        addingComment={addingComment}
      />
      <CommentsModal
        visible={!isEmpty(addingCommentsToQuestionId) && customCommunicationView}
        comments={pickBy(
          comments,
          comment => get(comment, 'metadata.questionId') === addingCommentsToQuestionId
        )}
        onCommentSave={handleCommentSave}
        onCancelComment={() => dispatch(setAddingCommentsToQuestionId(null))}
        onCancel={() => dispatch(setAddingCommentsToQuestionId(null))}
        onDeleteComment={onDeleteComment}
        onUpdateComment={onUpdateComment}
        allowCommenting={allowCommenting}
        editingCommentId={editingCommentId}
      />
      <QuestionDescriptionModal
        onAutoFail={onAutoFail}
        disabled={checklistManagerState === CHECKLIST_MANAGER_STATES.SAVED}
      />
    </>
  );
};

export default React.memo(withRouter(ChecklistManager), shallowEqual);
