export default {
  loginPage: {
    form: {
      title: 'Login',
      unRegistered: 'Not registered?',
      fields: {
        emailPlaceholder: 'Email',
        phonePlaceholder: 'Phone',
        passwordPlaceholder: 'Password',
        enterConfimationPasswordPlaceholder: 'Password confirmation',
        firstNamePlaceholder: 'First name',
        lastNamePlaceholder: 'Last name',
        orgNamePlaceholder: 'Organization name',
        WebsitePlaceholder: 'Website address',
        demoPlaceholder: 'Create with demo data'
      },
      messages: {
        agreeWith: 'I agree with ',
        and: ' and ',
        offer: 'public offer agreement',
        privacy: 'privacy policy',
        errorUrl: 'Incorrect url',
        errorPhone: 'Incorrect phone',
        errorReg: 'Registration failed! Please try again',
        successReg: 'You are registered!',
        enterEmail: 'Please enter email',
        enterPhone: 'Please enter phone',
        enterFirstName: 'Please enter first name',
        enterLastName: 'Please enter last name',
        shouldAccept: 'Should accept agreement',
        enterPassword: 'Please enter password',
        confirmPassword: 'Please confirm password',
        enterOrgName: 'Please enter organization name',
        enterWebsite: 'Please enter website address',
        wrongEmail: 'Wrong email format',
        minLengthPassword: 'Short password'
      },
      buttons: {
        login: 'Login',
        register: 'Register',
        forgotPassword: 'Forgot password',
        registration: 'Registration',
        goToSite: 'Go to website',
        registrationlink: 'Register'
      }
    },
    layout: {
      slides: {
        efficiency: {
          title: 'Efficiency',
          description:
            'All tools for quality control and evaluation of communications on one platform'
        },
        motivation: {
          title: 'Motivation',
          description: 'Involve, develop and motivate your team'
        },
        simplicity: {
          title: 'Simplicity',
          description:
            'Get rid of spreadsheets and outdated tools for evaluation and quality control'
        },
        analytics: {
          title: 'Analytics',
          description:
            'Analyze quality metrics and find new opportunities for the growth of your team'
        }
      }
    }
  }
};
