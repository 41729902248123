import { useSelections } from 'ahooks';
import { Button, Checkbox, Col, Modal, Row, Skeleton, Tooltip, Typography } from 'antd';
import Search from 'components/Inputs/Search';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import { SListItem } from 'components/Standard/SList';
import UserPreview from 'components/UserPreview';
import { intersection, isEmpty, isEqual, orderBy, reduce } from 'lodash';
import React, { useEffect, useState, useMemo, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FixedSizeList } from 'react-window';
import { operations, actions } from 'redux/lists/userAccountBindingsList';
import { userAccountBindingsResource } from 'redux/resources/userAccountBindings';
import { amocrmGetUsersStatusEndpoint } from '../../../../core/api';

const { Text } = Typography;

const ManageUsers = forwardRef(
  (
    {
      integrationId,
      integrationType,
      integrationInProcess = false,
      onOk,
      okText,
      okButtonProps = {}
    },
    ref
  ) => {
    const userAccountBindingsByIds = useSelector(
      state => state.userAccountBindingsResource.byIds,
      isEqual
    );
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [handlingOk, setHandlingOk] = useState(false);
    const [search, setSearch] = useState('');
    const [matchesAndCollisions, setMatchesAndCollisions] = useState({
      userAccountBindingsIdsWithCollisionsOnCreate: [],
      userAccountBindingsIdsWithMatches: []
    });
    const { ids } = useSelector(state => state.userAccountBindingsList, isEqual);

    const userAccounts = useMemo(() => {
      if (isEmpty(search)) {
        return ids.map(id => userAccountBindingsByIds[id]);
      }
      const filteredAccounts = ids
        .map(id => userAccountBindingsByIds[id])
        .filter(
          account =>
            (account.lastName + account.firstName || '')
              ?.toLowerCase()
              ?.indexOf(search.toLowerCase()) >= 0
        );
      return orderBy(filteredAccounts, ['desc']);
    }, [ids, search]);

    const dispatch = useDispatch();

    const [amoUserStatus, setAmoUserStatus] = useState(null);

    const getAmoUserStatus = async () => {
      if (integrationType === 'amocrm') {
        const response = await fetch(amocrmGetUsersStatusEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            integration_id: integrationId
          })
        });
        const data = await response.json();
        setAmoUserStatus(data);
        return data;
      }
    };

    useEffect(() => {
      getAmoUserStatus();
    }, []);
    const amoUserStatusData = [];
    if (amoUserStatus !== null) {
      // eslint-disable-next-line array-callback-return
      Object.values(amoUserStatus).map(amoUserStatusUbject => {
        // eslint-disable-next-line array-callback-return
        amoUserStatusUbject.map(item => {
          amoUserStatusData.push(item);
        });
      });
    }

    // Получаем статус пользователя в интеграции AmoCRM
    const getUserStatusFunc = userAccountId => {
      if (
        !userAccountId ||
        amoUserStatusData === null ||
        typeof amoUserStatusData === 'undefined'
      ) {
        return;
      }
      const foundUser = amoUserStatusData.find(item => item.id === userAccountId);
      // eslint-disable-next-line no-nested-ternary
      const userStatus = foundUser.active
        ? 'Активен'
        : !foundUser.active
        ? 'Неактивен'
        : 'Статус неизвестен';
      // const userStatus = foundUser ? (foundUser.active ? 'Активен' : !foundUser.active ? 'Неактивен' : 'Статус неизвестен') : 'Статус неизвестен';
      return (
        <Text className="truncated" style={{ color: userStatus === 'Активен' ? 'green' : 'red' }}>
          {userStatus}
        </Text>
      );
    };

    const {
      allSelected,
      selected,
      isSelected,
      toggle,
      partiallySelected,
      setSelected
    } = useSelections(ids, []);
    const [isAllSelected, setIsAllSelected] = useState(allSelected);

    const decideSelectedAccountsIds = (userAccounts, matchesAndCollisions, forceSelect = false) =>
      reduce(
        userAccounts,
        (acc, userAccount) => {
          // * if account already has user it automatically selected
          if (userAccount.userId) return [...acc, userAccount.id];

          // * if there is no user and collision then we cant accept this account
          if (
            matchesAndCollisions.userAccountBindingsIdsWithCollisionsOnCreate.includes(
              userAccount.id
            ) ||
            isEmpty(userAccount.email)
          ) {
            return acc;
          }

          // * all available accounts are preselected in integration in proccess mode cuz it's handy
          return integrationInProcess || forceSelect ? [...acc, userAccount.id] : acc;
        },
        []
      );

    const toggleAll = () => {
      setIsAllSelected(!isAllSelected);
      if (isAllSelected) {
        setSelected([]);
      } else {
        setSelected(decideSelectedAccountsIds(userAccounts, matchesAndCollisions, true));
      }
    };

    const loadAndPreselectUsers = async () => {
      setLoading(true);

      const matchesAndCollisions = await dispatch(
        userAccountBindingsResource.operations.getMatchesAndCollisions({ id: integrationId }, {})
      );

      setMatchesAndCollisions(matchesAndCollisions);

      const accountsByIds = await dispatch(
        operations.load({
          pagination: 'false',
          include: 'user.unit,user.role',
          filters: { integrationId }
        })
      );

      setSelected(decideSelectedAccountsIds(accountsByIds, matchesAndCollisions));

      setLoading(false);
    };

    useImperativeHandle(ref, () => ({
      load: loadAndPreselectUsers
    }));

    useEffect(() => {
      loadAndPreselectUsers();
      return () => dispatch(actions.clearList());
    }, []);

    const handleOk = async () => {
      setHandlingOk(true);
      try {
        const data = selected.map(id => ({ user_account_binding_id: id }));
        const matchedIds = intersection(
          selected,
          matchesAndCollisions.userAccountBindingsIdsWithMatches
        );

        if (!isEmpty(matchedIds)) {
          return Modal.info({
            title: t('general.users'),
            width: '600px',
            content: (
              <Row gutter={[16, 16]} style={{ margin: '-8px' }}>
                <Col span={24}>
                  <Text strong>
                    {`${t('integrationsSettingsPage.manageUserAccounts.usersAlreadyInSystem')}`}
                  </Text>
                </Col>
                {matchedIds.map(id => (
                  <Col span={24}>
                    <Text>{userAccountBindingsByIds[id].email}</Text>
                  </Col>
                ))}
                <Col span={24}>
                  <Text strong>
                    {`${t('integrationsSettingsPage.manageUserAccounts.accountToUsers')}`}
                  </Text>
                </Col>
              </Row>
            ),
            onOk: async () => {
              await dispatch(
                userAccountBindingsResource.operations.importSaas({ id: integrationId, data })
              );

              if (onOk) {
                await onOk(selected);
              }

              setHandlingOk(false);
            }
          });
        }

        await dispatch(
          userAccountBindingsResource.operations.importSaas({ id: integrationId, data })
        );

        if (onOk) {
          await onOk(selected);
        }
      } catch (error) {
        console.log({ error });
      }

      setHandlingOk(false);
    };

    const getUserAccountTooltip = userAccount => {
      if (isEmpty(userAccount.email))
        return t('integrationsSettingsPage.manageUserAccounts.brokenEmail');

      if (
        !matchesAndCollisions.userAccountBindingsIdsWithCollisionsOnCreate.includes(userAccount.id)
      )
        return undefined;

      return t('integrationsSettingsPage.manageUserAccounts.usersAlreadyInSystem');
    };

    const renderItem = (userAccount, style) => {
      return (
        <SListItem style={style} borderBottom="1px solid var(--gray-border)">
          <Col span={24} style={{ padding: '0' }}>
            <Row type="flex" justify="space-between" align="middle">
              <Col span={1}>
                <Tooltip title={getUserAccountTooltip(userAccount)}>
                  <Checkbox
                    disabled={
                      matchesAndCollisions?.userAccountBindingsIdsWithCollisionsOnCreate.includes(
                        userAccount.id
                      ) || isEmpty(userAccount.email)
                    }
                    checked={isSelected(userAccount.id)}
                    onClick={() => toggle(userAccount.id)}
                  />
                </Tooltip>
              </Col>
              <Col span={integrationType === 'amocrm' ? 5 : 9}>
                <UserPreview user={userAccount} showAvatar disabled />
              </Col>
              <SCol
                span={integrationType === 'amocrm' ? 5 : 7}
                display="flex"
                justifyContent="flex-start"
                flex="auto"
              >
                <Row type="flex" style={{ maxWidth: '100%' }}>
                  <Text className="truncated">{userAccount.email}</Text>
                </Row>
              </SCol>
              <SCol
                span={integrationType === 'amocrm' ? 6 : 7}
                display="flex"
                justifyContent="flex-start"
                flex="auto"
              >
                <Row type="flex" style={{ maxWidth: '100%' }}>
                  <Text className="truncated">{userAccount.integrationUid}</Text>
                </Row>
              </SCol>
              {integrationType === 'amocrm' && (
                <SCol span={7} display="flex" justifyContent="flex-start" flex="auto">
                  <Row type="flex" style={{ maxWidth: '100%' }}>
                    {getUserStatusFunc(userAccount.id)}
                  </Row>
                </SCol>
              )}
            </Row>
          </Col>
        </SListItem>
      );
    };

    if (loading) {
      return (
        <SCard>
          <Skeleton active />
        </SCard>
      );
    }

    return (
      <SCard>
        <Row gutter={[0, 16]} style={{ marginBottom: '-16px' }}>
          <Col span={24}>
            <Row align="middle" justify="space-between">
              <Col>
                <Row align="middle" gutter={[16, 0]}>
                  <Col>
                    <Search onSearch={setSearch} />
                  </Col>
                  <Col>
                    <Text type="secondary">
                      {`${t('integrationsSettingsPage.manageUserAccounts.totalUsers')}: `}
                    </Text>
                    <Text>{userAccounts.length}</Text>
                  </Col>
                  <Col>
                    <Checkbox
                      checked={isAllSelected}
                      onClick={toggleAll}
                      indeterminate={partiallySelected}
                    >
                      {t('integrationsSettingsPage.manageUserAccounts.selectAll')}
                    </Checkbox>
                  </Col>
                  <Col>
                    <Text type="secondary">{`${t('general.selected')}: ${selected.length}`}</Text>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button type="primary" loading={handlingOk} onClick={handleOk} {...okButtonProps}>
                  {isEmpty(okText) ? t('general.continue') : okText}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <SListItem borderBottom="1px solid var(--gray-border)">
              <Col span={24} style={{ padding: '0' }}>
                <Row type="flex" justify="space-between" align="middle">
                  <Col span={1} />
                  <Col span={integrationType === 'amocrm' ? 5 : 9}>
                    <Text strong>
                      {t('integrationsSettingsPage.manageUserAccounts.columns.user')}
                    </Text>
                  </Col>
                  <SCol
                    span={integrationType === 'amocrm' ? 5 : 7}
                    display="flex"
                    justifyContent="flex-start"
                    flex="auto"
                  >
                    <Text strong>
                      {t('integrationsSettingsPage.manageUserAccounts.columns.email')}
                    </Text>
                  </SCol>
                  <SCol
                    span={integrationType === 'amocrm' ? 6 : 7}
                    display="flex"
                    justifyContent="flex-start"
                    flex="auto"
                  >
                    <Text strong>
                      {t('integrationsSettingsPage.manageUserAccounts.columns.id')}
                    </Text>
                  </SCol>
                  {integrationType === 'amocrm' && (
                    <SCol span={7} display="flex" justifyContent="flex-start" flex="auto">
                      <Text strong>
                        {t('integrationsSettingsPage.manageUserAccounts.columns.status')}
                      </Text>
                    </SCol>
                  )}
                </Row>
              </Col>
            </SListItem>

            <FixedSizeList itemSize={56} itemCount={userAccounts.length} height={500}>
              {({ index, style }) => renderItem(userAccounts[index], style)}
            </FixedSizeList>
          </Col>
        </Row>
      </SCard>
    );
  }
);

export default ManageUsers;
