import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Upload, message, Button, Alert, Row, Col, Modal } from 'antd';
import styled from 'styled-components';
import { callUploadEndpoint, getImportExampleFileEndpoint, importFromFileEndpoint } from 'core/api';
import { processJsonApiObject } from 'core/jsonapi';
import axios from 'axios';
import { operations } from 'redux/lists/uploadedCallsListReducer';
import { phoneCallsResource } from 'redux/resources/calls';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Text from 'antd/lib/typography/Text';
import fileDownload from 'js-file-download';
import UploadedCallsTable from './UploadedCallsTable';

const maxSize = 30 * 1024 * 1024; // 30 MB

const UploadCallsPage = ({ onUploadCall, loadCalls, listPage }) => {
  const { t } = useTranslation();
  const [filesList, setFilesList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [contentModal, setContentModal] = useState(null);

  const handleBeforeUpload = file => {
    const fileExt = file.name
      .split('.')
      .pop()
      .toLowerCase();

    if (fileExt !== 'mp3' && fileExt !== 'wav' && fileExt !== 'csv') {
      message.error(
        `${t('uploadCallsPage.messages.fileFormatText')} '${file.name}' ${t(
          'uploadCallsPage.messages.fileFormatMessage'
        )}`,
        5
      );
      if (file.size >= maxSize) {
        message.error(
          `${t('uploadCallsPage.messages.maxFileText')} '${file.name}' ${t(
            'uploadCallsPage.messages.maxFileSize'
          )}`,
          5
        );
        return false;
      }
      return false;
    }
    if (file.size >= maxSize) {
      message.error(
        `${t('uploadCallsPage.messages.maxFileText')} '${file.name}' ${t(
          'uploadCallsPage.messages.maxFileSize'
        )}`,
        5
      );
      return false;
    }
    return true;
  };

  const handleChange = info => {
    const { fileList } = info;
    const validFilesList = [];

    fileList.map(file => {
      const fileExt = file.name
        .split('.')
        .pop()
        .toLowerCase();

      if (file.size <= maxSize) {
        if (fileExt === 'mp3' || fileExt === 'wav' || fileExt === 'csv') {
          validFilesList.push(file);
        }
      }
    });

    setFilesList(validFilesList);
  };

  const downloadFile = async () => {
    await fetch(getImportExampleFileEndpoint, { method: 'GET' })
      .then(response => response.text())
      .then(data => {
        fileDownload(data, 'example.csv');
      })
      .catch(error => {
        console.log(error);
        message.error(t('errorPages.internalServerError.title'));
      });
  };

  const customRequest = async ({ file, onSuccess, onError, onProgress }) => {
    const fileExtension = file.name.split('.').pop();
    const headers = {
      'access-token': localStorage.getItem('access-token'),
      client: localStorage.getItem('client'),
      uid: localStorage.getItem('uid'),
      'content-type': 'multipart/form-data'
    };

    const onUploadProgress = ({ total, loaded }) => {
      const percent = Math.round((loaded * 100) / total);
      onProgress({ percent });
    };

    const body = new FormData();
    body.append(fileExtension === 'csv' ? 'content' : 'record', file);
    try {
      const response = await axios.post(
        fileExtension === 'csv' ? importFromFileEndpoint : callUploadEndpoint,
        body,
        { headers, onUploadProgress }
      );
      fileExtension === 'csv'
        ? setVisible(true)
        : message.success(`${file.name} ${t('uploadCallsPage.messages.successfullyLoaded')}`);
      // Modal success import
      setContentModal(
        <Text>
          <p>{t('clientInteractionsPage.importModal.content')}</p>
        </Text>
      );
      const call = fileExtension === 'csv' ? '' : processJsonApiObject(response.data.data);
      onSuccess(response.data);
      onUploadCall(call);
      loadCalls({ page: listPage, include: 'operator' });
    } catch (error) {
      onError(error);
      console.log(error);
      if (error.message.includes('404')) {
        message.error(t('errorPages.internalServerError.title'));
        return;
      }
      const responseErrors = [];
      if (error.response && fileExtension === 'csv') {
        setVisible(true);
        const validateErrors = error.response.data.errors
          ? responseErrors.push(...error.response.data.errors)
          : responseErrors.push(error.response.data.error);
      }
      // Modal error or message error
      return fileExtension === 'csv'
        ? setContentModal
          (
            <Text>
              <p style={{ color: 'red' }}>
                {`${file.name} - ${t('clientInteractionsPage.importModal.errors.title')}`}
              </p>
              {responseErrors &&
                responseErrors.map(error => (
                  <>
                    {error}
                    <br />
                  </>
                ))}
            </Text>
          )
        : message.error(`${t('uploadCallsPage.messages.loadFailed')} ${file.name}`);
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('pagesMeta.uploadCallsPage.title')}</title>
      </Helmet>
      <Row type="flex" justify="start" gutter={[16, 16]}>
        <Col>
          <Upload
            showUploadList={{ showDownloadIcon: false, showRemoveIcon: false }}
            multiple
            onChange={handleChange}
            beforeUpload={handleBeforeUpload}
            customRequest={customRequest}
            fileList={filesList}
            name="file"
            accept=".mp3,.wav,.csv"
          >
            <Button type="primary">{t('uploadCallsPage.buttons.loadCalls')}</Button>
          </Upload>
        </Col>
        <Col>
          <Button onClick={downloadFile} type="primary">
            {t('uploadCallsPage.buttons.downloadExample')}
          </Button>
        </Col>
        <Modal
          title={t('clientInteractionsPage.importModal.title')}
          visible={visible}
          cancelButtonProps={{ style: { display: 'none' } }} // hide cancel button
          onCancel={() => setVisible(false)}
          onOk={() => setVisible(false)}
        >
          {contentModal}
        </Modal>
        <Col>
          <StyledAlert message={t('uploadCallsPage.messages.fileFormat')} type="info" />
        </Col>
      </Row>
      <TableContainer>
        <UploadedCallsTable />
      </TableContainer>
    </>
  );
};

const StyledAlert = styled(Alert)`
  &.ant-alert {
    padding: 5px 15px !important;
    margin-bottom: 20px;
  }
`;

const TableContainer = styled.div``;

const mapStateToProps = (state, ownProps) => {
  return {
    users: Object.values(state.usersResource.byIds),
    listPage: state.uploadedCallsList.page,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUploadCall: callData => dispatch(phoneCallsResource.actions.loadByIdSucceed(callData)),
    loadCalls: params => dispatch(operations.load(params))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadCallsPage));
