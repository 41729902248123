import React, { useEffect, useState } from 'react';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, message } from 'antd';
import AiIcon from 'assets/images/ai-icon.svg';
import { useTranslation } from 'react-i18next';
// import { DATASET_STATUSSES } from 'core/utils/constants';
import { useDispatch, useSelector } from 'react-redux';
// import UserPreview from 'components/UserPreview';
import SCol from 'components/Standard/SCol';
import { operations as datasetsOperations } from 'redux/lists/datasetList';
import SCard from './SCard';
import SSelect from './SSelect';
import { HeaderItems } from './styled';
import SButton from './SButton';
import ModalTask from './ModalTask';

const { Option } = SSelect;

const loadDatasets = datasetsOperations.load;

const HeaderDatasetAi = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  // const [searchStart, setSearchStart] = useState(false);
  const [createdByText, setCreatedByText] = useState('');
  const usersByIds = useSelector(state => state.usersResource.byIds);
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );

  useEffect(() => {
    dispatch(
      loadDatasets({
        organization_id: organizationId,
        search_input: searchText,
        created_by: createdByText
      })
    );
  }, [searchText, createdByText]);
  const showModal = () => {
    setIsModalOpen(true);
  };
  // const handleSearch = async () => {
  //   setSearchStart(true);
  //   setLoading(true);
  //   try {
  //     await dispatch(
  //       loadDatasets({
  //         organization_id: organizationId,
  //         search_input: searchText
  //       })
  //     );
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.log('error', error);
  //     message.error(t('errorPages.internalServerError.title'));
  //   }
  // };
  const handleInputChange = e => {
    setSearchText(e.target.value.toLowerCase());
  };

  // const handleKeyPress = e => {
  //   if (e.key === 'Enter') {
  //     handleSearch();
  //   }
  // };

  return (
    <>
      <SCol span={24}>
        <SCard shadowed bordered>
          <HeaderItems>
            <div>
              <Input
                value={searchText}
                onChange={handleInputChange}
                // onKeyPress={handleKeyPress}
                style={{ width: 180, height: 32, marginRight: '24px' }}
                placeholder={t('datasetAiPage.placeholders.name')}
              />
              {/*<Button type="primary" onClick={handleSearch} style={{ marginRight: '24px' }}>*/}
              {/*  {loading ? <LoadingOutlined /> : <SearchOutlined />}*/}
              {/*</Button>*/}
              {/*<SSelect*/}
              {/*  size="middle"*/}
              {/*  allowClear*/}
              {/*  placeholder={t('datasetAiPage.placeholders.status')}*/}
              {/*>*/}
              {/*  {Object.values(DATASET_STATUSSES).map(item => (*/}
              {/*    <Option*/}
              {/*      value={item.value}*/}
              {/*      key={item.value}*/}
              {/*      label={`${t('datasetAiPage.statuses.title')}: ${t(item.title)}`}*/}
              {/*    >*/}
              {/*      {t(item.title)}*/}
              {/*    </Option>*/}
              {/*  ))}*/}
              {/*</SSelect>*/}
              <SSelect
                size="middle"
                allowClear
                placeholder={t('datasetAiPage.placeholders.createdBy')}
                onChange={
                  createdBy => setCreatedByText(createdBy)
                  // dispatch(
                  //   loadDatasets({
                  //     organization_id: organizationId,
                  //     created_by: createdBy
                  //   })
                  // )
                }
              >
                {Object.values(usersByIds)
                  .sort((a, b) => a.name.localeCompare(b.name)) // Сортировка по имени
                  .map(item => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                      {/* <UserPreview userId={item.id} showAvatar disabled /> */}
                    </Option>
                  ))}
              </SSelect>
            </div>
            <div>
              <SButton
                type="primary"
                size="large"
                onClick={showModal}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                {t('datasetAiPage.buttons.create')}
                <img
                  src={AiIcon}
                  alt="ai-icon"
                  style={{ marginLeft: '5px', paddingBottom: '3px' }}
                />
              </SButton>
            </div>
          </HeaderItems>
        </SCard>
      </SCol>
      <ModalTask isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export default HeaderDatasetAi;
