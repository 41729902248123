import { WIDGET_VIEW_MODES } from 'core/utils/constants';
import { isEqual } from 'lodash';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import { exportWidgetData } from 'pages/DashboardPage/utils';
import { prepareRows } from './prepareTableData';

export const TableReviewsWithScoresContext = React.createContext({
  loadingExport: false,
  startExport: () => {}
});

export const TableReviewsWithScoresProvider = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loadingExport, setLoadingExport] = useState(false);

  const usersByIds = useSelector(state => state.usersResource.byIds, isEqual);
  const unitsByIds = useSelector(state => state.unitsResource.byIds, shallowEqual);

  const startExport = async ({
    id,
    type,
    filters,
    widgetMeta,
    initialState,
    currentChecklist,
    checklistItems
  }) => {
    const res = await dispatch(
      updateDashboardWidget({
        id,
        type,
        filters,
        page: {
          number: 1,
          size: widgetMeta.total_count
        }
      })
    );

    const { viewMode } = filters;

    const itemsType =
      viewMode === WIDGET_VIEW_MODES.QUESTIONS.value
        ? 'checklist-questions'
        : 'checklist-question-groups';

    const targetItems = checklistItems.filter(item => item.type === itemsType);

    const columns = [
      {
        title: t('general.clientInteractionType'),
        dataIndex: 'reviewId',
        key: 'reviewId'
      },
      {
        title: t('general.operator'),
        dataIndex: 'operatorId',
        key: 'operatorId'
      },
      {
        title: t('general.unit'),
        dataIndex: 'unitName',
        key: 'unitName'
      },
      {
        title: t('general.phoneNumber'),
        dataIndex: 'communicationKey',
        key: 'communicationKey'
      },
      {
        title: t('general.clientInteractionDate'),
        dataIndex: 'clientInteractionStartedAt',
        key: 'clientInteractionStartedAt'
      },
      {
        title: t('general.reviewDate'),
        dataIndex: 'createdAt',
        key: 'createdAt'
      },
      ...targetItems.map(item => {
        return {
          title: item?.name,
          dataIndex: item?.id,
          key: item?.id
        };
      }),
      {
        title: t('general.result'),
        dataIndex: 'checklistScore',
        key: 'checklistScore'
      }
    ];

    const body = {
      widgetType: type,
      widgetData: {
        columns,
        rows: prepareRows({
          filters,
          currentChecklist,
          widgetValue: res?.updatedAnalytics,
          checklistItems,
          isExport: true,
          usersByIds,
          unitsByIds
        })
      }
    };

    await exportWidgetData({ body, setLoadingExport });

    await dispatch(
      updateDashboardWidget({
        id,
        type,
        filters,
        page: initialState
      })
    );
  };

  return (
    <TableReviewsWithScoresContext.Provider value={{ loadingExport, startExport }}>
      {children}
    </TableReviewsWithScoresContext.Provider>
  );
};

export const useTableReviewsWithScoresExport = () => {
  const { loadingExport, startExport } = useContext(TableReviewsWithScoresContext);
  return { loadingExport, startExport };
};
