import { DATE_PICKER_MODES, SYMBOLIC_TIME_RANGE } from 'core/utils/constants';
import { isNull, isEmpty } from 'lodash';
import moment from 'moment';

export const getModeFromFilters = ({ filters }) => {
  if (
    !isEmpty(filters.clientInteractionSymbolicTimeRange) ||
    (!isEmpty(filters.clientInteractionTimeFrom) && !isEmpty(filters.clientInteractionTimeTo))
  ) {
    return DATE_PICKER_MODES.clientInteraction;
  }
  if (
    !isEmpty(filters.reviewSymbolicTimeRange) ||
    (!isEmpty(filters.reviewTimeFrom) && !isEmpty(filters.reviewTimeTo))
  ) {
    return DATE_PICKER_MODES.review;
  }

  return undefined;
};

export const onChangePeriod = ({ period, setSymbolicPeriod, setTimePeriod, timeFrom, timeTo }) => {
  setSymbolicPeriod(
    period === SYMBOLIC_TIME_RANGE.THIS_YEAR || period === SYMBOLIC_TIME_RANGE.PREVIOUS_YEAR
      ? undefined
      : period
  );
  switch (period) {
    case SYMBOLIC_TIME_RANGE.TODAY:
      setTimePeriod({ timeFrom: moment(), timeTo: moment() });
      return { timeFrom: moment(), timeTo: moment() };

    case SYMBOLIC_TIME_RANGE.YESTERDAY:
      setTimePeriod({
        timeFrom: moment().subtract(1, 'day'),
        timeTo: moment().subtract(1, 'day')
      });
      return { timeFrom: moment().subtract(1, 'day'), timeTo: moment().subtract(1, 'day') };

    case SYMBOLIC_TIME_RANGE.LAST_SEVEN_DAYS:
      setTimePeriod({
        timeFrom: moment().subtract(6, 'day'),
        timeTo: moment()
      });
      return { timeFrom: moment().subtract(6, 'day'), timeTo: moment() };

    case SYMBOLIC_TIME_RANGE.THIS_WEEK:
      setTimePeriod({
        timeFrom: moment().startOf('week'),
        timeTo: moment()
      });
      return { timeFrom: moment().startOf('week'), timeTo: moment() };

    case SYMBOLIC_TIME_RANGE.PREVIOUS_WEEK:
      setTimePeriod({
        timeFrom: moment()
          .startOf('week')
          .subtract(7, 'days'),
        timeTo: moment()
          .endOf('week')
          .subtract(7, 'days')
      });
      return {
        timeFrom: moment()
          .startOf('week')
          .subtract(7, 'days'),
        timeTo: moment()
          .endOf('week')
          .subtract(7, 'days')
      };

    case SYMBOLIC_TIME_RANGE.LAST_THIRTY_DAYS:
      setTimePeriod({
        timeFrom: moment().subtract(29, 'days'),
        timeTo: moment()
      });
      return {
        timeFrom: moment().subtract(29, 'days'),
        timeTo: moment()
      };

    case SYMBOLIC_TIME_RANGE.THIS_MONTH:
      setTimePeriod({
        timeFrom: moment().startOf('month'),
        timeTo: moment()
      });
      return {
        timeFrom: moment().startOf('month'),
        timeTo: moment()
      };

    case SYMBOLIC_TIME_RANGE.PREVIOUS_MONTH:
      setTimePeriod({
        timeFrom: moment()
          .startOf('month')
          .subtract(1, 'months'),
        timeTo: moment()
          .subtract(1, 'months')
          .endOf('month')
      });
      return {
        timeFrom: moment()
          .startOf('month')
          .subtract(1, 'months'),
        timeTo: moment()
          .subtract(1, 'months')
          .endOf('month')
      };
    case SYMBOLIC_TIME_RANGE.PREVIOUS_YEAR: {
      setTimePeriod({
        timeFrom: moment()
          .add(-1, 'year')
          .startOf('year'),
        timeTo: moment()
          .add(-1, 'year')
          .endOf('year')
      });
      return {
        timeFrom: moment()
          .add(-1, 'year')
          .startOf('year'),
        timeTo: moment()
          .add(-1, 'year')
          .endOf('year')
      };
    }
    case SYMBOLIC_TIME_RANGE.THIS_YEAR: {
      setTimePeriod({
        timeFrom: moment().startOf('year'),
        timeTo: moment()
      });
      return { timeFrom: moment().startOf('year'), timeTo: moment() };
    }
    case undefined: {
      setTimePeriod({
        timeFrom: moment(timeFrom, 'DD/MM/YYYY'),
        timeTo: moment(timeTo, 'DD/MM/YYYY')
      });
      return { timeFrom: moment(timeFrom, 'DD/MM/YYYY'), timeTo: moment(timeTo, 'DD/MM/YYYY') };
    }
    default:
      setTimePeriod(prevState => ({ ...prevState }));
      return { timeFrom: moment(timeFrom, 'DD/MM/YYYY'), timeTo: moment(timeTo, 'DD/MM/YYYY') };
  }
};

export const elementWithClassName = ({ e, className }) => {
  let element = e.target;
  while (element.parentElement !== null) {
    if (element.classList.contains(className)) {
      return true;
    }
    element = element.parentElement;
  }
  return false;
};

export const handleClick = ({
  e,
  setOpenSelect,
  pickerId,
  isClientInteractionPage,
  setInitalValues
}) => {
  // * если id кликнутого элемента не совпадает с установленным id календаря закрываем календарь. Актуально для страниц где больше чем 1 дейтпикер
  if (e.target.id !== pickerId && !isEmpty(e.target.id) && isClientInteractionPage) {
    setOpenSelect(false);
    setInitalValues();
  }

  // * закрываем календарь если кликнули вне его области
  if (
    isNull(e.target.closest('.custom-dropdown')) &&
    !elementWithClassName({ e, className: 'date-picker-select-btn' }) &&
    !elementWithClassName({ e, className: 'ant-picker-header' }) &&
    !elementWithClassName({ e, className: 'ant-picker-body' })
  ) {
    setOpenSelect(false);
    setInitalValues();
  }
};

export const disabledDate = ({ current, isStartDate, timeFrom, timeTo }) => {
  if (isStartDate) {
    return current.isAfter(timeTo);
  }
  if (current.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) return false;
  if (!isStartDate) {
    return current.isBefore(timeFrom);
  }
};
