import React, { useState } from 'react';
import { Col, Radio, Row, Tooltip } from 'antd';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import { useDispatch, useSelector } from 'react-redux';
import { WIDGET_VIEW_MODES } from 'core/utils/constants';
import WidgetTable from 'components/Dashboard/WidgetTable';
import { get, isEmpty, isEqual, keyBy } from 'lodash';
import ExcelIcon from 'images/ExcelIcon';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { IconButton } from 'components/Buttons/IconButton';
import SCol from 'components/Standard/SCol';
import { TimePeriod } from 'components/TimePeriod';
import { WidgetTitle } from 'components/WidgetTitle';
import SRow from 'components/Standard/SRow';
import { LargeWidgetCard } from '../../styled';
import SettingsModal from '../../Components/SettingsModal';
import {
  exportWidgetData,
  widgetInfoTextSetter,
  widgetNameSetter,
  widgetPeriodSetter
} from '../../utils';
import EmptyWidget from '../../Components/EmptyWidget';
import { prepareColumns, prepareRows } from './prepareTableData';

const TableChecklistsScoresByOperators = ({ widgetData, loadingWidgetId, isWarningWidget }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const usersByIds = useSelector(state => state.usersResource.byIds, isEqual);
  const unitsByIds = useSelector(state => state.unitsResource.byIds, isEqual);

  const checklistDefinitionsByIds = useSelector(
    state => state.checklistDefinitionsResource.byIds,
    isEqual
  );
  const widgetsAnalyticsByIds = useSelector(state => state.dashboardAnalytics.byIds, isEqual);

  const { id, type, filters, name } = widgetData;
  const [loadingExport, setLoadingExport] = useState(false);
  const { viewMode } = filters;

  const widgetValue = get(widgetsAnalyticsByIds, id, {});

  const checklistDefinitionsAverageScoresByOperators = get(
    widgetValue,
    'checklist_definitions_average_scores_by_operators',
    []
  );

  const checklistDefinitionAverageScoreByOperatorsIds = keyBy(
    get(widgetValue, 'checklist_definition_average_score_by_operators', []),
    'id'
  );

  const averageScoreByChecklistDefinitionsIds = keyBy(
    get(widgetValue, 'average_score_by_checklist_definitions', []),
    'id'
  );

  const checklistDefinitionAverageScore = get(
    widgetValue,
    'checklist_definition_average_score',
    []
  );

  const checklistDefinitionsAverageScoresByUnits = get(
    widgetValue,
    'checklist_definitions_average_scores_by_units',
    []
  );

  const checklistDefinitionAverageScoreByUnitsIds = keyBy(
    get(widgetValue, 'checklist_definition_average_score_by_units', []),
    'id'
  );

  const reviewsCountByOperators = get(widgetValue, 'reviews_count_by_operators', []);

  const reviewsCountByUnits = get(widgetValue, 'reviews_count_by_units', []);

  const selectedChecklistDefinitions = filters.checklistDefinitionsIds.reduce(
    (acc, checklistDefinitionId) => {
      if (!Object.keys(checklistDefinitionsByIds).includes(checklistDefinitionId)) return acc;
      return [...acc, checklistDefinitionsByIds[checklistDefinitionId]];
    },
    []
  );

  const tableColumns = prepareColumns({
    selectedChecklistDefinitions,
    viewMode,
    filters
  });

  const tableRows = prepareRows({
    checklistDefinitionsAverageScoresByOperators,
    checklistDefinitionAverageScoreByOperatorsIds,
    checklistDefinitionsAverageScoresByUnits,
    checklistDefinitionAverageScoreByUnitsIds,
    averageScoreByChecklistDefinitionsIds,
    checklistDefinitionAverageScore,
    selectedChecklistDefinitions,
    reviewsCountByOperators,
    reviewsCountByUnits,
    usersByIds,
    unitsByIds,
    viewMode
  });

  const isEmptyWidgetData = isEmpty(
    viewMode === WIDGET_VIEW_MODES.OPERATORS.value
      ? checklistDefinitionsAverageScoresByOperators
      : checklistDefinitionsAverageScoresByUnits
  );

  const body = {
    widgetType: type,
    widgetData: {
      columns: tableColumns.reduce((acc, item) => {
        if (filters.viewMode === WIDGET_VIEW_MODES.UNITS.value && item.key === 'unitName')
          return acc;

        const data = {
          ...item,
          title: ['name', 'totalScore', 'reviewsCount', 'unitName'].includes(item.key)
            ? t(item?.title?.props?.i18nKey)
            : item?.title?.props?.title
        };
        return [...acc, data];
      }, []),
      rows: tableRows.map(item => ({
        ...item,
        name: item.key === 'footer' ? t(item.name) : item.name
      }))
    }
  };
  return (
    <>
      {loadingWidgetId === id || isEmptyWidgetData || isWarningWidget ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ) : (
        <Col span={24} key={id}>
          <LargeWidgetCard>
            <SRow type="flex" justify="space-between" align="top">
              <SCol span={8}>
                <SRow type="flex" gutter={[6, 0]} justify="start" alignItems="center" wrap={false}>
                  <SCol flex="auto" display="flex" alignItems="center">
                    <Tooltip title={widgetNameSetter({ name, type })}>
                      <WidgetTitle>{widgetNameSetter({ name, type })}</WidgetTitle>
                    </Tooltip>
                    {widgetInfoTextSetter({ type })}
                  </SCol>
                </SRow>

                <TimePeriod type="secondary">
                  {widgetPeriodSetter({
                    widgetsFilters: filters,
                    widgetType: type,
                    showDateMode: true
                  })}
                </TimePeriod>
              </SCol>
              <Col span={6}>
                <Row justify="center">
                  <Col>
                    <Radio.Group
                      onChange={e =>
                        dispatch(
                          updateDashboardWidget({
                            id,
                            type,
                            filters: { ...filters, viewMode: e.target.value }
                          })
                        )
                      }
                      value={filters.viewMode || WIDGET_VIEW_MODES.OPERATORS.value}
                    >
                      <Radio.Button value={WIDGET_VIEW_MODES.OPERATORS.value}>
                        {t(WIDGET_VIEW_MODES.OPERATORS.title)}
                      </Radio.Button>
                      <Radio.Button value={WIDGET_VIEW_MODES.UNITS.value}>
                        {t(WIDGET_VIEW_MODES.UNITS.title)}
                      </Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>
              </Col>
              <SCol display="flex" justifyContent="flex-end" span={8} alignItems="center">
                <IconButton
                  tooltip={{ title: t('dashboardPage.widget.buttons.export') }}
                  button={{
                    icon: loadingExport ? <LoadingOutlined spin /> : <ExcelIcon />,
                    marginTop: '2px',
                    onClick: () =>
                      loadingExport ? null : exportWidgetData({ body, setLoadingExport }),
                    size: 'icon'
                  }}
                />
                <SettingsModal widgetData={widgetData} />
              </SCol>
            </SRow>
            <WidgetTable
              columns={tableColumns.filter(item => item.key !== 'unitName')}
              dataSource={tableRows}
              pagination={false}
              size="small"
              bordered
              scroll={{ x: 1500, y: 452 }}
              style={{ marginTop: 8 }}
              rowClassName={record => (record.key === 'header' ? 'table-row-light' : '')}
            />
          </LargeWidgetCard>
        </Col>
      )}
    </>
  );
};

export default TableChecklistsScoresByOperators;
