import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DatePicker, Radio } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SText from 'components/Standard/SText';
import { getDatesArray } from 'pages/DashboardPage/utils';
import { DATE_PICKER_MODES } from 'core/utils/constants';
import { disabledDate } from '../utils';

const StyledPicker = styled(DatePicker)`
  &.ant-picker {
    border: none;

    .ant-picker-input {
      display: none;
    }
  }
`;

const CalendarPicker = ({
  timePeriod,
  setTimePeriod,
  symbolicPeriod,
  setSymbolicPeriod,
  checkboxMode,
  setCheckboxMode,
  showModeSwitch,
  onChangePeriod
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const startDate = moment(timePeriod.timeFrom).format('DD/MM/YYYY');
  const endDate = moment(timePeriod.timeTo).format('DD/MM/YYYY');

  // * отрисовка сохраненных значений
  useEffect(() => {
    setOpen(true);
    onChangePeriod({
      period: symbolicPeriod,
      setTimePeriod,
      setSymbolicPeriod,
      timeFrom: timePeriod.timeFrom,
      timeTo: timePeriod.timeTo
    });
  }, []);

  const datesArray = getDatesArray({
    timeFrom: timePeriod.timeFrom,
    timeTo: timePeriod.timeTo,
    isWeekendView: true,
    historyGroupingPeriod: 'day'
  });

  const onDateRender = current => {
    let style = {};
    const currentDate = moment(current).format('DD/MM/YYYY');

    datesArray.map(date => {
      style.display = 'flex';
      style.width = '100%';
      style.justifyContent = 'center';
      // * стилизация даты начала
      if (currentDate === startDate && startDate !== endDate) {
        style.borderTopRightRadius = 0;
        style.borderBottomRightRadius = 0;
        style.backgroundColor = '#597EF7';
        style.color = '#FFFFFF';
      }
      // * стилизация даты окончания
      if (currentDate === endDate && startDate !== endDate) {
        style.borderTopLeftRadius = 0;
        style.borderBottomLeftRadius = 0;
        style.backgroundColor = '#597EF7';
        style.color = '#FFFFFF';
      }
      // * стилизация выделенного периода
      if (
        currentDate === moment(date).format('DD/MM/YYYY') &&
        currentDate !== startDate &&
        currentDate !== endDate
      ) {
        style.backgroundColor = '#EBEFFF';
        style.color = '#597EF7';
        style.borderRadius = 0;
        return (
          <div className="ant-picker-cell-inner " style={style}>
            {current.date()}
          </div>
        );
      }
      if (
        datesArray.length === 1 ||
        (currentDate === moment().format('DD/MM/YYYY') &&
          currentDate !== startDate &&
          currentDate !== endDate)
      ) {
        style = {};
      }
      return (
        <div className="ant-picker-cell-inner " style={style}>
          {current.date()}
        </div>
      );
    });

    return (
      <div className="ant-picker-cell-inner " style={style}>
        {current.date()}
      </div>
    );
  };

  return (
    <SRow type="flex">
      <SCol style={{ padding: '0px' }} span={12}>
        <SText fontWeight="bold" paddingLeft="12px" color="#82858C">
          {t('general.startOfPeriod')}
        </SText>
        <StyledPicker
          value={moment(timePeriod.timeFrom)}
          dropdownClassName={
            showModeSwitch
              ? 'custom-date-picker-dropdown'
              : 'custom-date-picker-dropdown without-display-by-date'
          }
          disabledDate={current =>
            disabledDate({
              current,
              timeFrom: timePeriod.timeFrom,
              timeTo: timePeriod.timeTo,
              isStartDate: true
            })
          }
          dateRender={current => onDateRender(current, true)}
          onChange={val => {
            setTimePeriod(prevState => ({ ...prevState, timeFrom: val }));
            setSymbolicPeriod(undefined);
          }}
          open={open}
          getPopupContainer={triggerNode => {
            return triggerNode.parentNode;
          }}
        />
      </SCol>
      <SCol style={{ padding: '0px' }} span={12}>
        <SText fontWeight="bold" paddingLeft="12px" color="#82858C">
          {t('general.endOfPeriod')}
        </SText>
        <StyledPicker
          value={moment(timePeriod.timeTo)}
          dropdownClassName={
            showModeSwitch
              ? 'custom-date-picker-dropdown'
              : 'custom-date-picker-dropdown without-display-by-date'
          }
          disabledDate={current =>
            disabledDate({
              current,
              timeFrom: timePeriod.timeFrom,
              timeTo: timePeriod.timeTo,
              isStartDate: false
            })
          }
          dateRender={current => onDateRender(current, false)}
          onChange={val => {
            setTimePeriod(prevState => ({ ...prevState, timeTo: val }));
            setSymbolicPeriod(undefined);
          }}
          open={open}
          getPopupContainer={triggerNode => {
            return triggerNode.parentNode;
          }}
        />
      </SCol>
      {showModeSwitch && (
        <SRow marginTop="291px" marginBottom="12px" width="100%">
          <SText fontWeight="bold" marginLeft="24px" marginRight="40px">
            {t('general.displayByDate')}
          </SText>
          <Radio.Group onChange={e => setCheckboxMode(e.target.value)} value={checkboxMode}>
            <Radio value={DATE_PICKER_MODES.clientInteraction}>
              <SText>{t('general.communication')}</SText>
            </Radio>
            <Radio value={DATE_PICKER_MODES.review}>
              <SText>{t('general.review')}</SText>
            </Radio>
          </Radio.Group>
        </SRow>
      )}
    </SRow>
  );
};

export default CalendarPicker;
