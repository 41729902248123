export default {
  pagesMeta: {
    loginPage: {
      title: 'Вход | qolio.io'
    },
    registerPage: {
      title: 'Регистрация | qolio.io'
    },
    recoverPasswordPage: {
      title: 'Восстановление пароля | qolio.io'
    },
    setNewPasswordPage: {
      title: 'Установка нового пароля | qolio.io'
    },
    organizationSettingsPage: {
      title: 'Настройка организации | qolio.io'
    },
    checklistEditorPage: {
      title: 'Настройки формы оценки | qolio.io'
    },
    checklistQuestionsEditor: {
      title: 'Настройки критериев | qolio.io'
    },
    checklistsAndQuestionsPage: {
      title: 'Формы оценки | qolio.io'
    },
    callPage: {
      title: 'Звонок | qolio.io'
    },
    reviewPage: {
      title: 'Оценка | qolio.io'
    },
    textCommunicationPage: {
      title: 'Текстовая коммуникация | qolio.io'
    },
    clientInteractionsPage: {
      title: 'Список коммуникаций | qolio.io'
    },
    calibrationsSessionsPages: {
      calibrationsSessions: {
        title: 'Калибровочные сессии | qolio.io'
      },
      viewCalibrationSession: {
        title: 'Текущая сессия | qolio.io'
      },
      calibrationSessionResult: {
        title: 'Итоги сессии | qolio.io'
      }
    },
    customCommunicationReviewPage: {
      titles: {
        review: 'Оценка | qolio.io',
        addReview: 'Новая проверка | qolio.io'
      }
    },
    dashboardPage: {
      title: 'Аналитика | qolio.io'
    },
    notFoundPage: {
      title: 'Страница не найдена | qolio.io'
    },
    libraryPage: {
      title: 'Библиотека звонков | qolio.io'
    },
    organizationStructurePage: {
      tabs: {
        organizationStructure: {
          title: 'Структура компании | qolio.io'
        },
        employees: {
          title: 'Сотрудники | qolio.io'
        },
        permissionsAndLevels: {
          title: 'Права доступа и уровни сотрудников | qolio.io'
        },
        notificationsAndStatuses: {
          title: 'Уведомления и статусы контакта | qolio.io'
        }
      }
    },
    integrationsSettingsPage: {
      title: 'Интеграции | qolio.io'
    },
    editTaskDefinitionCardPage: {
      titles: {
        createTag: 'Создать тег | qolio.io',
        updateTag: 'Редактировать тег | qolio.io'
      }
    },
    triggersSettingsPage: {
      title: 'Теги | qolio.io'
    },
    userSettingsPage: {
      title: 'Настройки пользователя | qolio.io'
    },
    teamPage: {
      title: 'Команда | qolio.io'
    },
    uploadCallsPage: {
      title: 'Загрузка звонков | qolio.io'
    },
    userAnalyticsPage: {
      title: 'Личный кабинет | qolio.io'
    },
    workPlanTaskConfigurationsPage: {
      title: 'Распределение нагрузки | qolio.io'
    },
    appealsPage: {
      title: 'Апелляции | qolio.io'
    },
    appealsSettingsPage: {
      title: 'Настройки апелляций | qolio.io'
    },
    unitAnalyticsPage: {
      title: 'Аналитика по отделам | qolio.io'
    },
    autoFailAnalyticsPage: {
      title: 'Аналитика по критичным ошибкам | qolio.io'
    },
    crmEntityPage: {
      title: 'Сделка/Тикет | qolio.io',
      buttons: {
        info: 'инфо'
      }
    },
    datasetPage: {
      title: 'Dataset | qolio.io'
    },
    aiSettingsPage: {
      title: 'Настройки AI | qolio.io'
    }
  }
};
